import React, { memo } from "react";
import { getGameDisableData, useShallowSelector } from "../../store/selectors";

const ScreenDisableLayer = () => {
    const disableScreen = useShallowSelector(getGameDisableData);

    return <>{disableScreen ? <div className="screenDisablingLayer" /> : null}</>;
};

export default memo(ScreenDisableLayer);
