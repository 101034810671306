import React, { memo } from "react";
import Tile from "../../Tile";
import PropTypes from "prop-types";
import { GenerateTileAnimationStyles } from "../../../hocs/GenerateTileAnimationStyles";
import classNames from "classnames";
import { getOpponentTilesData, useShallowSelector } from "../../../store/selectors";
import PointsNotification from "../../PointsNotification";
import WinnerBadge from "../../WinnerBadge";
import ChanceTile from "../ChanceTile";
import { isHistory } from "../../../constants";

const OpponentTiles = ({ getTileAnimationStyles }) => {
    const {
        bazar,
        dominoes,
        isPlayerWin,
        isPlayerTurn,
        playedDomino,
        roundFinished,
        dominoesPoint,
        playedDominoId,
        isPlayerPlayed,
        startAnimation,
        additionalPoint,
        historyStepPoint
    } = useShallowSelector(getOpponentTilesData);

    return (
        <div
            className={classNames("opponent-tiles", {
                history: isHistory,
                "show-opponent-tiles": roundFinished,
                "game-end-with-no-tiles": roundFinished && !dominoes.length
            })}
        >
            {dominoes.map((i, index) => {
                const isLastTile = index === dominoes.length - 1;
                const tileToPlay = !isPlayerTurn && isLastTile;
                const style = tileToPlay && !roundFinished && getTileAnimationStyles();
                const tileId = tileToPlay ? playedDominoId || 0 : 0;
                return (
                    <div className={"tile-wrapper-helper"} key={index}>
                        <Tile
                            threeD
                            isPlayer={roundFinished}
                            id={tileToPlay ? "oppSelectedTile" : ""}
                            tileId={roundFinished ? i : tileId}
                            style={style}
                            showAnimation={Boolean(tileToPlay && style)}
                        />
                    </div>
                );
            })}
            {bazar && !isPlayerTurn && !roundFinished && <ChanceTile />}
            {((!startAnimation && playedDomino?.isPointValuable && !isPlayerPlayed) ||
                (isHistory && historyStepPoint)) && (
                <PointsNotification
                    playedDomino={playedDomino}
                    point={isHistory ? historyStepPoint : playedDomino.point}
                    className="game-end-notification right"
                />
            )}
            {roundFinished && (
                <>
                    {dominoesPoint > 0 && (
                        <PointsNotification
                            point={dominoesPoint}
                            withoutPlus
                            className="game-end-notification rounded left"
                        />
                    )}
                    {!isPlayerWin && (
                        <>
                            {additionalPoint > 0 && (
                                <PointsNotification
                                    point={additionalPoint}
                                    className="game-end-notification rounded right"
                                />
                            )}
                            <WinnerBadge />
                        </>
                    )}
                </>
            )}
            {/*<PointsNotification point={8} className="game-end-notification rounded left" />*/}
            {/*<PointsNotification point={score} className="game-end-notification rounded right" />*/}
            {/*{dominoesPoint >= 0 && <PointsNotification point={dominoesPoint} />}*/}
            {/*{!isPlayerWin && (*/}
            {/*    <>*/}
            {/*        {additionalPoint >= 0 && <PointsNotification point={additionalPoint} />}*/}
            {/*        <WinnerBadge />*/}
            {/*    </>*/}
            {/*)}*/}
        </div>
    );
};

OpponentTiles.propTypes = {
    getTileAnimationStyles: PropTypes.func.isRequired
};

export default memo(GenerateTileAnimationStyles(OpponentTiles));
