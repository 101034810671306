import React from "react";

const WinnerGetsAll = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 12.806">
            <path
                d="M6.889,5.594A2,2,0,0,1,7.227,4.52l0,.007a1.04,1.04,0,0,0-1,1.039c0,.01,0,.02,0,.029V16.259c0,.008,0,.018,0,.028a1.04,1.04,0,0,0,1,1.039h.069a2,2,0,0,1-.333-1.065V5.593Z"
                transform="translate(-3.554 -4.52)"
            />
            <path
                d="M2.065,5.594A2.01,2.01,0,0,1,2.4,4.52l0,.007H2.385A1.053,1.053,0,0,0,1.332,5.58v.015h0V16.26c0,.008,0,.018,0,.028a1.04,1.04,0,0,0,1,1.039H2.4a2,2,0,0,1-.333-1.065V5.594Z"
                transform="translate(-1.331 -4.52)"
            />
            <path
                d="M11.656,5.594a2,2,0,0,1,.338-1.074l0,.007a1.04,1.04,0,0,0-1,1.039c0,.01,0,.02,0,.029V16.259c0,.008,0,.018,0,.028a1.04,1.04,0,0,0,1,1.039h.069a2,2,0,0,1-.333-1.065V5.593Z"
                transform="translate(-5.722 -4.52)"
            />
            <path
                d="M16.423,5.594a2,2,0,0,1,.338-1.074l0,.007a1.04,1.04,0,0,0-1,1.039c0,.01,0,.02,0,.029V16.259c0,.008,0,.018,0,.028a1.04,1.04,0,0,0,1,1.039h.069a2,2,0,0,1-.333-1.065V5.593Z"
                transform="translate(-7.889 -4.52)"
            />
            <path
                d="M24.266,4.533h-4.28a1.053,1.053,0,0,0-1.053,1.053V5.6h0V16.213a1.053,1.053,0,0,0,1.053,1.053H24.28a1.053,1.053,0,0,0,1.053-1.053V16.2h0V5.586A1.053,1.053,0,0,0,24.28,4.533h-.014Zm-.533.933a.6.6,0,1,1-.6.6h0v0a.6.6,0,0,1,.6-.6ZM24.4,9.332a.6.6,0,1,1-.6-.6h0a.646.646,0,0,1,.6.6v0ZM20.6,5.466a.6.6,0,1,1-.6.6h0v0a.6.6,0,0,1,.6-.6Zm.666,3.866a.6.6,0,1,1-1.2,0h0a.487.487,0,0,1-.008-.092.509.509,0,0,1,.509-.509h.047a.655.655,0,0,1,.652.6ZM20.6,16.465a.6.6,0,1,1,.6-.6h0c.067.267-.266.6-.6.6ZM20,12.532a.6.6,0,1,1,.6.6h0a.6.6,0,0,1-.6-.6v0ZM22.6,14.6a.613.613,0,0,1-.867-.867h0a.569.569,0,0,1,.866,0h0a.7.7,0,0,1,0,.868v0Zm1.133,1.866a.6.6,0,1,1,.6-.6h0C24.4,16.2,24.066,16.465,23.732,16.465Zm-.6-3.933a.6.6,0,1,1,.6.6h0a.6.6,0,0,1-.6-.6v0ZM24.6,11.066h0l-4.933.067h0a.066.066,0,0,1-.065-.065v0h0V10.8a.066.066,0,0,1,.065-.065h4.869a.066.066,0,0,1,.065.065v0h0Z"
                transform="translate(-9.333 -4.526)"
            />
        </svg>
    );
};

export default WinnerGetsAll;
