import React, { memo, useCallback, useMemo, useState } from "react";
import Close from "../../Icons/Close";
import PopupFooter from "../PopupFooter";
import PropTypes from "prop-types";
import { httpService } from "../../../services/apiService";
import { DOMAIN } from "../../../constants/urls";
import { gameId, HTTP_METHODS, playerId, token } from "../../../constants";
import { getSettingsPopupData, useShallowSelector } from "../../../store/selectors";
import Themes from "./Themes";
import classnames from "classnames";
import Game from "./Game";
import { setSettingsProps } from "../../../store/slices/settings";
import { batch, useDispatch } from "react-redux";
import { closePopup } from "../../../store/slices/popup";
import { setSelectedTile } from "../../../store/slices/gameData";
import useDidUpdate from "../../../customHooks/useDidUpdate";
import { getTranslations } from "../../../helpers/translation";

const SettingsPopup = ({ close }) => {
    const dispatch = useDispatch();
    const { themes, currentTheme, isAutoDeal, isAnimationEnabled, isDragAndDropEnabled } = useShallowSelector(
        getSettingsPopupData
    );
    const translate = useMemo(() => getTranslations(), []);

    const [themeIndex, setThemeIndex] = useState(currentTheme - 1);
    const [section, setSection] = useState("themes");
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [switchers, setSwitchers] = useState({ isAnimationEnabled, isAutoDeal, isDragAndDropEnabled });

    const setSettingSection = sectionName => () => {
        setSection(sectionName);
    };

    const isSettingsChanged = () => {
        return !(
            switchers.isAnimationEnabled !== isAnimationEnabled ||
            switchers.isAutoDeal !== isAutoDeal ||
            switchers.isDragAndDropEnabled !== isDragAndDropEnabled ||
            themeIndex !== currentTheme - 1
        );
    };

    useDidUpdate(() => {
        setSubmitDisabled(isSettingsChanged());
    }, [switchers, themeIndex]);

    const renderSections = () => {
        switch (section) {
            case "themes":
                return <Themes themeIndex={themeIndex} setThemeIndex={setThemeIndex} />;
            case "game":
                return <Game switchers={switchers} setSwitchers={setSwitchers} />;
            default:
                return;
        }
    };

    const onSubmit = useCallback(() => {
        httpService({
            url: `${DOMAIN}/api/Settings/ChangeSettings`,
            method: HTTP_METHODS.POST,
            headers: { "Content-Type": "application/json" },
            body: {
                token: token,
                gameId: gameId,
                playerId: playerId,
                ...switchers,
                themeNumber: themes[themeIndex].id
            },
            successHandler: success => {
                if (success) {
                    batch(() => {
                        isDragAndDropEnabled !== switchers.isDragAndDropEnabled && dispatch(setSelectedTile(null));
                        dispatch(setSettingsProps({ ...switchers, currentTheme: themes[themeIndex].id }));
                        dispatch(closePopup());
                    });
                }
            }
        });
    }, [themeIndex, switchers, isDragAndDropEnabled]);

    return (
        <div>
            <div className="popup-header">
                <span className="popup-title">{translate("Settings")}</span>
                <button className="btn-close" onClick={close}>
                    <Close />
                </button>
            </div>
            <div className="popup-content">
                <div className="popup-nav">
                    <div className="nav-buttons">
                        <button
                            className={classnames("nav-btn", {
                                selected: section === "themes"
                            })}
                            onClick={setSettingSection("themes")}
                        >
                            {translate("Themes")}
                        </button>
                        <button
                            className={classnames("nav-btn", {
                                selected: section === "game"
                            })}
                            onClick={setSettingSection("game")}
                        >
                            {translate("Game")}
                        </button>
                    </div>
                </div>
                <div className="main-content">{renderSections()}</div>
            </div>
            <PopupFooter submitButtonText={translate("Save")} disabled={submitDisabled} onSubmit={onSubmit} />
        </div>
    );
};

SettingsPopup.propTypes = {
    close: PropTypes.func.isRequired
};

export default memo(SettingsPopup);
