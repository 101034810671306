import React, { memo } from "react";
import PropTypes from "prop-types";
import Dots from "../Dots";
import classNames from "classnames";

const HalfTile = ({ value, isPossibleMove, isPointValuable, onClick, direction }) => {
    return (
        <div className="half-tile" onClick={onClick}>
            <Dots value={value} />
            {isPossibleMove && (
                <>
                    <div
                        id={direction}
                        className={classNames({
                            "possible-tile-layer-helper-colored-point": isPointValuable,
                            "possible-tile-layer-helper-colored-no-point": !isPointValuable
                        })}
                        onClick={onClick}
                    />
                </>
            )}
        </div>
    );
};

HalfTile.propTypes = {
    value: PropTypes.number,
    onClick: PropTypes.func,
    direction: PropTypes.number,
    isPossibleMove: PropTypes.bool,
    isPointValuable: PropTypes.bool
};

HalfTile.defaultProps = {
    value: null,
    onClick: null,
    direction: null,
    isPossibleMove: false,
    isPointValuable: false
};

export default memo(HalfTile);
