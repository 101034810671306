import React, { memo } from "react";
import Settings from "../Icons/Settings";
import GameInfoButton from "../GameInfoButton";
import { openPopup } from "../../store/slices/popup";
import { isHistory, isMobile, POPUP_NAMES } from "../../constants";
import { useDispatch } from "react-redux";
import Close from "../Icons/Close";
import SoundController from "../SoundController";
import { getSettingsData, useShallowSelector } from "../../store/selectors";
import { postCloseWindow } from "../../helpers/utility";

const GameSettings = () => {
    const dispatch = useDispatch();
    const { isGuest, settingsData, isGameStarted } = useShallowSelector(getSettingsData);

    const leaveGame = () => {
        if (isMobile && (isGuest || isHistory)) {
            postCloseWindow();
        } else {
            dispatch(openPopup({ name: POPUP_NAMES.LEAVE_GAME }));
        }
    };

    return (
        <div className="game-settings-wrapper">
            <GameInfoButton />
            {settingsData && !isGuest && !isHistory && (
                <div className="button-wrapper">
                    <button
                        disabled={!isGameStarted}
                        className="game-settings-btn"
                        onClick={() => isGameStarted && dispatch(openPopup({ name: POPUP_NAMES.SETTINGS }))}
                    >
                        <Settings />
                    </button>
                </div>
            )}
            {!isHistory && (
                <div className="button-wrapper">
                    <SoundController />
                </div>
            )}
            {(isMobile || (!isGuest && !isHistory)) && (
                <div className="button-wrapper">
                    <button className="game-settings-btn" onClick={leaveGame}>
                        <Close />
                    </button>
                </div>
            )}
        </div>
    );
};

export default memo(GameSettings);
