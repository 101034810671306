import { useEffect, useState } from "react";
import { useDocumentSizes } from "./useDocumnetSizes";
import { calculateGameDimension } from "../helpers/utility";

export const useCalculateGameDimension = () => {
    const { width, height } = useDocumentSizes();
    const [gameDimensions, setGameDimensions] = useState({
        gameHeight: 0,
        gameWidth: 0,
        fontSize: 0,
        clientWidth: 0,
        clientHeight: 0
    });

    useEffect(() => {
        setGameDimensions(calculateGameDimension(width, height));
    }, [width, height]);

    return gameDimensions;
};
