import React, { memo, useMemo } from "react";
import LowConnectionIcon from "../Icons/LowConnection";
import { getTranslations } from "../../helpers/translation";

const WeakConnection = () => {
    const handleRefresh = () => window.location.reload();
    const translate = useMemo(() => getTranslations(), []);

    return (
        <div className="connection-lost">
            <div className="loader-wrapper">
                <div className="loader" />
                <div className="icon-wrapper">
                    <LowConnectionIcon />
                </div>
            </div>
            {/*<p className="text-block secondary">{translate("Attention")}</p>*/}
            <p className="text-block secondary">{translate("Attention")}</p>
            {/*<p className="text-block main">{translate("WeakConnection")}</p>*/}
            <p className="text-block main">{translate("LowConnection")}</p>
            {/*<p className="text-block minor">{translate("PressRefresh")}</p>*/}
            <p className="text-block minor">{translate("PressRefreshBtn")}</p>
            <button className="popup-button" onClick={handleRefresh}>
                {translate("Refresh")}
            </button>
        </div>
    );
};

export default memo(WeakConnection);
