import React from "react";

const Sound = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 15.591">
            <path
                d="M3,8.359v5.333H6.556L11,18.137V3.914L6.556,8.359Zm12,2.667a4,4,0,0,0-2.222-3.582V14.6A3.977,3.977,0,0,0,15,11.026Zm-2.222-7.8V5.061a6.224,6.224,0,0,1,0,11.929v1.831a7.994,7.994,0,0,0,0-15.591Z"
                transform="translate(-3 -3.23)"
            />
        </svg>
    );
};

export default Sound;
