import React, { memo, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import { useCalculateGameDimension } from "../customHooks/useCalculateGameDimension";
import { BROWSER_NAME, default_BG_img, isMobile, lang, RESET_DRAGGED_TILE_ID } from "../constants";

import "./App.css";
import Game from "../components/Game";
import { getAppData, useShallowSelector } from "../store/selectors";
import { getImageUrl, updateDocumentLanguage } from "../helpers/localHelperFunctions";

function App() {
    const [reloaded, setReloaded] = useState(true);
    const { gameWidth, gameHeight, fontSize } = useCalculateGameDimension();

    let { currentTheme, backgroundUrl } = useShallowSelector(getAppData);

    const gameStyle = useMemo(
        () =>
            !isMobile
                ? {
                      height: `${gameHeight}px`,
                      fontSize: `${fontSize}px`,
                      width: `${gameWidth}px`
                  }
                : {
                      width: "100%",
                      height: "100%"
                  },
        [gameHeight, fontSize, gameWidth]
    );

    const resetDraggedTile = e => {
        if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
            // console.log("&&&&&  I'm out X  ", e.clientX);
            let clickEvent = new MouseEvent("mouseup", {
                bubbles: true,
                cancelable: true,
                detail: RESET_DRAGGED_TILE_ID
            });
            document.dispatchEvent(clickEvent);
        }
    };

    useEffect(() => {
        updateDocumentLanguage(lang);
        if (isMobile) {
            setTimeout(() => {
                setReloaded(false);
            }, 350);
        }
        document.addEventListener("mousemove", resetDraggedTile);
        return () => document.removeEventListener("mousemove", resetDraggedTile);
    }, []);

    return (
        <div
            className={classNames(`application ${BROWSER_NAME}`, {
                mobile: isMobile,
                desktop: !isMobile,
                reloaded: reloaded && isMobile
            })}
        >
            <img
                src={getImageUrl(backgroundUrl) || default_BG_img}
                style={{
                    ...gameStyle,
                    position: "absolute"
                }}
                alt="board"
            />
            {/*bones-dealing-animation*/}
            <div className={`game-container theme-${currentTheme || 1}`}>
                {gameHeight === 0 || gameWidth === 0 || fontSize === 0 ? null : <Game style={gameStyle} />}
            </div>
        </div>
    );
}

export default memo(App);
