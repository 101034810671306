import React, { memo, useCallback, useEffect, useRef } from "react";
import BoardTiles from "../BoardTiles";
import {
    isHistory,
    TILE_DEFAULT_SCALE_SIZE,
    TILE_MINIMUM_SCALE_SIZE,
    transitionEndEventName
} from "../../../constants";
import classNames from "classnames";
import { removePX } from "../../../helpers/localHelperFunctions";
import { useDispatch } from "react-redux";
import { setTileMaxScaled, setTilesPrinted } from "../../../store/slices/gameData";
import { getBoardTilesWrapperData, useShallowSelector } from "../../../store/selectors";

const BoardTilesWrapper = () => {
    const dispatch = useDispatch();
    const mainBoardRef = useRef(null);
    const tilesContainerRef = useRef(null);
    const { tileMaxScaled, resetTileScale, currentRound, tilesPrinted, isGuest } = useShallowSelector(
        getBoardTilesWrapperData
    );

    useEffect(() => {
        if (resetTileScale) {
            tilesContainerRef.current.style.transform = `scale(${TILE_DEFAULT_SCALE_SIZE})`;
            updateTilesPositionsAndSizes(true);
        }
    }, [resetTileScale, tilesContainerRef.current]);

    const scaleTiles = (newScalePercent, withoutAnimation) => {
        const tilesContRef = tilesContainerRef.current;

        const currentScale = Number((tilesContRef.getBoundingClientRect().width / tilesContRef.offsetWidth).toFixed(2));

        // the under logic is the old ***
        //
        // if (currentScale < 1) {
        // newScale = (currentScale * ((newScale * 100) / currentScale)) / 100; //(newScale * 100) / currentScale;
        // newScale = currentScale - newScale;
        // }
        //

        let newScale = (currentScale * newScalePercent) / 100; //(newScale * 100) / currentScale;

        if (newScale < TILE_MINIMUM_SCALE_SIZE) {
            newScale = TILE_MINIMUM_SCALE_SIZE;
            dispatch(setTileMaxScaled());
        }

        if (newScale > 1) {
            newScale = 1;
        }

        tilesContRef.style.transform = `scale(${newScale})`;

        if (!withoutAnimation && !document.getElementsByClassName("first-tile-container").length) {
            tilesContRef.style.transition = `0.2s linear`;
        }

        return newScale;
    };

    const calcScalePercent = (topDiff, bottomDiff, leftDiff, rightDiff, nextTileMaxSize, withoutAnimation) => {
        let { top, left, right, bottom } = getTilesMaxPositions();

        // the under logic is the old ***
        //
        // const verticalDiff = (bottomDiff + topDiff) / 2;//  getCentralizingPxDiff(topDiff, bottomDiff);
        // const horizontalDiff = getCentralizingPxDiff(leftDiff, rightDiff);
        // top = top + verticalDiff;
        // left = left + horizontalDiff;

        // mi qani qari hamar miangamic shat poqracnelu scale-i hashvark
        // const newScale = currentScale - 0.3 > 0.35 ? currentScale - 0.3 : 0.35;

        // let horizontalScale = 0;
        // let verticalScale = 0;
        // if (left <= mainBoardLeft + nextTileMaxSize * 0.3) {
        //     horizontalScale = (nextTileMaxSize * MIN_TILE_SIZE_FOR_NEXT_STEP * 100) / (left + right) / 100;
        // }
        //
        // if (top <= mainBoardTop + nextTileMaxSize * 0.3) {
        //     verticalScale = (nextTileMaxSize * MIN_TILE_SIZE_FOR_NEXT_STEP) / (top + bottom);
        // }

        // const { left: mainBoardLeft, top: mainBoardTop, height, width } = mainBoardRef.current.getBoundingClientRect();
        //

        const { height, width } = mainBoardRef.current.getBoundingClientRect();

        let horizontalScale = 0;
        let newScalePercent = 0;
        const boardHorzMaxSize = width - nextTileMaxSize * 0.7;
        const tilesHorzDistance = right - left;
        if (tilesHorzDistance > boardHorzMaxSize || isHistory) {
            horizontalScale = (boardHorzMaxSize * 100) / tilesHorzDistance;
            newScalePercent = horizontalScale;
        }

        let verticalScale = 0;
        const boardVertMaxSize = height - nextTileMaxSize * 0.7;
        const tilesVertDistance = bottom - top;
        if (tilesVertDistance > boardVertMaxSize || isHistory) {
            verticalScale = (boardVertMaxSize * 100) / tilesVertDistance;
            if (horizontalScale) {
                newScalePercent = horizontalScale < verticalScale ? horizontalScale : verticalScale;
            } else {
                newScalePercent = verticalScale;
            }
        }

        return (horizontalScale || verticalScale) && scaleTiles(newScalePercent, withoutAnimation);
    };

    const getCentralizingPxDiff = (x, y) => (y - x) / 2;

    const calcCentralizingSizes = (x, y, style, padding) => {
        const pxDiff = getCentralizingPxDiff(x, y);
        style[padding] = `${pxDiff + removePX(style[padding])}px`;
    };

    const getTilesMaxPositions = () => {
        let tiles = mainBoardRef.current.getElementsByClassName("tile-wrapper");
        if (!tiles.length) {
            tiles = document.getElementsByClassName("first-tile-container");
        }
        const tileDimensions = [...tiles].map(i => i.getBoundingClientRect());
        const { top, bottom, left, right } = tileDimensions.reduce((acc, tile) => {
            return (acc = {
                top: acc.top < tile.top ? acc.top : tile.top,
                bottom: acc.bottom > tile.bottom ? acc.bottom : tile.bottom,
                left: acc.left < tile.left ? acc.left : tile.left,
                right: acc.right > tile.right ? acc.right : tile.right
            });
        }, {});
        const { width, height } = tileDimensions[0];

        return { top, bottom, left, right, width, height };
    };

    const setMainBoardPaddings = (topDiff, bottomDiff, leftDiff, rightDiff) => {
        const { style } = tilesContainerRef.current;
        if (topDiff === bottomDiff && leftDiff === rightDiff) {
            !style.marginTop && (style.marginTop = `${topDiff}px`);
            !style.marginLeft && (style.marginLeft = `${leftDiff}px`);
        } else {
            calcCentralizingSizes(topDiff, bottomDiff, style, "marginTop");
            calcCentralizingSizes(leftDiff, rightDiff, style, "marginLeft");
        }
    };

    const getBoardTilesRatio = () => {
        const { top, bottom, left, right, width, height } = getTilesMaxPositions();

        const mainBoardRect = mainBoardRef.current.getBoundingClientRect();
        const topDiff = parseInt(top - mainBoardRect.top);
        const bottomDiff = parseInt(mainBoardRect.bottom - bottom);
        const leftDiff = parseInt(left - mainBoardRect.left);
        const rightDiff = parseInt(mainBoardRect.right - right);
        const nextTileMaxSize = width > height ? width : height;

        return { topDiff, bottomDiff, leftDiff, rightDiff, nextTileMaxSize };
    };

    const updateMainBoardPadding = (scaled, ratioInfo) => {
        const { topDiff, bottomDiff, leftDiff, rightDiff } = scaled ? getBoardTilesRatio() : ratioInfo;
        setMainBoardPaddings(topDiff, bottomDiff, leftDiff, rightDiff);
    };

    // ******  old history tiles sizes version   ****
    // const updateTilesPositionsAndSizes = useCallback(
    //     (withoutAnimation, lessThenPrev, UsedHistorySlider, boardTilesCount) => {
    //         if (isHistory && boardTilesCount === 0) {
    //             historyTilesScaleMap.current = { 0: TILE_DEFAULT_SCALE_SIZE };
    //             tilesContainerRef.current.style.transform = `scale(1)`;
    //         } else if (mainBoardRef.current) {
    //             let scaled;
    //
    //             if (isHistory && lessThenPrev) {
    //                 scaled = historyTilesScaleMap.current[boardTilesCount];
    //                 if (scaled) {
    //                     boardTilesCount && delete historyTilesScaleMap.current[boardTilesCount];
    //                 } else {
    //                     const keyArr = Object.keys(historyTilesScaleMap.current);
    //                     if (keyArr[keyArr.length - 1] > boardTilesCount) {
    //                         boardTilesCount && delete historyTilesScaleMap.current[keyArr[keyArr.length - 1]];
    //                         keyArr.pop();
    //                     }
    //                     scaled = historyTilesScaleMap.current[keyArr.length - 1];
    //                 }
    //                 tilesContainerRef.current.style.transform = `scale(${scaled})`;
    //             }
    //
    //             const ratioInfo = getBoardTilesRatio();
    //             const { topDiff, bottomDiff, leftDiff, rightDiff, nextTileMaxSize } = ratioInfo;
    //             if (!lessThenPrev) {
    //                 scaled =
    //                     tileMaxScaled ||
    //                     calcScalePercent(
    //                         topDiff,
    //                         bottomDiff,
    //                         leftDiff,
    //                         rightDiff,
    //                         nextTileMaxSize,
    //                         withoutAnimation || isHistory || isGuest
    //                     );
    //             }
    //
    //             if (isHistory) {
    //                 if (UsedHistorySlider) {
    //                     historyTilesScaleMap.current = {};
    //                 } else if (scaled && !lessThenPrev) {
    //                     historyTilesScaleMap.current[boardTilesCount] = scaled;
    //                 }
    //             }
    //
    //             if (!withoutAnimation && !isHistory && !isGuest && scaled) {
    //                 document.addEventListener(
    //                     transitionEndEventName,
    //                     () => {
    //                         updateMainBoardPadding(scaled, ratioInfo);
    //                     },
    //                     { once: true }
    //                 );
    //             } else {
    //                 updateMainBoardPadding(scaled, ratioInfo);
    //             }
    //
    //             if (!withoutAnimation && !isHistory && !isGuest) {
    //                 tilesContainerRef.current.style.transition = `0.2s linear`;
    //             }
    //         }
    //     },
    //     [mainBoardRef.current, tileMaxScaled]
    // );

    const updateTilesPositionsAndSizes = useCallback(
        withoutAnimation => {
            if (mainBoardRef.current) {
                const ratioInfo = getBoardTilesRatio();
                const { topDiff, bottomDiff, leftDiff, rightDiff, nextTileMaxSize } = ratioInfo;
                const scaled =
                    (tileMaxScaled && !isHistory) ||
                    calcScalePercent(
                        topDiff,
                        bottomDiff,
                        leftDiff,
                        rightDiff,
                        nextTileMaxSize,
                        withoutAnimation || isHistory || isGuest
                    );

                if (!withoutAnimation && !isHistory && !isGuest && scaled) {
                    document.addEventListener(
                        transitionEndEventName,
                        () => {
                            updateMainBoardPadding(scaled, ratioInfo);
                        },
                        { once: true }
                    );
                } else {
                    updateMainBoardPadding(scaled, ratioInfo);
                }

                if (!withoutAnimation && !isHistory && !isGuest) {
                    tilesContainerRef.current.style.transition = `0.2s linear`;
                }

                return scaled;
            }
        },
        [mainBoardRef.current, tileMaxScaled]
    );

    const windowResizeHandler = () => {
        let timeOutId;
        return () => {
            clearTimeout(timeOutId);
            timeOutId = setTimeout(() => {
                updateTilesPositionsAndSizes(true);
            }, 150);
        };
    };

    useEffect(() => {
        window.addEventListener("resize", windowResizeHandler());
        setTimeout(() => {
            updateTilesPositionsAndSizes(true);
            dispatch(setTilesPrinted(true));
        });
        return () => window.removeEventListener("resize", windowResizeHandler());
    }, [currentRound]);

    return (
        <div
            className={classNames("main-board-helper", {
                "tiles-printed": tilesPrinted
            })}
            ref={mainBoardRef}
            id="main-board-helper"
        >
            <div
                className={classNames("tiles-container", {
                    "first-step": false
                })}
                ref={tilesContainerRef}
                style={{
                    transform: `scale(${TILE_DEFAULT_SCALE_SIZE})`
                }}
            >
                <BoardTiles updateTilesPositionsAndSizes={updateTilesPositionsAndSizes} />
            </div>
        </div>
    );
};

export default memo(BoardTilesWrapper);
