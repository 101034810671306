import { VALIDATION_COOKIE_NAME } from "../constants";

export const httpService = ({
    url,
    method,
    body,
    headers = { "Content-Type": "application/json;charset=utf-8" },
    successHandler,
    errorHandler
}) => {
    fetch(url, {
        method,
        headers: {
            ...headers,
            [VALIDATION_COOKIE_NAME]: getValidationCookie()
        },
        body: body && JSON.stringify(body)
    })
        .then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        })
        .then(data => successHandler?.(data))
        .catch(err => errorHandler?.(err));
};

export const getValidationCookie = () => {
    return localStorage.getItem(VALIDATION_COOKIE_NAME);
};
