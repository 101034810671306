import {
    HubConnectionBuilder,
    HttpTransportType,
    HubConnectionState,
    JsonHubProtocol,
    LogLevel
} from "@microsoft/signalr";
import { HIDDEN, isHistory, VISIBILITY_CHANGE } from "../constants";
import store from "../store";
import { setGameVisibility, setUserConnectionLost, setWeakConnection } from "../store/slices/gameData";

let connectTryCount = 0;
export let connection = null;

export const startSignalR = (url, startHandler, messageListener, connectionLost, canStartConnection = true) => {
    let forceStop;
    const createConnection = () => {
        const connectionOptions = {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        };

        connection = new HubConnectionBuilder()
            .withUrl(url, connectionOptions)
            .withAutomaticReconnect([0, 0, 1000])
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(LogLevel.Information)
            .build();

        // connection = new HubConnectionBuilder()
        //     .withUrl(url, {
        //         skipNegotiation: true,
        //         transport: HttpTransportType.WebSockets
        //     })
        //     .build();
    };

    const handleStart = async reconnected => {
        try {
            await connection.start({ pingInterval: 15000 });
            connectTryCount = 0;
            typeof startHandler === "function" && (await startHandler(connection, reconnected));
            reconnected && (forceStop = false);
        } catch (err) {
            if (
                connection.connectionState === HubConnectionState.Disconnected ||
                connection.connectionState === HubConnectionState.Disconnecting
            ) {
                // eslint-disable-next-line no-console
                console.log(`Error while establishing connection ${err}`);
                store.dispatch(setWeakConnection(false));
                store.dispatch(setUserConnectionLost(true));
                if (connectTryCount < 30) {
                    ++connectTryCount;
                    setTimeout(() => {
                        handleStart(reconnected);
                    }, 3000);
                }
            }
        }
    };

    const addSocketListenersAndStart = () => {
        typeof messageListener === "function" && messageListener(connection);
        canStartConnection && handleStart();
    };

    const reconnectAfterOnClose = () => {
        connection.onclose(() => {
            typeof connectionLost === "function" && connectionLost();
            !forceStop &&
                handleStart(true).then(() => {
                    // eslint-disable-next-line no-console
                    console.log("reconnected after onClose");
                });
        });
    };

    createConnection();
    addSocketListenersAndStart();
    reconnectAfterOnClose();

    window.addEventListener("offline", () => {
        connection?.stop();
    });

    const visibilityChangeHandler = async () => {
        if (!isHistory) {
            if (!document[HIDDEN]) {
                forceStop = true;
                connection?.stop().then(() => {
                    handleStart(true);
                });
            } else {
                store.dispatch(setGameVisibility(false));
            }
        }
    };

    window.addEventListener(VISIBILITY_CHANGE, visibilityChangeHandler, false);
};

export const invoke = (type, params) => {
    if (params !== undefined) {
        connection.invoke(type, ...params);
    } else {
        connection.invoke(type);
    }
};
