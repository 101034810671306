import store from "../store";

const defaultTexts = {
    Accept: "Accept",
    Agree: "Agree",
    Background: "Background",
    BaseStake: "Base Stake",
    Beaver: "Beaver",
    Board: "Board",
    Cancel: "Cancel",
    Change: "Change ",
    ChooseOption: "Please choose an option.",
    Confirm: "Confirm",
    ConnectionLost: "Connection Lost",
    CurrentStake: "Current Stake",
    Deny: "Deny",
    Disagree: "Disagree",
    DoYouWantToDoubleScore: "Would you like to Double the Score?",
    DoYouWantToDoubleStake: "Would you like to Double the Stake?",
    DoYouWantToExitGame: "If you leave the game, you will lose. Are you sure you want leave?",
    Double: "Double",
    DoubleStake: "Double Stake",
    DoubleStakeOffer: "Your opponent offers to double the stake. Otherwise you will lose.",
    DoubleStakeRequest: "Double stake request",
    EntranceFee: "Buy-in",
    Game: "Game",
    GameId: "Game ID",
    Leave: "Leave",
    LeaveGame: "Leave Game",
    LeaveMatch: "Leave Match",
    Match: "Match",
    MaxStake: "Max Stake",
    Ok: "Ok",
    OpponentDoesntAgree: "Your opponent doesn't agree",
    OpponentLeavesGame: "Opponent Leaves The Game",
    Price: "Price:",
    PrivateGame: "Private Game",
    Prize: "Prize",
    RangeTotalBonus: "Range Total Bonus",
    Recommend: "Recommend",
    Redouble: "Redouble",
    Reject: "Reject",
    Remain: "Remain",
    Themes: "Themes",
    AutoDeal: "Auto Deal",
    DragAndDrop: "Drag And Drop",
    AutoDealIsInProgress: "Auto deal is in progress",
    Winner: "Winner",
    Refresh: "Refresh",
    Attention: "Attention",
    LowConnection: "Connection Is Too Low",
    PressRefreshBtn: "Press the Refresh Button to continue the game",
    connectionAutoResume: "The game will automatically resume when the connection is restored",
    Round: "Round",
    Save: "Save",
    Score: "Score",
    Settings: "Settings",
    ShowAnimations: "Show Animations",
    Stay: "Stay",
    SumOfTotalBonuses: "Sum of Total Bonuses",
    Surrender: "Surrender",
    TimeOut: "Time out",
    ToEnd: "Game Over",
    TournamentId: "Tournament ID",
    WantsToSurrend: "Your opponent wants to surrender with #",
    YourFinalStake: "Your final Stake after doubling:",
    GuestMode: "Guest Mode"
};

export function getTranslations() {
    const translations = store.getState().translations;
    return key => translations[key] || (defaultTexts && defaultTexts[key]) || key;
}
