import React, { memo, useMemo } from "react";
import ArrowToLeft from "../../../Icons/ArrowToLeft";
import ArrowToRight from "../../../Icons/ArrowToRight";
import { getSettingsThemeData, useShallowSelector } from "../../../../store/selectors";
import PropTypes from "prop-types";
import { getImageUrl } from "../../../../helpers/localHelperFunctions";
import classNames from "classnames";
import { getTranslations } from "../../../../helpers/translation";

const Themes = ({ themeIndex, setThemeIndex }) => {
    const themes = useShallowSelector(getSettingsThemeData);
    const transformPercentage = useMemo(() => themeIndex * -25, [themeIndex]);
    const toLeftDisabled = themeIndex === 0;
    const toRightDisabled = themeIndex === themes.length - 1;

    const translate = useMemo(() => getTranslations(), []);

    const moveLeft = () => {
        if (themeIndex > 0) {
            setThemeIndex(themeIndex - 1);
        }
    };

    const moveRight = () => {
        if (themeIndex < themes.length - 1) {
            setThemeIndex(themeIndex + 1);
        }
    };

    return (
        <div className="buttons-navigation-block">
            <button
                className={classNames("btn-left", {
                    "disabled-arrow": toLeftDisabled
                })}
                disabled={toLeftDisabled}
                onClick={moveLeft}
                style={{ cursor: toLeftDisabled ? "auto" : "pointer" }}
            >
                <ArrowToLeft />
            </button>
            <div className="theme-container">
                <div className="single-theme" style={{ transform: `translateX(${transformPercentage}%)` }}>
                    {themes.map(theme => {
                        return (
                            <div key={theme.name + theme.url + theme.id} style={{ width: "25%" }}>
                                <img alt="" src={getImageUrl(theme.url)} />
                                <p className="title">{translate(`${theme.name}View`)}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <button
                className={classNames("btn-right", {
                    "disabled-arrow": toRightDisabled
                })}
                disabled={toRightDisabled}
                onClick={moveRight}
                style={{ cursor: toRightDisabled ? "auto" : "pointer" }}
            >
                <ArrowToRight />
            </button>
        </div>
    );
};

Themes.propTypes = {
    themeIndex: PropTypes.number.isRequired,
    setThemeIndex: PropTypes.func.isRequired
};

export default memo(Themes);
