import React, { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { POPUP_NAMES } from "../../constants";
import LeaveGame from "./LeaveGame";
import SettingsPopup from "./SettingsPopup";
import DoubleStake from "./DoubleStake";
import DoubleStakeAccept from "./DoubleStakeAccept";
import { getTranslations } from "../../helpers/translation";
import { closePopup } from "../../store/slices/popup";
import { getPopupData, useShallowSelector } from "../../store/selectors";

const Popups = () => {
    const translate = useMemo(() => getTranslations(), []);
    const close = useCallback(() => dispatch(closePopup()), []);
    const { name, data, disableScreen } = useShallowSelector(getPopupData);
    const dispatch = useDispatch();

    const renderPopup = () => {
        switch (name) {
            case POPUP_NAMES.SETTINGS:
                return <SettingsPopup translate={translate} close={close} data={data} />;
            case POPUP_NAMES.DOUBLE_STAKE:
                return <DoubleStake translate={translate} close={close} />;
            case POPUP_NAMES.DOUBLE_STAKE_ACCEPT:
                return <DoubleStakeAccept translate={translate} close={close} data={data} />;
            case POPUP_NAMES.LEAVE_GAME:
                return <LeaveGame translate={translate} close={close} />;
        }
    };

    return name && !disableScreen ? (
        <div className="popup-container">
            <div className="overlay" />
            <div
                className={classNames("popup", {
                    "settings-popup": name === POPUP_NAMES.SETTINGS
                })}
            >
                {renderPopup()}
            </div>
        </div>
    ) : null;
};

export default memo(Popups);
