import {
    DOWN_DIRECTION_START_MIN_COUNT,
    LEFT_DIRECTION_START_MIN_COUNT,
    POINTS_MAP_BY_TYPE,
    POSITIONS,
    RIGHT_DIRECTION_START_MIN_COUNT,
    TILE_MOVE_COURSE_ID,
    TILE_MOVE_COURSE_NAME,
    TILES_DIRECTIONS_BASED_ON_COUNT,
    TILES_MAP,
    UP_DIRECTION_START_MIN_COUNT
} from "../constants";
import store from "../store";
import { batch } from "react-redux";
import { setBazarDominoMove, toggleAnimation } from "../store/slices/gameData";
import { separateHost } from "./utility";

/**
 * opens App when all necessary data is loaded
 * @param {function} resolve
 * @return {function}
 */
export function setAppReady(resolve) {
    let firstDataLoaded = null;
    return (key, data) => {
        if (firstDataLoaded) {
            firstDataLoaded[key] = data;
            resolve(firstDataLoaded);
        } else {
            firstDataLoaded = { [key]: data };
        }
    };
}

/**
 * destruct all keys from one obj into another. this function is for only redux slice not for simply merge objects
 * @param {object} target
 * @param {object} source
 * @return {undefined}
 */
export function join2Objects(target, source) {
    for (let key in source) {
        if (source.hasOwnProperty(key)) {
            target[key] = source[key];
        }
    }
}

/**
 * detect if the first tile skeleton is rotated oor not
 * @param {object} state
 * @return {undefined}
 */
export function detectFirstTileSkeletonPositioon(state) {
    const { board, isPlayerTurn, player, isGuest } = state;
    if (isPlayerTurn && !isGuest && board.centralDominoId === -1) {
        state.firstPossibleTileIsRotated = !player.dominoes.find(i => TILES_MAP[i][0] === TILES_MAP[i][1]);
    }
}

/**
 * destruct all keys from one obj into another. this function is for only redux slice not for simply merge objects
 * @param {number} moveCourse
 * @param {number} index
 * @return {undefined | number}
 */
export function getEachTileDirectionBasedOnCount(moveCourse, index) {
    const currentDirInfo = TILES_DIRECTIONS_BASED_ON_COUNT[moveCourse];
    if (currentDirInfo && index + 1 > currentDirInfo.min) {
        return currentDirInfo.directionRanges.find(i => i.from === index)?.direction;
    }
}

/**
 * get event tiles count in passed array
 * @param {array} dominoes
 * @return {number}
 */
export function getEvenTilesCount(dominoes) {
    return dominoes.filter(i => {
        const domino = TILES_MAP[i.dominoId];
        return domino[0] === domino[1];
    }).length;
}

/**
 * destruct all keys from one obj into another. this function is for only redux slice not for simply merge objects
 * @param {object} dominoesSet
 * @param {number} moveCourse
 * @return {object}
 */
export function getDirectionByTilesCount(dominoesSet, moveCourse = 0) {
    if (moveCourse === TILE_MOVE_COURSE_ID.CENTER) {
        return { direction: moveCourse };
    }
    if (!dominoesSet) {
        // this if is for call from playerTiles component. the board data is not available there due to performance issues
        dominoesSet = store.getState().gameData.board[`${TILE_MOVE_COURSE_NAME[moveCourse]}DominoesSet`];
    }
    let direction = moveCourse;
    let directionFirstStep = false;
    let length = dominoesSet.dominoes.length + 1;

    //TODO: make directions lengths dynamic based on even tiles count in that direction
    switch (moveCourse) {
        case TILE_MOVE_COURSE_ID.UP:
            if (length > UP_DIRECTION_START_MIN_COUNT) {
                if (length <= 7) {
                    directionFirstStep = length === 3;
                    direction = TILE_MOVE_COURSE_ID.LEFT;
                } else if (length <= 14) {
                    directionFirstStep = length === 8;
                    direction = TILE_MOVE_COURSE_ID.DOWN;
                } else {
                    directionFirstStep = length === 15;
                    direction = TILE_MOVE_COURSE_ID.RIGHT;
                }
            }
            break;
        case TILE_MOVE_COURSE_ID.RIGHT:
            if (length > RIGHT_DIRECTION_START_MIN_COUNT) {
                if (length <= 12) {
                    directionFirstStep = length === 8;
                    direction = TILE_MOVE_COURSE_ID.UP;
                } else if (length <= 23) {
                    directionFirstStep = length === 13;
                    direction = TILE_MOVE_COURSE_ID.LEFT;
                } else {
                    directionFirstStep = length === 24;
                    direction = TILE_MOVE_COURSE_ID.DOWN;
                }
            }
            break;
        case TILE_MOVE_COURSE_ID.DOWN:
            if (length > DOWN_DIRECTION_START_MIN_COUNT) {
                if (length <= 11) {
                    directionFirstStep = length === 2;
                    direction = TILE_MOVE_COURSE_ID.RIGHT;
                } else if (length <= 14) {
                    directionFirstStep = length === 12;
                    direction = TILE_MOVE_COURSE_ID.UP;
                } else {
                    directionFirstStep = length === 15;
                    direction = TILE_MOVE_COURSE_ID.LEFT;
                }
            }
            break;
        case TILE_MOVE_COURSE_ID.LEFT:
            if (length > LEFT_DIRECTION_START_MIN_COUNT) {
                if (length <= 7) {
                    directionFirstStep = length === 4;
                    direction = TILE_MOVE_COURSE_ID.DOWN;
                } else if (length <= 18) {
                    directionFirstStep = length === 8;
                    direction = TILE_MOVE_COURSE_ID.RIGHT;
                } else {
                    directionFirstStep = length === 20;
                    direction = TILE_MOVE_COURSE_ID.UP;
                }
            }
            break;
        default:
            break;
    }

    return { direction, directionFirstStep };
}

/**
 * destruct all keys from one obj into another. this function is for only redux slice not for simply merge objects
 * @param {object} board
 * @param {number} moveCourse
 * @return {undefined}
 */
export function setDominoDirections(board, moveCourse = 0) {
    if (moveCourse) {
        const dominoesKey = `${TILE_MOVE_COURSE_NAME[moveCourse]}DominoesSet`;
        const { direction, directionFirstStep } = getDirectionByTilesCount(board[dominoesKey], moveCourse);
        board[dominoesKey] = { ...board[dominoesKey], direction, directionFirstStep };
    } else {
        for (const key in board) {
            if (board.hasOwnProperty(key) && key.includes("DominoesSet")) {
                const newMoveCourse = key.substring(0, key.indexOf("DominoesSet"));
                const { direction, directionFirstStep } = getDirectionByTilesCount(
                    board[key],
                    TILE_MOVE_COURSE_ID[`${newMoveCourse.toUpperCase()}`]
                );

                board[key] = { ...board[key], direction, directionFirstStep };
            }
        }
    }
}

/**
 * get elements distance by Pythagorean Theorem
 * @param {Element} el1
 * @param {Element} el2
 * @return {number}
 */
export function getElementsDistance(el1, el2) {
    const el1rect = el1.getBoundingClientRect();
    const el2rect = el2.getBoundingClientRect();

    // get el1's center point
    const el1x = el1rect.left + el1rect.width / 2;
    const el1y = el1rect.top + el1rect.height / 2;

    // get el2's center point
    const el2x = el2rect.left + el2rect.width / 2;
    const el2y = el2rect.top + el2rect.height / 2;

    // calculate the distance using the Pythagorean Theorem (a^2 + b^2 = c^2)
    const distanceSquared = Math.pow(el1x - el2x, 2) + Math.pow(el1y - el2y, 2);
    return Math.sqrt(distanceSquared);
}

/**
 * update dominoes directions based on step data
 * @param {number} dominoId
 * @param {object} state
 * @param {number} bazarDominoId
 * @param {boolean} isPlayerPlayed
 * @param {object} player
 * @param {object} opponent
 * @param {boolean} isGuest
 * @return {undefined}
 */
export function updatePlayersDominoes(dominoId, state, bazarDominoId, isPlayerPlayed, player, opponent, isGuest) {
    state.player = { ...state.player, ...player };
    state.opponent = { ...state.opponent, ...opponent };
    if (dominoId) {
        if (isPlayerPlayed) {
            if (isGuest) {
                state.player.dominoes.pop();
            } else {
                state.player.dominoes = state.player.dominoes.filter(i => i !== dominoId);
            }
        } else {
            state.opponent.dominoes.pop();
        }
    } else {
        if (isPlayerPlayed) {
            state.player.dominoes.push(isGuest ? 0 : bazarDominoId);
        } else {
            state.opponent.dominoes.push(0);
        }
    }
}

/**
 * update board dominoes directions based on step data
 * @param {object} dominoMove
 * @param {object} board
 * @return {undefined}
 */
export function updateBoardDominoes(dominoMove, board) {
    let currentDominoesSet = null;
    const { dominoId, moveCourse, connectorTile } = dominoMove;

    switch (moveCourse) {
        case TILE_MOVE_COURSE_ID.UP:
            currentDominoesSet = board.upDominoesSet;
            break;
        case TILE_MOVE_COURSE_ID.RIGHT:
            currentDominoesSet = board.rightDominoesSet;
            break;
        case TILE_MOVE_COURSE_ID.DOWN:
            currentDominoesSet = board.downDominoesSet;
            break;
        case TILE_MOVE_COURSE_ID.LEFT:
            currentDominoesSet = board.leftDominoesSet;
            break;
        case TILE_MOVE_COURSE_ID.CENTER:
        default:
            board.centralDominoId = dominoId;
            break;
    }

    if (currentDominoesSet) {
        const tile = TILES_MAP[dominoId];
        if (tile[0] === tile[1]) {
            currentDominoesSet.setConnectorTile = tile[0];
        } else {
            currentDominoesSet.setConnectorTile = tile.find(i => i !== connectorTile);
        }
        currentDominoesSet.dominoes.push({ dominoId, connectorTile });
    }
}

/**
 * get valid directions rotate info
 * @param {number} direction
 * @return {string}
 */
export const getValidDirectionRotateInfo = (direction = TILE_MOVE_COURSE_ID.LEFT) => {
    switch (direction) {
        case TILE_MOVE_COURSE_ID.UP:
        case TILE_MOVE_COURSE_ID.DOWN:
            return POSITIONS.BOTTOM_LEFT;
        case TILE_MOVE_COURSE_ID.RIGHT:
            return POSITIONS.TOP_RIGHT;
        case TILE_MOVE_COURSE_ID.CENTER:
        case TILE_MOVE_COURSE_ID.LEFT:
        default:
            return POSITIONS.TOP_LEFT;
    }
};

/**
 * rename all short keys in valid keys
 * @param {object} obj,
 * @param {object} keyMap,
 * @return {object}
 */
export function renameObjectKeys(obj, keyMap) {
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        const value = obj[key];

        if (Array.isArray(value)) {
            acc[newKey] = value.map(item => {
                if (item && typeof item === "object") {
                    return renameObjectKeys(item, keyMap);
                }
                return item;
            });
        } else if (value && typeof value === "object") {
            acc[newKey] = renameObjectKeys(value, keyMap);
        } else {
            acc[newKey] = value;
        }
        return acc;
    }, {});
}

export const addClosestMethodForOldBrowsers = () => {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    Element.prototype.closest = function(s) {
        let el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
};

/**
 * get valid directions rotate info
 * @param {$ElementType} el
 * @param {string} selector
 * @return {string}
 */
export const getClosestParentNodeBySelector = (el, selector) => {
    if (!Element.prototype.closest) {
        addClosestMethodForOldBrowsers();
    }
    return el.closest?.(selector);
};

/**
 * get valid directions rotate info
 * @param {number} moveCourse
 * @param {object} board
 * @return {object}
 */
export const getTargetTileMoveCourseInfo = (moveCourse, board) => {
    let moveCourseDirection;
    let moveCourseToSet = moveCourse;
    if (moveCourse !== TILE_MOVE_COURSE_ID.CENTER) {
        const { dominoes, direction } = board[`${TILE_MOVE_COURSE_NAME[moveCourse]}DominoesSet`];
        moveCourseDirection = direction;
        if (!dominoes.length) {
            moveCourseToSet = TILE_MOVE_COURSE_ID.CENTER;
        }
    }

    return { moveCourseToSet, moveCourseDirection };
};

/**
 * get valid directions rotate info
 * @param {object} state
 * @return {undefined}
 */
export const setHistoryCurrentData = state => {
    const step = state.rounds[state.currentRound].steps[state.currentStep];

    join2Objects(state, step);
};

/**
 * end game data
 * @param {object} state
 * @param {object} payload
 */
export const endGameData = (state, { payload }) => {
    const { playerGameTime, opponentGameTime, playerGameScore, opponentGameScore, isPlayerWin } = payload;

    state.player.gameTime = playerGameTime;
    state.opponent.gameTime = opponentGameTime;
    state.gameScore.playerGameScore = playerGameScore;
    state.gameScore.opponentGameScore = opponentGameScore;
    state.isPlayerWin = isPlayerWin;
    state.stopTimer = true;
    state.disableScreen = true;

    state.player.gameTime = 0;
    state.opponent.gameTime = 0;
    state.gameScore.playerGameScore = 0;
    state.gameScore.opponentGameScore = 0;
};

/**
 * remove px from style
 * @param {string} padding
 * @return {number}
 */
export const removePX = padding => {
    return Number(padding.substring(0, padding.indexOf("px")));
};

/**
 * detect if point is valuable
 * @param {number} point
 * @return {boolean}
 */
export const isMovePoint = point => {
    if (point < 1) {
        return false;
    }
    const { gameType } = store.getState().gameStaticData.gameConfiguration;
    const divider = POINTS_MAP_BY_TYPE[gameType];
    return Boolean(point % divider === 0);
};

/**
 * detect if point is valuable
 * @param {function} next
 * @param {object} domino
 * @return {undefined}
 */
export const playBazarMoveIfChanceTileIsRendered = (next, domino) => {
    if (document.getElementById("chance-tile")) {
        batch(() => {
            next(toggleAnimation({ toggle: true, dominoMove: domino }));
            next(setBazarDominoMove(domino));
        });
    } else {
        const intervalId = setInterval(() => {
            if (document.getElementById("chance-tile")) {
                clearInterval(intervalId);
                batch(() => {
                    next(toggleAnimation({ toggle: true, dominoMove: domino }));
                    next(setBazarDominoMove(domino));
                });
            }
        }, 100);
    }
};

export const getImageUrl = imageUrl => {
    if (imageUrl) {
        if (process.env.REACT_APP_IS_DYNAMIC_IMAGE_URL === "false") {
            return "https://cdn-sg.deekjdsg-9q87vb3p.org" + imageUrl;
        } else {
            return "https://cdn-sg." + separateHost() + imageUrl;
        }
    }
};

/**
 * update html lang attribute
 * @param lang
 */
export function updateDocumentLanguage(lang) {
    document.documentElement.lang = lang;
}
