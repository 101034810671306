import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { OldTimer } from "skill-games/components";

import { FINISH_TYPE_TIME_OUT, isHistory } from "../../constants";

import Notify from "../Notify";
import InfoText from "../InfoText";
import { getTranslations } from "../../helpers/translation";
import { getOpponentInfo, useShallowSelector } from "../../store/selectors";

const OpponentInfo = ({ finishType }) => {
    const translate = useMemo(() => getTranslations(), []);
    let {
        extraTime,
        isPlayerTurn,
        stopTimer,
        stepTime,
        gameTime,
        notification,
        isConnected,
        doubleStakeData,
        nickName
    } = useShallowSelector(getOpponentInfo);

    if (finishType === FINISH_TYPE_TIME_OUT) {
        gameTime = 0;
    }

    return (
        <div className="player-info-wrapper opponent">
            <OldTimer
                className="opponent-timer"
                extraTime={extraTime}
                isWorking={stopTimer ? false : !isPlayerTurn}
                remainingTime={stepTime}
                remainingExtraTime={gameTime}
                updaters={[stepTime, gameTime]} //roundNumber
                historyMode={isHistory}
                isOnline={isConnected}
            />
            {notification && (
                <Notify className={"notification-lg"}>
                    <span className="">{translate(notification)}</span>
                </Notify>
            )}
            <div className="notifications-wrapper">
                {doubleStakeData && !doubleStakeData.isPlayer && (
                    <Notify className={"notification-sm"}>{doubleStakeData.currentStake}X</Notify>
                )}
            </div>
            <div className="info-wrapper">
                <InfoText playerName={`${translate("You")} / ${nickName}`} finishType={finishType} />
            </div>
        </div>
    );
};

OpponentInfo.propTypes = {
    finishType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

OpponentInfo.defaultProps = {
    finishType: null
};

export default memo(OpponentInfo);
