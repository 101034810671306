import React from "react";
import { dots } from "../../../../constants";
import PropTypes from "prop-types";

const Dots = ({ value }) => {
    return (
        <svg
            className={`tile-${value}`}
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 50 50"
        >
            <defs>
                <radialGradient
                    id="dots-color-theme-1"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#5f5f5f" />
                    <stop offset="1" stopColor="#191919" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-2"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#6c4b29" />
                    <stop offset="1" stopColor="#412c17" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-4"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="1" stopColor="#dbdbdb" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-1"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#7E4D4D" />
                    <stop offset="1" stopColor="#552F2F" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-2"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#76BBD0" />
                    <stop offset="1" stopColor="#5794A6" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-3"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#BFCC7A" />
                    <stop offset="1" stopColor="#9AA657" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-4"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#D1758F" />
                    <stop offset="1" stopColor="#A6576D" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-5"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#90AFA7" />
                    <stop offset="1" stopColor="#66857E" />
                </radialGradient>
                <radialGradient
                    id="dots-color-theme-3-6"
                    cx="0.5"
                    cy="0.615"
                    r="0.355"
                    gradientTransform="matrix(0, 1, -0.936, 0, 1.208, 0.256)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#A39BC6" />
                    <stop offset="1" stopColor="#6C6685" />
                </radialGradient>
            </defs>
            <g id="Group_34051" data-name="Group 34051" transform="translate(-765 -1372)">
                {dots[1].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(765 1372)" />
                )}
                {dots[2].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(765 1390)" />
                )}
                {dots[3].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(765 1408)" />
                )}
                {dots[4].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(783 1390)" />
                )}
                {dots[5].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(801 1372)" />
                )}
                {dots[6].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(801 1390)" />
                )}
                {dots[7].includes(value) && (
                    <path className="dot" d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(801 1408)" />
                )}
            </g>
        </svg>
    );
};

Dots.propTypes = {
    value: PropTypes.number
};

Dots.defaultProps = {
    value: null
};

export default Dots;
