import React, { memo } from "react";
import PropTypes from "prop-types";

const ToggleSwitchItem = ({ name, onChange, checked, disabled }) => {
    return (
        <div className="toggle-switch-item">
            <label>
                <input
                    disabled={disabled}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                    className="switch-control"
                />
                <span className="toggle-switch" />
                {/*<span className="switch-label">{name}</span>*/}
                <span className="switch-label">{name}</span>
            </label>
        </div>
    );
};

ToggleSwitchItem.propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func
};

ToggleSwitchItem.defaultProps = {
    name: "",
    checked: false,
    disabled: false,
    onChange: () => {},
    onClick: () => {}
};

export default memo(ToggleSwitchItem);
