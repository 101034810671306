import React from "react";

const MultiStep = () => {
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 14.768">
        //     <path
        //         d="M10.378,4.726c0-.5-.214-.726-.7-.726H3.727C3.2,4,3,4.212,3,4.749V18.128a.58.58,0,0,0,.578.64H3.6q3.094,0,6.187,0a1.193,1.193,0,0,0,.376-.111l-.007,0a.776.776,0,0,0,.226-.55c0-.012,0-.023,0-.035v0q0-6.674,0-13.346ZM6.147,7.1a.753.753,0,0,1,1.084,0h0a.812.812,0,0,1,.165.871l0-.006a.762.762,0,0,1-1.421,0l0,0a.8.8,0,0,1-.057-.3.807.807,0,0,1,.23-.565h0Zm1.078,8.579a.767.767,0,0,1-1.087-.014h0a.814.814,0,0,1,.01-1.118h0a.754.754,0,0,1,1.087.01h0a.81.81,0,0,1-.01,1.121h0Zm2.646-4.1H3.5c0-.134.009-.253.012-.385H9.883c-.007.127-.01.247-.014.385Z"
        //         transform="translate(-2.995 -4)"
        //     />
        //     <path
        //         d="M24.38,4.726c0-.5-.214-.726-.7-.726H17.727C17.2,4,17,4.212,17,4.749V18.128a.594.594,0,0,0,0,.06.58.58,0,0,0,.58.58H17.6q3.094,0,6.188,0a1.193,1.193,0,0,0,.376-.111l-.007,0a.775.775,0,0,0,.226-.55c0-.012,0-.024,0-.036v0q0-6.674,0-13.346Zm-2.338.425a.749.749,0,0,1,1.081.008h0a.8.8,0,0,1-.009,1.114h0a.749.749,0,0,1-1.081-.008h0a.8.8,0,0,1,.006-1.113h0ZM22.035,9.08a.745.745,0,0,1,1.082,0h0a.8.8,0,0,1,0,1.113h0a.749.749,0,0,1-1.081,0h0a.8.8,0,0,1,0-1.115h0ZM18.271,5.154a.773.773,0,0,1,1.117.01h0a.81.81,0,0,1-.028,1.108h0a.76.76,0,0,1-1.107-.009h0a.81.81,0,0,1,.017-1.108h0ZM19.359,10.2a.762.762,0,0,1-1.089-.006h0a.808.808,0,0,1,.013-1.119h0a.75.75,0,0,1,1.083.008h0a.807.807,0,0,1-.01,1.117h0Zm-.006,7.419a.76.76,0,0,1-1.088-.006h0a.807.807,0,0,1,.013-1.118h0a.751.751,0,0,1,1.083.007h0a.805.805,0,0,1-.013,1.116h0Zm0-3.931a.759.759,0,0,1-1.11-.009h0a.81.81,0,0,1,.022-1.108h0a.773.773,0,0,1,1.117.01h0a.808.808,0,0,1-.028,1.1h0Zm3.764,3.928a.756.756,0,0,1-1.087,0h0a.8.8,0,0,1,0-1.113h0a.749.749,0,0,1,1.081,0h0a.811.811,0,0,1,0,1.118h0Zm-.01-3.927a.749.749,0,0,1-1.081-.008h0a.8.8,0,0,1,.007-1.113h0a.749.749,0,0,1,1.081.008h0a.8.8,0,0,1-.009,1.114h0Zm.762-2.114H17.5c0-.134.008-.253.012-.385H23.88c0,.129-.008.247-.012.385Z"
        //         transform="translate(-8.38 -4)"
        //     />
        // </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 44 35">
            <g transform="translate(-490 -521)">
                <path
                    d="M0,35V21.065H4.094V18.847H17V35H0Zm12.748-5.384a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.749,29.616Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-8.077a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.749,21.539Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692ZM17,16.154H4.094v-2.5H0V0H17V16.152Zm-4.251-5.384a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.748,10.769Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-8.077a1.384,1.384,0,0,0-1.416,1.346,1.418,1.418,0,0,0,2.833,0A1.384,1.384,0,0,0,12.748,2.692Zm-8.5,0A1.384,1.384,0,0,0,2.833,4.038,1.384,1.384,0,0,0,4.249,5.384,1.384,1.384,0,0,0,5.666,4.038,1.384,1.384,0,0,0,4.249,2.692Z"
                    transform="translate(517 521)"
                />
                <path
                    d="M0,35V18.847H13.593v2.218H17V35H0Zm12.748-5.384a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.749,29.616Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-4.038a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.749,25.578Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-4.039a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.749,21.539Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm9.343-5.385H0V0H17V13.655H13.593v2.5Zm-.845-5.384a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.748,10.769Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-4.038a1.348,1.348,0,1,0,1.416,1.346A1.384,1.384,0,0,0,12.748,6.731Zm-8.5,0a1.348,1.348,0,1,0,0,2.692,1.348,1.348,0,1,0,0-2.692Zm8.5-4.039a1.384,1.384,0,0,0-1.416,1.346,1.418,1.418,0,0,0,2.833,0A1.384,1.384,0,0,0,12.748,2.692Zm-8.5,0A1.384,1.384,0,0,0,2.833,4.038,1.384,1.384,0,0,0,4.249,5.384,1.384,1.384,0,0,0,5.666,4.038,1.384,1.384,0,0,0,4.249,2.692Z"
                    transform="translate(490 521)"
                />
                <path
                    d="M9.65,0,6.014,3.636,2.378,0,0,2.378,3.636,6.014,0,9.65l2.378,2.378L6.014,8.391,9.65,12.028,12.028,9.65,8.392,6.014l3.636-3.636Z"
                    transform="translate(511.98 529.715) rotate(45)"
                />
            </g>
        </svg>
    );
};

export default MultiStep;
