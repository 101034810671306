import React, { memo, useCallback } from "react";
import { getBazarData, useShallowSelector } from "../../store/selectors";
import { getElementsPosition } from "../../helpers/utility";
import AutoDeal from "./AutoDeal";
import OpenedBazar from "./OpenedBazar";
import { isHistory } from "../../constants";

const Bazar = () => {
    const { isAutoDeal, bazarDominoesCount, isPlayerTurn, bazar, roundFinished } = useShallowSelector(getBazarData);

    const getBazarTileAnimationStyles = useCallback(
        (toggle, tileRef) => {
            let tileStyles = null;
            let wrapperStyles = null;

            if (toggle && tileRef && !isHistory) {
                const chanceTilePositions = getElementsPosition(document.getElementById("chance-tile"));
                const { x, y, width } = getElementsPosition(tileRef);
                const tile = getElementsPosition(
                    document.querySelector(`div.${isPlayerTurn ? "player" : "opponent"}-tiles div.tile-wrapper-helper`)
                );

                wrapperStyles = {
                    transform: `translate(${chanceTilePositions.x - x}px, ${chanceTilePositions.y -
                        y}px) scale(${tile.width / width})`,
                    transformOrigin: "left top",
                    transition: "transform 0.5s linear"
                };

                tileStyles = { transform: "unset" };
            }

            return { tileStyles, wrapperStyles };
        },
        [isPlayerTurn]
    );

    return (
        <div className="bone-yard-container">
            {bazar && (
                <>
                    {isAutoDeal && !roundFinished ? (
                        <AutoDeal getBazarTileAnimationStyles={getBazarTileAnimationStyles} />
                    ) : (
                        <OpenedBazar getBazarTileAnimationStyles={getBazarTileAnimationStyles} />
                    )}
                </>
            )}

            <div className="bone-yard-closed">
                <div className="tiles-quantity">
                    <span className="text">{bazarDominoesCount}</span>
                </div>
            </div>
        </div>
    );
};

export default memo(Bazar);
