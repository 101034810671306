import React, { useMemo } from "react";
import { getTranslations } from "../../helpers/translation";

const WinnerBadge = () => {
    const translate = useMemo(() => getTranslations(), []);

    return (
        <div className="winner-badge">
            <span>{translate("Winner")}</span>
        </div>
    );
};

export default WinnerBadge;
