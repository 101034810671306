import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { SOCKET_INVOKE_ACTIONS } from "../../../constants";
import PopupHeader from "../PopupHeader";
import PopupFooter from "../PopupFooter";
import { invoke } from "../../../connection";
import { getTranslations } from "../../../helpers/translation";

const LeaveGame = ({ close }) => {
    const [disabled, setDisabled] = useState(false);
    const translate = useMemo(() => getTranslations(), []);

    const leaveGame = () => {
        invoke(SOCKET_INVOKE_ACTIONS.LEAVE_GAME);
        setDisabled(true);
    };

    return (
        <>
            <PopupHeader name={translate("LeaveGame")} />
            <div className="popup-content">
                <div className="popup-text">
                    <p>{translate("DoYouWantToExitGame")}</p>
                </div>
            </div>
            <PopupFooter
                close={close}
                onSubmit={leaveGame}
                closeButtonText={translate("Cancel")}
                submitButtonText={translate("Leave")}
                disabled={disabled}
            />
        </>
    );
};

LeaveGame.propTypes = {
    close: PropTypes.func.isRequired
};

export default LeaveGame;
