import React, { memo, useState } from "react";
import Close from "../Icons/Close";
import MultiStep from "../Icons/MultiStep";
import classNames from "classnames";

const PlayerNotification = () => {
    const [showMultiStepNotification, setShowMultiStepNotification] = useState(false);
    const [closeAnimation, setCloseAnimation] = useState(false);

    const closeMultiStepNotificationHandler = () => {
        setCloseAnimation(true);
        setTimeout(() => {
            setShowMultiStepNotification(null);
        }, 400);
    };

    if (showMultiStepNotification) {
        return (
            <div className={classNames("player-notification", { close: closeAnimation })}>
                {/*add "close" classname after clicking on close button for animation*/}
                For <span className="text-colored">multistep</span> choose 2 or more bones and press
                <span className="icon-multistep">
                    <MultiStep />
                </span>
                <button className="btn-close" onClick={closeMultiStepNotificationHandler}>
                    <Close />
                </button>
            </div>
        );
    } else {
        return null;
    }
};

export default memo(PlayerNotification);
