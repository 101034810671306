import getSymbolFromCurrency from "currency-symbol-map";
import { APP_PROPERTIES, isMobile, POSITIONS } from "../constants";
import { detect } from "detect-browser";

/**
 * Returns a parameter from Url
 * @param {string} paramsArr - The Url params
 * @return {object}
 */

export function getUrlParams(paramsArr) {
    let urlParams = {};
    const url = new URL(window.location.href);
    for (let i = 0, len = paramsArr.length; i < len; ++i) {
        urlParams[paramsArr[i]] = url.searchParams.get(paramsArr[i]);
    }
    return urlParams;
}

export const getCurrencySign = currency => {
    return getSymbolFromCurrency(currency) || currency;
};

export function postCloseWindow() {
    window.parent.postMessage("closeWindow", "*");
}

/**
 * Returns initial playerInfo data from Url
 * @return {object}
 */

export function getGameDataFromUrl() {
    let {
        gameId,
        lang,
        token,
        isMobile,
        playerId,
        consumerPartnerId,
        currencyId,
        win,
        bet,
        guestId,
        isDemo
    } = getUrlParams([
        "gameId",
        "playerId",
        "lang",
        "token",
        "isMobile",
        "consumerPartnerId",
        "currencyId",
        "win",
        "bet",
        "guestId",
        "isDemo"
    ]);
    isMobile = (isMobile && isMobile.toLowerCase()) === "true" || false;
    isDemo = (isDemo && isDemo.toLowerCase()) === "true" || false;
    const isHistory = window.location.pathname.includes("/game/history");

    win = win && win.replace(",", ".");
    bet = bet && bet.replace(",", ".");

    return {
        gameId,
        playerId,
        token,
        lang,
        isMobile,
        isHistory,
        consumerPartnerId,
        currencyId,
        win,
        bet,
        guestId,
        isDemo
    };
}

/**
 * @param gameColumnWidth
 * @param isMobile
 * In base of playerInfo width, function calculates
 * root font-size and sets on HTML tag.
 * If app is running in mobile mode,`
 * root font size must me 12.
 * @return {undefined}
 */

export const setRootFontSize = (gameColumnWidth, isMobile) => {
    const rootElement = document.getElementsByTagName("html")[0];
    if (isMobile) {
        rootElement.style.fontSize = `${(gameColumnWidth * 2) / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX}px`;
        return;
    }
    rootElement.style.fontSize = `${gameColumnWidth / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX}px`;
};

/**
 * @param clientWidth
 * @param clientHeight
 * In base of client width and client height,
 * function return playerInfo Dimensions in (16/9),
 * aspect ration.
 * @return {object}
 */

export const calculateGameDimension = (clientWidth, clientHeight) => {
    let widthForFontSize = 0,
        finalDimensions = {};

    if (
        (clientWidth * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH <
        clientHeight
    ) {
        widthForFontSize = clientWidth;
        finalDimensions = {
            gameHeight:
                (clientWidth * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH,
            fontSize: widthForFontSize / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX,
            gameWidth: clientWidth
        };
    } else {
        widthForFontSize =
            (clientHeight * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT;
        finalDimensions = {
            gameWidth:
                (clientHeight * APP_PROPERTIES.DEFAULT_ASPECT_RATIO_WIDTH) / APP_PROPERTIES.DEFAULT_ASPECT_RATIO_HEIGHT,
            fontSize: widthForFontSize / APP_PROPERTIES.FONT_SIZE_SCALE_INDEX,
            gameHeight: clientHeight
        };
    }
    setRootFontSize(widthForFontSize, isMobile);
    return { ...finalDimensions, clientWidth, clientHeight };
};

/**
 * get elements positions
 * @param {object} element
 * @param {string} position
 * @returns {object}
 */
export function getElementsPosition(element, position = POSITIONS.TOP_LEFT) {
    if (!element) {
        return;
    }
    let x = 0;
    let y = 0;
    let width, height, xCenter, yCenter;

    const rect = element.getBoundingClientRect();
    switch (position) {
        case POSITIONS.TOP_RIGHT:
            x = rect.right;
            y = rect.top;
            break;
        case POSITIONS.BOTTOM_RIGHT:
            x = rect.right;
            y = rect.bottom;
            break;
        case POSITIONS.CENTER_RIGHT:
            x = rect.right;
            y = rect.bottom - Number((rect.height / 2).toFixed());
            break;
        case POSITIONS.BOTTOM_LEFT:
            x = rect.left;
            y = rect.bottom;
            break;
        case POSITIONS.CENTER_LEFT:
            x = rect.left;
            y = rect.bottom - Number((rect.height / 2).toFixed());
            break;
        case POSITIONS.CENTER:
            x = rect.left + rect.width / 2;
            y = rect.top + rect.height / 2;
            break;
        case POSITIONS.TOP_LEFT:
        default:
            x = rect.left;
            y = rect.top;
            break;
    }
    width = rect.width;
    height = rect.height;
    xCenter = x + width / 2;
    yCenter = y + height / 2;
    return { x, y, width, height, xCenter, yCenter };
}

/**
 * getBrowserSpecificProperties
 * @returns {object}
 */
export function getBrowserSpecificProperties() {
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    return { HIDDEN: hidden, VISIBILITY_CHANGE: visibilityChange };
}

/**
 * detect browser name
 * @returns {string}
 */
export const getBrowserName = () => {
    const browser = detect();

    return browser.name;
};

/**
 * Separate host
 * @returns {string}
 */
export function separateHost() {
    let host = window.location.host.split(".");
    if (host.length > 2) {
        host.shift();
    }
    host = host.join(".");
    return host;
}
