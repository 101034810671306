import React, { memo } from "react";
import PropTypes from "prop-types";
import GameSettings from "../GameSettings";
import ActionButtons from "../ActionButtons";
import OpponentInfo from "../OpponentInfo";
import PlayerInfo from "../PlayerInfo";
import Board from "../Board";
import IdTimeBlock from "../IdTimeBlock";
import Popups from "../Popups";
import PlayerNotification from "../PlayerNotification";
import Bazar from "../Bazar";
import ScreenDisableLayer from "../ScreenDisableLayer";
import ScoreBarWrapper from "../ScoreBarWrapper";
import { getGameCompData, useShallowSelector } from "../../store/selectors";
import classNames from "classnames";
import { isDemo, isHistory } from "../../constants";
import HistoryControllers from "../HistoryControllers";
import WeakConnection from "../WeakConnection";
import UserConnectionLost from "../UserConnectionLost";
import DemoStartButton from "../demoStartButton/DemoStartButton";

const Game = ({ style }) => {
    const { isGuest, weakConnection, connectionLost, disableScreen, isDemoGame } = useShallowSelector(getGameCompData);

    if (isDemo || (disableScreen && isDemoGame)) {
        return <DemoStartButton />;
    }

    return (
        <div
            className={classNames("game-container-helper", {
                "guest-mode": isGuest
            })}
            style={style}
        >
            <ScreenDisableLayer />
            {!isGuest && !isHistory && <Popups />}
            <GameSettings />
            <OpponentInfo />

            <Board />

            <PlayerInfo />
            <ScoreBarWrapper />

            {!isGuest && !isHistory && !isDemoGame && <ActionButtons />}
            <IdTimeBlock />

            <Bazar />

            <PlayerNotification />
            {isHistory && <HistoryControllers />}
            {/*<DemoStartButton />*/}
            {connectionLost && <UserConnectionLost />}
            {weakConnection && <WeakConnection />}
        </div>
    );
};

Game.propTypes = {
    style: PropTypes.object.isRequired
};
Game.defaultProps = {
    style: {}
};

export default memo(Game);
