import React, { memo, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import WinnerGetsAll from "../../Icons/WinnerGetsAll";
import Incognito from "../../Icons/Incognito";
import Private from "../../Icons/Private";
import MultiStep from "../../Icons/MultiStep";
import { bet, currencyId, gameId, isMobile, win } from "../../../constants";
import Close from "../../Icons/Close";
import useOutsideClick from "../../../customHooks/useOutsideClick";
import { getCurrencySign } from "../../../helpers/utility";
import { getGameInfoData, useShallowSelector } from "../../../store/selectors";

const GameInfo = ({ isOpen, infoButtonRef, onClose, translate }) => {
    const ref = useRef(null);
    useOutsideClick(onClose, ref, infoButtonRef);

    const aa = useShallowSelector(getGameInfoData);

    const {
        private: privateConf,
        gamePoints,
        incognito,
        allToWinner,
        tourOrder,
        multiplePair,
        currentStake,
        maxStakeDoubling,
        tournamentId
    } = aa;

    const infos = useMemo(() => {
        return tournamentId
            ? [
                  {
                      name: translate("TournamentId"),
                      value: tournamentId
                  },
                  {
                      name: translate("GameId"),
                      value: gameId
                  },
                  {
                      name: translate("Round"),
                      value: tourOrder
                  },
                  {
                      name: translate("Score"),
                      value: gamePoints
                  }
                  // {
                  //     name: translate("EntranceFee"),
                  //     value: "-"
                  // }
              ]
            : [
                  {
                      name: translate("GameId"),
                      value: gameId
                  },
                  {
                      name: translate("Score"),
                      value: gamePoints
                  },
                  {
                      name: translate("BaseCurrentStake"),
                      value:
                          ((bet && `${parseFloat(bet)} ${getCurrencySign(currencyId) || ""}`) || "-") +
                          "/" +
                          ((bet && `${parseFloat(bet) * (currentStake || 1)} ${getCurrencySign(currencyId) || ""}`) ||
                              "-"),
                      notShow: false
                  },
                  {
                      name: translate("MaxStake"),
                      value: bet && `${parseFloat(bet) * (maxStakeDoubling || 1)} ${getCurrencySign(currencyId) || ""}`,
                      notShow: !maxStakeDoubling
                  },
                  {
                      name: translate("Prize"),
                      value: win && `${win * (currentStake || 1)} ${getCurrencySign(currencyId) || ""}`
                  }
              ];
    }, [maxStakeDoubling, gamePoints, currentStake]);

    const conventions = useMemo(
        () => [
            {
                icon: Private,
                tooltip: translate("PrivateGame"),
                show: privateConf
            },
            {
                icon: Incognito,
                tooltip: translate("Incognito"),
                show: incognito
            },
            {
                icon: WinnerGetsAll,
                tooltip: translate("AllToWinner"),
                show: allToWinner
            },
            {
                icon: MultiStep,
                tooltip: translate("MultiplePair"),
                show: multiplePair
            }
        ],
        [privateConf, incognito, allToWinner, multiplePair]
    );

    const openStateClass = isOpen ? "open-game-info" : "close-game-info";
    return (
        <div ref={ref} className={`game-info-container ${isOpen === null ? "" : openStateClass}`}>
            <div className="game-info">
                {isMobile && (
                    <span className="close-btn" onClick={onClose}>
                        <Close />
                    </span>
                )}
                {infos.map(info => {
                    return (
                        info.notShow || (
                            <p key={info.name}>
                                <span>{info.name}</span>
                                <span>{info.value}</span>
                            </p>
                        )
                    );
                })}
                <div className="conventions">
                    <span className="title">{translate("Conventions")}</span>
                    <div className="tooltip-container-wrapper">
                        {conventions.map(
                            convention =>
                                convention.show && (
                                    <div className="tooltip-container" key={convention.tooltip}>
                                        <span className="icon ">{convention.icon()}</span>
                                        <span className="tooltip-text">{convention.tooltip}</span>
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

GameInfo.propTypes = {
    isOpen: PropTypes.bool,
    infoButtonRef: PropTypes.object.isRequired,
    doubleStake: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    doubleStakeData: PropTypes.object
};

GameInfo.defaultProps = {
    isOpen: null,
    doubleStake: null,
    doubleStakeData: null
};

export default memo(GameInfo);
