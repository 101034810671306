import React from "react";

const Pause = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32">
            <path d="M6,23.667h5.333V5H6ZM16.667,5V23.667H22V5Z" transform="translate(2 1.667)" />
            <path d="M0,0H32V32H0Z" fill="none" />
        </svg>
    );
};

export default Pause;
