import React from "react";
import PropTypes from "prop-types";

const Notify = ({ children, className }) => {
    return <div className={`${className} notification`}>{children}</div>;
};

Notify.defaultProps = {
    children: null,
    className: ""
};

Notify.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
};

export default Notify;
