import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import HalfTile from "./HalfTile";
import { TILE_MOVE_COURSE_ID, TILES_MAP } from "../../../constants";
import classNames from "classnames";
import { isMovePoint } from "../../../helpers/localHelperFunctions";
import { getTileFrontData, useShallowSelector } from "../../../store/selectors";

const Front = ({
    id,
    isCenter,
    boardTile,
    tileId,
    isPlayer,
    centerRightDirection,
    centerLeftDirection,
    clickHandler,
    isLastTile,
    moveCourse,
    possibleTileConnectorInfo
}) => {
    const {
        playedDomino,
        targetTileInfo,
        startAnimation,
        rightDominoesLength,
        leftDominoesLength
    } = useShallowSelector(getTileFrontData);
    const [firstValue, secondValue] = TILES_MAP[tileId] || [];

    const { frontUpConnector, frontDownConnector } = useMemo(() => {
        let frontUpConnector = false;
        let frontDownConnector = false;

        possibleTileConnectorInfo?.forEach(i => {
            if (isCenter && !centerRightDirection && !centerLeftDirection) {
                if (i.connectorTile === firstValue && i.moveCourse === TILE_MOVE_COURSE_ID.UP) {
                    frontUpConnector = i;
                }
                if (i.connectorTile === secondValue && i.moveCourse === TILE_MOVE_COURSE_ID.DOWN) {
                    frontDownConnector = i;
                }
            } else {
                frontUpConnector = i.connectorTile === firstValue && i;
                frontDownConnector = i.connectorTile === secondValue && i;
            }
        });

        return { frontUpConnector, frontDownConnector };
    }, [possibleTileConnectorInfo, tileId, centerRightDirection, centerLeftDirection, isCenter]);

    // const frontUpConnector = possibleTileConnectorInfo?.find(i => i.connectorTile === firstValue);
    // const frontDownConnector = possibleTileConnectorInfo?.find(i => i.connectorTile === secondValue);

    const possibleMoveDirection = useMemo(() => {
        if (boardTile && ((isCenter && firstValue === secondValue) || isLastTile)) {
            if (
                !(
                    playedDomino?.moveCourse === moveCourse &&
                    firstValue === secondValue &&
                    ((isPlayer && centerRightDirection && centerLeftDirection) || !isPlayer) &&
                    !startAnimation
                ) &&
                possibleTileConnectorInfo?.length &&
                !targetTileInfo
            ) {
                if (centerLeftDirection && centerRightDirection) {
                    const leftOrUpDirection = TILE_MOVE_COURSE_ID[`${firstValue === secondValue ? "LEFT" : "UP"}`];
                    const rightOrDownDirection =
                        TILE_MOVE_COURSE_ID[`${firstValue === secondValue ? "RIGHT" : "DOWN"}`];
                    return (
                        <>
                            <div
                                id={`${leftOrUpDirection}`}
                                className="possible-tile-layer-helper-left"
                                onClick={clickHandler(leftOrUpDirection)}
                            />
                            <div
                                id={`${rightOrDownDirection}`}
                                className="possible-tile-layer-helper-right"
                                onClick={clickHandler(rightOrDownDirection)}
                            />
                        </>
                    );
                } else if (
                    centerLeftDirection ||
                    centerRightDirection ||
                    (firstValue === secondValue &&
                        (((!leftDominoesLength || !rightDominoesLength) && isCenter) || isLastTile))
                ) {
                    const cntrRightDirection = centerRightDirection ? TILE_MOVE_COURSE_ID.RIGHT : null;
                    const possDirection = centerLeftDirection ? TILE_MOVE_COURSE_ID.LEFT : cntrRightDirection;
                    const connectorTileValue = frontUpConnector ? firstValue : secondValue;

                    return (
                        <>
                            <div
                                id={`${possDirection || moveCourse}`}
                                className="possible-tile-layer-helper"
                                onClick={clickHandler(possDirection || moveCourse, connectorTileValue, ".tile-wrapper")}
                            />
                        </>
                    );
                }
            }
        }
    }, [
        isCenter,
        tileId,
        targetTileInfo,
        startAnimation,
        centerRightDirection,
        centerLeftDirection,
        possibleTileConnectorInfo,
        playedDomino,
        leftDominoesLength,
        rightDominoesLength
    ]);

    const { isUpHalfPossible, isDownHalfPossible } = useMemo(() => {
        let isUpHalfPossible, isDownHalfPossible;
        if (boardTile && !possibleMoveDirection) {
            if (!playedDomino) {
                if (!targetTileInfo) {
                    isUpHalfPossible = !!frontUpConnector;
                    isDownHalfPossible = !!frontDownConnector;
                }
            }
        }
        return {
            isUpHalfPossible,
            isDownHalfPossible
        };
    }, [boardTile, possibleMoveDirection, frontUpConnector, frontDownConnector, playedDomino, targetTileInfo]);

    // possible-tile-layer-helper-colored-point, possible-tile-layer-helper-colored-no-point
    const upDirection = isCenter
        ? firstValue === secondValue
            ? TILE_MOVE_COURSE_ID.UP
            : TILE_MOVE_COURSE_ID.LEFT
        : moveCourse;
    const downDirection = isCenter
        ? firstValue === secondValue
            ? TILE_MOVE_COURSE_ID.DOWN
            : TILE_MOVE_COURSE_ID.RIGHT
        : moveCourse;

    const isPointValuable = useMemo(() => {
        if (playedDomino?.isPointValuable) {
            return true;
        }

        let point = frontUpConnector.point;
        if (isDownHalfPossible) {
            point = frontDownConnector.point;
        }

        return isMovePoint(point);
    }, [isUpHalfPossible, frontUpConnector.point, isDownHalfPossible, frontDownConnector.point]);

    return (
        <div
            className={classNames("tile-front-wrapper", {
                pulsating:
                    playedDomino?.point &&
                    isPointValuable &&
                    !startAnimation &&
                    (id === "moveCourse5" ? !leftDominoesLength || !rightDominoesLength : isLastTile),
                "possible-tile-layer-helper-colored-point": possibleMoveDirection && !playedDomino && isPointValuable,
                "possible-tile-layer-helper-colored-no-point":
                    possibleMoveDirection && !playedDomino && !isPointValuable
            })}
        >
            <HalfTile
                value={firstValue}
                direction={upDirection}
                isPossibleMove={Boolean(!possibleMoveDirection && !playedDomino && frontUpConnector && !targetTileInfo)}
                isPointValuable={isPointValuable}
                possiblePoint={frontUpConnector?.point}
                onClick={isUpHalfPossible ? clickHandler(upDirection, firstValue, ".tile-wrapper") : null}
            />
            <HalfTile
                value={secondValue}
                direction={downDirection}
                isPossibleMove={Boolean(
                    !possibleMoveDirection && !playedDomino && frontDownConnector && !targetTileInfo
                )}
                isPointValuable={isPointValuable}
                possiblePoint={frontDownConnector?.point}
                onClick={isDownHalfPossible ? clickHandler(downDirection, secondValue, ".tile-wrapper") : null}
            />
            {possibleMoveDirection}
        </div>
    );
};

Front.propTypes = {
    id: PropTypes.string,
    tileId: PropTypes.number,
    isCenter: PropTypes.bool,
    boardTile: PropTypes.bool,
    isLastTile: PropTypes.bool,
    moveCourse: PropTypes.number,
    directionCourse: PropTypes.number,
    clickHandler: PropTypes.func,
    centerLeftDirection: PropTypes.bool,
    isPlayer: PropTypes.bool.isRequired,
    centerRightDirection: PropTypes.bool,
    possibleTileConnectorInfo: PropTypes.array
};

Front.defaultProps = {
    id: null,
    tileId: null,
    moveCourse: null,
    directionCourse: null,
    isCenter: false,
    boardTile: false,
    isLastTile: false,
    clickHandler: null,
    centerLeftDirection: false,
    centerRightDirection: false,
    possibleTileConnectorInfo: null
};

export default memo(Front);
