import { createSlice } from "@reduxjs/toolkit";
import { join2Objects } from "../../helpers/localHelperFunctions";

const settings = createSlice({
    name: "settings",
    initialState: {},
    reducers: {
        setSettings: (state, { payload }) => {
            join2Objects(state, payload);
        },
        setSettingsProps: (state, { payload }) => {
            join2Objects(state, payload);
            state.backgroundUrl = state.backgroundUrl.replace(/bg_.*jpg/i, `bg_${payload.currentTheme}.jpg`);
        }
    }
});

export const { setSettings, setSettingsProps } = settings.actions;

export default settings.reducer;
