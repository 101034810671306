import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { getPointNotificationData } from "../../store/selectors";
import { useSelector } from "react-redux";
import { isHistory } from "../../constants";

const PointsNotification = ({ point, className, playedDomino, boardPossiblePoint, withoutPlus }) => {
    if (typeof point !== "number") {
        return null;
    }

    const { isPlayerPlayed, isPlayerTurn } = useSelector(getPointNotificationData);
    return (
        <div
            className={classNames("points-notification", {
                rounded: (playedDomino && !boardPossiblePoint) || isHistory,
                "players-point":
                    (isHistory && isPlayerTurn) ||
                    (isPlayerTurn && boardPossiblePoint) ||
                    (isPlayerPlayed && point > 0),
                "players-no-point": (isPlayerPlayed && point === 0) || (isPlayerTurn && boardPossiblePoint),
                "opponents-point":
                    (isHistory && !isPlayerTurn) ||
                    (!isPlayerPlayed && !isHistory) ||
                    (isPlayerTurn && boardPossiblePoint),
                [className]: className
            })}
        >
            <span>
                {(playedDomino || className?.includes("game-end-notification")) && !withoutPlus && "+"}
                {point}
            </span>
        </div>
    );
};

PointsNotification.propTypes = {
    className: PropTypes.string,
    point: PropTypes.number,
    playedDomino: PropTypes.object,
    withoutPlus: PropTypes.bool,
    boardPossiblePoint: PropTypes.bool
};

PointsNotification.defaultProps = {
    point: null,
    className: null,
    playedDomino: null,
    withoutPlus: null,
    boardPossiblePoint: false
};

export default memo(PointsNotification);
