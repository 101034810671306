import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import useDidUpdate from "../../customHooks/useDidUpdate";
import Previous from "../Icons/Previous";
import Play from "../Icons/Play";
import Next from "../Icons/Next";
import Pause from "../Icons/Pause";
import { isMobile } from "../../constants";
import { getHistoryControllerData, useShallowSelector } from "../../store/selectors";
import { setHistoryCurrentStep } from "../../store/slices/gameData";
import classNames from "classnames";

const HistoryControllers = () => {
    const dispatch = useDispatch();
    const [autoPlay, play] = useState(false);
    const [toggleSlider, setToggleSlider] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const sliderToggleTimeout = useRef(null);

    const { currentStep, isLastStep, isFirstStep, stepsLength } = useShallowSelector(getHistoryControllerData);
    const isNewRound = currentStep === 0;

    /**
     *listener for stop auto play when round changed
     */
    useEffect(() => {
        autoPlay && isNewRound && play(false);
    }, [isNewRound]);

    useDidUpdate(() => {
        setSliderValue(currentStep);
    }, [currentStep]);

    const handleSliderChange = step => {
        console.log("after change  ", step);
        if (step !== currentStep) {
            dispatch(setHistoryCurrentStep(step));
        }
    };

    const doMove = step => {
        dispatch(setHistoryCurrentStep(step));
    };

    useEffect(() => {
        let timer;
        if (autoPlay && !isLastStep) {
            timer = setInterval(() => {
                doMove(currentStep + 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [autoPlay, isLastStep, currentStep]);

    const handleToggleSlider = () => {
        clearTimeout(sliderToggleTimeout.current);
        setToggleSlider(true);
        sliderToggleTimeout.current = setTimeout(() => {
            setToggleSlider(false);
        }, 2000);
    };

    useEffect(() => {
        !isMobile && document.addEventListener("mousemove", handleToggleSlider);
        document.addEventListener("click", handleToggleSlider);
    }, []);

    return (
        <div
            className={classNames("history-controllers", {
                active: toggleSlider
            })}
        >
            <div className="buttons-wrapper">
                <button
                    className="btn-icon"
                    disabled={isFirstStep}
                    onClick={() => {
                        play(false);
                        doMove(currentStep - 1);
                    }}
                >
                    <Previous />
                </button>
                <button disabled={isLastStep} className="btn-icon" onClick={() => play(prev => !prev)}>
                    {/*playHistory*/}
                    {!isLastStep && autoPlay ? <Pause /> : <Play />}
                </button>
                <button
                    disabled={isLastStep}
                    className="btn-icon"
                    onClick={() => {
                        play(false);
                        doMove(currentStep + 1);
                    }}
                >
                    <Next />
                </button>
            </div>
            {!isMobile && (
                <div className="slider-wrapper">
                    <Slider
                        value={sliderValue}
                        min={0}
                        max={stepsLength > 1 ? stepsLength - 1 : stepsLength}
                        onChange={step => {
                            autoPlay && play(prev => !prev);
                            setSliderValue(step);
                        }}
                        on
                        onAfterChange={handleSliderChange}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = ({ gameData }) => {
    const { rounds, currentStep, currentRound } = gameData;

    const steps = !isNaN(currentRound) && !isNaN(currentStep) && rounds[currentRound].steps;
    const stepsLen = steps?.length;

    const isLastStep = !stepsLen || currentStep === stepsLen - 1;
    return {
        steps,
        rounds,
        currentStep,
        isLastStep,
        stepsLength: steps?.length
    };
};

HistoryControllers.defaultProps = {
    currentStep: null,
    steps: [],
    rounds: []
};

HistoryControllers.propTypes = {
    // startStoneAnimation: PropTypes.bool.isRequired,
    steps: PropTypes.array,
    rounds: PropTypes.array,
    currentStep: PropTypes.number,
    stepsLength: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(HistoryControllers);
