import React, { memo, useEffect, useRef } from "react";
import OpponentTiles from "./OpponentTiles";
import PlayerTiles from "./PlayerTiles";
import BoardTilesWrapper from "./BoardTilesWrapper";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { ALL_TILES_COUNT, isHistory } from "../../constants";
import { setGameBoardDimensions, setIsGameStarted } from "../../store/slices/gameData";
import { getBoardData, useShallowSelector } from "../../store/selectors";
import { getSoundStatus, playSound } from "../../services/soundService";

const Board = () => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const {
        isGuest,
        isGameStarted,
        isAnimationEnabled,
        mobileMoveBoardUp,
        mobilePlayerMoreTiles,
        roundFinished,
        centralDominoId
    } = useShallowSelector(getBoardData);

    const removeDealingAnimClass = () => {
        let animatedTileCount = 1;
        return () => {
            if (animatedTileCount === ALL_TILES_COUNT) {
                animatedTileCount = 1;
                dispatch(setIsGameStarted());
            } else {
                ++animatedTileCount;
            }
        };
    };

    const windowResizeHandler = () => {
        let doIt;
        return () => {
            clearTimeout(doIt);
            doIt = setTimeout(() => {
                const { left, top, right, bottom } = ref.current?.getBoundingClientRect() || {};
                if (left || top || right || bottom) {
                    dispatch(setGameBoardDimensions({ left, top, right, bottom }));
                }
            }, 100);
        };
    };

    useEffect(() => {
        if (!isHistory && !isGuest) {
            windowResizeHandler()();
            window.addEventListener("resize", windowResizeHandler());
        }
    }, []);

    useEffect(() => {
        if (!isHistory && !isGameStarted && isAnimationEnabled && !roundFinished && centralDominoId === -1) {
            const sound = getSoundStatus();
            sound && playSound("dealing");
        }
    }, [isGameStarted, isAnimationEnabled, roundFinished, centralDominoId]);

    return (
        <div
            id="game-board"
            ref={ref}
            onAnimationEnd={!isGameStarted && !isHistory && isAnimationEnabled ? removeDealingAnimClass() : null}
            // mobile-move-board-up mobile-player-more-tiles
            className={classNames("game-board", {
                "tiles-dealing-animation": !isGameStarted && !isHistory && isAnimationEnabled && centralDominoId === -1,
                "mobile-move-board-up": mobileMoveBoardUp,
                "mobile-player-more-tiles": mobilePlayerMoreTiles
            })}
        >
            <OpponentTiles />
            <BoardTilesWrapper />
            <PlayerTiles />
        </div>
    );
};

export default memo(Board);
