import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { getTranslations } from "../../helpers/translation";

const InfoText = ({ playerName, finishType }) => {
    const translate = useMemo(() => getTranslations(), []);

    return (
        <>
            <div className="info-box">
                <div className="player-id">{playerName}</div>
            </div>
            {finishType && <div className="info-text">{translate(`${finishType}`)}</div>}
        </>
    );
};

InfoText.defaultProps = {
    playerName: null,
    finishType: null
};

InfoText.propTypes = {
    playerName: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    finishType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default memo(InfoText);
