import React, { memo } from "react";
import TimeBlock from "../TimeBlock";
import { gameId } from "../../constants";

const IdTimeBlock = () => {
    return (
        <div className="id-time-block">
            <p>
                <span>Game ID</span>
                <span>#{gameId}</span>
            </p>
            <TimeBlock />
        </div>
    );
};

export default memo(IdTimeBlock);
