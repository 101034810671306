import React, {useEffect, useState} from "react";
import {DOMAIN, TRANSLATIONS_DOMAIN} from "../constants/urls";
import {startSignalR} from "../connection";
import useLockBodyZoom from "../customHooks/useLockBodyZoom";
import App from "./App";
import GSLoader from "../components/Loader/GSLoader";
import {isMovePoint, renameObjectKeys, setAppReady} from "../helpers/localHelperFunctions";
import {batch, useDispatch} from "react-redux";
import {setGameStaticData} from "../store/slices/gameStaticData";
import {
    setGameData,
    setStepData,
    setEndGameData,
    setRoundResult,
    setDoubleStakeData,
    setHistoryData,
    setIsUserConnected,
    setWeakConnection,
    setUserConnectionLost
} from "../store/slices/gameData";
import {getValidationCookie, httpService} from "../services/apiService";
import {playSound, setupSound} from "../services/soundService";
import {setTranslations} from "../store/slices/translations";
import {setSettings} from "../store/slices/settings";
import {closePopup, openPopup} from "../store/slices/popup";
import {getAppWrapperData, useShallowSelector} from "../store/selectors";
import {
    gameId,
    token,
    SOCKET_INVOKE_ACTIONS,
    isHistory,
    GAME_DATA,
    INITIAL_STATE,
    lang,
    HTTP_METHODS,
    playerId,
    guestId,
    POPUP_NAMES,
    DOUBLE_STAKE_REDOUBLE_ID,
    DOUBLE_STAKE_OFFER_ID,
    DOMINO_SOUND_KEY,
    isDemo,
    IS_IOS_CHROME,
    SOCKET_ACTIONS,
    VALIDATION_COOKIE_NAME,
    keysMap
} from "../constants";

const AppData = () => {
    useLockBodyZoom();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const {tilesPrinted, isGameVisible} = useShallowSelector(getAppWrapperData);

    const configSounds = () => {
        //TODO: change sprite src and sprite times when mp3 will be given by PO Davit
        const config = {
            src: ["/assets/sounds/domino_sound_slow.mp3"],
            sprite: {
                dealing: [0, 2015],
                step: [3015, 100],
                bazarOpen: [4125, 1500]
            },
            pool: 0
        };
        setupSound(config, DOMINO_SOUND_KEY);
    };

    const startProject = () => {
        let history, signalR, openSocketConnection;
        if (isHistory) {
            history = new Promise(resolve => {
                httpService({
                    url: `${DOMAIN}/api/History/GetHistory?gameId=${gameId}&playerId=${playerId}&token=${token}`,
                    method: HTTP_METHODS.GET,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    successHandler: data => {
                        return resolve(data);
                    }
                });
            });
        } else {
            const socketConnectionWrapper = resolve => () => {
                const setAllGameData = setAppReady(resolve);
                let signalrInitialized = false;
                let getGameDataIntervalId = null;
                let timeoutId = null;

                const checkInetSpeed = connection => {
                    connection.invoke(SOCKET_INVOKE_ACTIONS.GET_INET_SPEED).catch(err => console.log(err, "err"));
                    timeoutId = setTimeout(() => dispatch(setWeakConnection(true)), 3000);
                };

                startSignalR(
                    `${DOMAIN}/Game?gameId=${gameId}&token=${token}`,
                    (connection, reconnected) => {
                        clearTimeout(timeoutId);
                        !reconnected &&
                        connection
                            .invoke(SOCKET_INVOKE_ACTIONS.GET_INITIAL_STATE)
                            .catch(err => console.log(err, "err"));
                        connection.invoke(SOCKET_INVOKE_ACTIONS.GET_GAME_DATA).catch(err => console.log(err, "err"));
                        clearInterval(getGameDataIntervalId);
                        getGameDataIntervalId = setInterval(() => {
                            connection
                                .invoke(SOCKET_INVOKE_ACTIONS.GET_GAME_DATA)
                                .catch(err => console.log(err, "err"));
                        }, 2000);
                    },

                    connection => {
                        connection.on(SOCKET_ACTIONS.INITIAL_STATE, data => {
                            const renamedData = renameObjectKeys(data, keysMap);
                            connection.off(SOCKET_ACTIONS.INITIAL_STATE);
                            if (!renamedData.isGuest) {
                                checkInetSpeed(connection);
                            }
                            setAllGameData(INITIAL_STATE, renamedData);
                        });

                        connection.on(SOCKET_ACTIONS.GAME_DATA, data => {
                            const renamedData = renameObjectKeys(data, keysMap);
                            clearInterval(getGameDataIntervalId);
                            if (signalrInitialized) {
                                batch(() => {
                                    dispatch(closePopup());
                                    dispatch(setWeakConnection(false));
                                    dispatch(setUserConnectionLost(false));
                                    dispatch(setGameData(renamedData));
                                });
                            } else {
                                signalrInitialized = true;
                            }

                            if (renamedData.popups?.doubleStake) {
                                dispatch(
                                    openPopup({
                                        name: POPUP_NAMES.DOUBLE_STAKE_ACCEPT,
                                        data: {...renamedData.popups.doubleStake}
                                    })
                                );
                            }
                            setAllGameData(GAME_DATA, renamedData);
                        });

                        connection.on(SOCKET_ACTIONS.STEP, data => {
                            const renamedData = renameObjectKeys(data, keysMap);
                            const {roundResult, newRound, dominoMove} = renamedData;

                            if (roundResult) {
                                delete renamedData.roundResult;
                                // delete renamedData.newRound;
                                setTimeout(() => {
                                    if (newRound) {
                                        setTimeout(() => {
                                            // newRound.isGameStarted = false;
                                            delete newRound.gameScore;
                                            newRound.roundFinished = false;
                                            newRound.resetTileScale = true;

                                            dispatch(setGameData(newRound));
                                        }, 3000);
                                    }
                                    dispatch(setRoundResult(roundResult));
                                }, 2000);
                            }

                            if (dominoMove) {
                                dominoMove.isPointValuable = isMovePoint(dominoMove.point);
                            }
                            dispatch(setStepData(renamedData));
                            playSound("step");
                        });

                        connection.on(SOCKET_ACTIONS.INET_SPEED, () => {
                            clearTimeout(timeoutId);
                            timeoutId = setTimeout(() => {
                                checkInetSpeed(connection);
                            }, 10000);
                        });

                        connection.on(SOCKET_ACTIONS.GAME_RESULT, data => {
                            clearTimeout(timeoutId);
                            batch(() => {
                                dispatch(closePopup());
                                dispatch(setEndGameData(renameObjectKeys(data, keysMap)));
                            });
                        });

                        connection.on(SOCKET_ACTIONS.CONNECTION_CHANGED, data => {
                            dispatch(setIsUserConnected(renameObjectKeys(data, keysMap)));
                        });

                        connection.on(SOCKET_ACTIONS.DOUBLE_STAKE, data => {
                            const renamedData = renameObjectKeys(data, keysMap);
                            const {
                                isPlayerTurn,
                                doubleStake: {status}
                            } = renamedData;
                            dispatch(setDoubleStakeData(renamedData));
                            if (
                                isPlayerTurn &&
                                (status === DOUBLE_STAKE_REDOUBLE_ID || status === DOUBLE_STAKE_OFFER_ID)
                            ) {
                                dispatch(openPopup({name: POPUP_NAMES.DOUBLE_STAKE_ACCEPT}));
                            }
                        });
                    },
                    () => {
                        batch(() => {
                            clearTimeout(timeoutId);
                            dispatch(closePopup());
                        });
                    },
                    !isHistory && gameId && token
                );
            };

            signalR = new Promise(resolve => {
                if (gameId && token) {
                    openSocketConnection = socketConnectionWrapper(resolve);
                    setTimeout(() => {
                        openSocketConnection();
                    }, 1000);
                }
            });
        }

        const translations = new Promise(resolve => {
            httpService({
                url: `${TRANSLATIONS_DOMAIN}/api/dominoes/GetTranslations?language=${lang || "en"}`,
                method: HTTP_METHODS.GET,
                headers: {"Content-Type": "application/json;charset=utf-8"},
                successHandler: data => resolve(data),
                errorHandler: () => resolve({})
            });
        });

        const settings = new Promise(resolve => {
            httpService({
                url: `${DOMAIN}/api/settings/GetSettings?token=${token}&playerId=${playerId || guestId}&gameId=${gameId}`,
                method: HTTP_METHODS.GET,
                successHandler: data => {
                    if (IS_IOS_CHROME) {
                        data.isAnimationEnabled = false;
                    }
                    resolve(data);
                },
                errorHandler: () => resolve(null)
            });
        });

        const promises = [translations, settings, isHistory ? history : signalR];

        Promise.all(promises).then(values => {
            batch(() => {
                dispatch(setTranslations(values[0]));
                dispatch(setSettings(values[1]));
            });
            if (isHistory) {
                const historyData = values[2];
                const {rounds} = historyData;
                delete historyData.rounds;
                batch(() => {
                    dispatch(setGameStaticData(historyData));
                    dispatch(setHistoryData(rounds));
                });
            } else {
                const {initialState, gameData} = values[2];
                batch(() => {
                    dispatch(setGameStaticData(initialState));
                    dispatch(setGameData(renameObjectKeys(gameData, keysMap)));
                });
            }

            setIsLoading(false);
        });
        configSounds();
    };

    /**
     *get game Resources and history
     */
    useEffect(() => {
        const validateKey = getValidationCookie();

        if (validateKey) {
            startProject();
        } else {
            const checkUrl = `${TRANSLATIONS_DOMAIN}/api/settings/getsettings`;
            fetch(checkUrl)
                .then(res => {
                    const validateValue = res.headers.get("sgsettings");

                    localStorage.setItem(VALIDATION_COOKIE_NAME, validateValue);
                })
                .finally(() => {
                    startProject();
                });
        }
    }, []);

    if ((isLoading || !isGameVisible) && !isDemo) {
        return (
            <div className="loading-popup dark-bg">
                <GSLoader/>
            </div>
        );
    }
    return (
        <>
            {!tilesPrinted && !isDemo && (
                <div className="loading-popup pre-printing-loader">
                    <GSLoader/>
                </div>
            )}
            <App/>
        </>
    );
};

export default AppData;

// withFullScreenMobile({
//     component: AppData
//     // iosImageURL: "https://media.giphy.com/media/M9HhVYxZRC3Dctc1S8/giphy.gif",
//     // androidImageURL: "https://media.giphy.com/media/M9HhVYxZRC3Dctc1S8/giphy.gif",
// });
