import React, { memo } from "react";
import loaderImg from "../../assets/images/GSLoader.gif";
import "./gs-loader.css";

const GSLoader = () => (
    <div className="gs-loader">
        <img src={loaderImg} alt="loader" />
    </div>
);

export default memo(GSLoader);
