import { separateHost } from "../helpers/utility";

export let DOMAIN = process.env.REACT_APP_ENV;
if (process.env.REACT_APP_IS_DYNAMIC_URL === "true") {
    DOMAIN = process.env.REACT_APP_ENV + separateHost();
}

export let TRANSLATIONS_DOMAIN = process.env.REACT_APP_TRANSLATIONS_DOMAIN;
if (process.env.REACT_APP_IS_DYNAMIC_TRANSLATIONS_DOMAIN === "true") {
    TRANSLATIONS_DOMAIN = process.env.REACT_APP_TRANSLATIONS_DOMAIN + separateHost();
}
