import React, { memo, useCallback } from "react";
import { TILE_ANIMATION_DIRECTIONS, TILE_MOVE_COURSE_ID, TILES_MAP } from "../../constants";
import PropTypes from "prop-types";
import { getGenerateTileAnimationStylesData, useShallowSelector } from "../../store/selectors";

export const GenerateTileAnimationStyles = Component => {
    const WrapperComponent = props => {
        const {
            targetTileInfo,
            startAnimation,
            moveCourseDirection,
            directionFirstStep,
            selectedTile,
            isPlayerTurn
        } = useShallowSelector(getGenerateTileAnimationStylesData);

        const getRotateDeg = (moveCourse, firstTile, secondTile) => {
            const { connectorTile } = targetTileInfo;
            const direction = moveCourseDirection || moveCourse;

            switch (direction) {
                case TILE_MOVE_COURSE_ID.UP: {
                    if (firstTile === secondTile) {
                        return -90;
                    }
                    if (firstTile === connectorTile) {
                        return 180;
                    }
                    return 0;
                }
                case TILE_MOVE_COURSE_ID.RIGHT: {
                    if (firstTile === secondTile) {
                        return 0;
                    }
                    if (firstTile === connectorTile) {
                        return -90;
                    } else if (secondTile === connectorTile) {
                        return 90;
                    }

                    return 0;
                }
                case TILE_MOVE_COURSE_ID.DOWN:
                    if (firstTile === secondTile) {
                        return -90;
                    }
                    if (secondTile === connectorTile) {
                        return 180;
                    }
                    return 0;
                case TILE_MOVE_COURSE_ID.LEFT: {
                    if (firstTile === secondTile) {
                        return 0;
                    }
                    if (firstTile === connectorTile) {
                        return 90;
                    } else if (secondTile === connectorTile) {
                        return -90;
                    }
                    return 0;
                }
                default:
                    if (firstTile !== secondTile) {
                        return -90;
                    }
                    return 0;
            }
        };

        const getYCoordinate = (moveCourse, firstTile, secondTile) => {
            const { y, height, width, connectorTile } = targetTileInfo;
            const yCoordinateDiff = y - selectedTile[moveCourse].y;
            const isTargetTileRotated = width > height;
            const direction = moveCourseDirection || moveCourse;

            switch (direction) {
                case TILE_MOVE_COURSE_ID.UP: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (firstTile === secondTile) {
                                return yCoordinateDiff - height;
                            }
                            if (secondTile === connectorTile) {
                                return yCoordinateDiff - height;
                            }
                            return yCoordinateDiff - height * 3;
                        }

                        if (firstTile === secondTile) {
                            return yCoordinateDiff + height * 0.25;
                        }
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff - height;
                        }

                        return yCoordinateDiff - height * 2;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff - height;
                        }
                        return yCoordinateDiff - height * 3;
                    }

                    if (firstTile === secondTile) {
                        return yCoordinateDiff - height;
                    }
                    if (secondTile === connectorTile) {
                        return yCoordinateDiff - height;
                    }

                    return yCoordinateDiff - height * 2;
                }
                case TILE_MOVE_COURSE_ID.RIGHT: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (secondTile === connectorTile) {
                                return yCoordinateDiff + height;
                            }
                            return yCoordinateDiff;
                        }

                        if (firstTile === secondTile) {
                            return yCoordinateDiff + height * 0.25;
                        }
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff + height;
                        }

                        return yCoordinateDiff + height / 2;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (firstTile === secondTile) {
                            return yCoordinateDiff - (height / 4) * 2;
                        }
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff + height;
                        }
                        return yCoordinateDiff;
                    }

                    if (firstTile === secondTile) {
                        return yCoordinateDiff + height / 4;
                    }
                    if (secondTile === connectorTile) {
                        return yCoordinateDiff + (height / 4) * 3;
                    }

                    return yCoordinateDiff + height / 4;
                }
                case TILE_MOVE_COURSE_ID.DOWN: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (firstTile === secondTile) {
                                return yCoordinateDiff + width / 2;
                            }
                            if (secondTile === connectorTile) {
                                return yCoordinateDiff;
                            }
                            return yCoordinateDiff + width;
                        }

                        if (secondTile === connectorTile) {
                            return yCoordinateDiff;
                        }

                        return yCoordinateDiff + height;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff;
                        }
                        return yCoordinateDiff + height * 2;
                    }

                    if (firstTile === secondTile) {
                        return yCoordinateDiff + width * 1.01;
                    }
                    if (secondTile === connectorTile) {
                        return yCoordinateDiff;
                    }

                    return yCoordinateDiff + height - 1;
                }
                case TILE_MOVE_COURSE_ID.LEFT: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (secondTile === connectorTile) {
                                return yCoordinateDiff + width / 2;
                            }
                            return yCoordinateDiff;
                        }

                        if (firstTile === secondTile) {
                            return yCoordinateDiff - width / 2;
                        }
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff + width;
                        }
                        return yCoordinateDiff;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (firstTile === secondTile) {
                            return yCoordinateDiff - height / 2;
                        }
                        if (secondTile === connectorTile) {
                            return yCoordinateDiff + height;
                        }
                        return yCoordinateDiff;
                    }

                    if (secondTile === connectorTile) {
                        return yCoordinateDiff + (height / 4) * 3;
                    }

                    return yCoordinateDiff + height / 4;
                }
                default: {
                    if (firstTile !== secondTile) {
                        return yCoordinateDiff + height;
                    }
                    return yCoordinateDiff;
                }
            }
        };

        const getXCoordinate = (moveCourse, firstTile, secondTile) => {
            const { x, width, height, connectorTile } = targetTileInfo;
            const isTargetTileRotated = width > height;
            let xCoordinateDiff = x - selectedTile[moveCourse].x;
            const direction = moveCourseDirection || moveCourse;

            switch (direction) {
                case TILE_MOVE_COURSE_ID.UP: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (firstTile === secondTile) {
                                return xCoordinateDiff + width * 1.25;
                            }
                            if (secondTile === connectorTile) {
                                return xCoordinateDiff + width / 2;
                            }
                            return xCoordinateDiff + width;
                        }

                        if (firstTile === secondTile) {
                            return xCoordinateDiff + width;
                        }
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff;
                        }

                        return xCoordinateDiff + width;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff + width * 0.25;
                        }

                        return xCoordinateDiff + width * 0.75;
                    }

                    if (firstTile === secondTile) {
                        return xCoordinateDiff + width * 1.5;
                    }
                    if (secondTile === connectorTile) {
                        return xCoordinateDiff;
                    }

                    return xCoordinateDiff + width;
                }
                case TILE_MOVE_COURSE_ID.RIGHT: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (secondTile === connectorTile) {
                                return xCoordinateDiff + width;
                            }
                            return xCoordinateDiff;
                        }

                        if (firstTile === secondTile) {
                            return xCoordinateDiff + width;
                        }
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff + width * 2;
                        }

                        return xCoordinateDiff;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (firstTile === secondTile) {
                            return xCoordinateDiff + height;
                        }
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff + width;
                        }

                        return xCoordinateDiff;
                    }

                    if (firstTile === secondTile) {
                        return xCoordinateDiff + width;
                    }
                    if (secondTile === connectorTile) {
                        return xCoordinateDiff + height;
                    }

                    return xCoordinateDiff;
                }
                case TILE_MOVE_COURSE_ID.DOWN: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (firstTile === secondTile) {
                                return xCoordinateDiff + width * 0.75;
                            }
                            if (secondTile === connectorTile) {
                                return xCoordinateDiff + height + 1;
                            }
                            return xCoordinateDiff;
                        }

                        if (secondTile === connectorTile) {
                            return xCoordinateDiff + width;
                        }

                        return xCoordinateDiff - (isPlayerTurn ? 1 : 0);
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff + width * 0.75;
                        }

                        return xCoordinateDiff + width * 0.25;
                    }

                    if (firstTile === secondTile) {
                        return xCoordinateDiff + width * 1.51;
                    }
                    if (secondTile === connectorTile) {
                        return xCoordinateDiff + width;
                    }
                    return xCoordinateDiff;
                }
                case TILE_MOVE_COURSE_ID.LEFT: {
                    //--------------------------
                    if (directionFirstStep) {
                        if (isTargetTileRotated) {
                            if (secondTile === connectorTile) {
                                return xCoordinateDiff - width;
                            }
                            return xCoordinateDiff;
                        }

                        if (firstTile === secondTile) {
                            return xCoordinateDiff - width * 0.98;
                        }
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff - width * 2 + (isPlayerTurn ? 0.5 : -1);
                        }
                        return xCoordinateDiff;
                    }
                    //--------------------------

                    if (isTargetTileRotated) {
                        if (firstTile === secondTile) {
                            return xCoordinateDiff - height;
                        }
                        if (secondTile === connectorTile) {
                            return xCoordinateDiff - width;
                        }
                        return xCoordinateDiff;
                    }

                    if (secondTile === connectorTile) {
                        return xCoordinateDiff - height;
                    }

                    return xCoordinateDiff;
                }
                default:
                    return xCoordinateDiff;
            }
        };

        const getTransformData = moveCourse => {
            const [firstTile, secondTile] = TILES_MAP[selectedTile.dominoId];
            const { width, height } = targetTileInfo;

            const x = getXCoordinate(moveCourse, firstTile, secondTile);
            const y = getYCoordinate(moveCourse, firstTile, secondTile);
            const rotateDeg = getRotateDeg(moveCourse, firstTile, secondTile);
            const isTargetTileRotated = width > height;
            const scale = (isTargetTileRotated ? height : width) / selectedTile[moveCourse].width;

            return `translate(${x}px, ${y}px) rotate(${rotateDeg}deg) scale(${scale})`;
        };

        const getTileAnimationStyles = useCallback(() => {
            let styles = null;

            if (targetTileInfo && startAnimation) {
                const { moveCourse } = targetTileInfo;
                styles = {
                    transform: getTransformData(moveCourse),
                    transformOrigin: `${TILE_ANIMATION_DIRECTIONS[moveCourseDirection || moveCourse]}`
                    // ,
                    // margin: 0
                };
            }

            return styles;
        }, [targetTileInfo, startAnimation, moveCourseDirection]);

        return <Component {...props} getTileAnimationStyles={getTileAnimationStyles} />;
    };

    WrapperComponent.propTypes = {
        selectedTile: PropTypes.object
    };

    WrapperComponent.defaultProps = {
        selectedTile: null
    };

    return memo(WrapperComponent);
};
