import { configureStore } from "@reduxjs/toolkit";
import gameData from "./slices/gameData";
import gameStaticData from "./slices/gameStaticData";
import translations from "./slices/translations";
import settings from "./slices/settings";
import popup from "./slices/popup";
import { animationEndUpdates } from "../middlewares/animationEndUpdates";
import { guestModeMiddleware } from "../middlewares/guestModeMiddleware";

export default configureStore({
    reducer: {
        popup,
        gameData,
        settings,
        gameStaticData,
        translations
    },
    devTools: process.env.NODE_ENV === "development",
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(animationEndUpdates, guestModeMiddleware)
});
