import React, { memo } from "react";
import { httpService } from "../../services/apiService";
import { DOMAIN } from "../../constants/urls";
import { HTTP_METHODS, isMobile, lang } from "../../constants";

const DemoStartButton = () => {
    const handleStartBtn = () => {
        httpService({
            url: `${DOMAIN}/api/Demo/Game?with2Player=false&isMobile=${isMobile}&language=${lang || "en"}`,
            method: HTTP_METHODS.GET,
            successHandler: ({ url }) => {
                window.location.href = url;
            }
        });
    };

    return (
        <button className="start-button" onClick={handleStartBtn}>
            START
        </button>
    );
};

export default memo(DemoStartButton);
