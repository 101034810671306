import React, { useState, useRef, useMemo, memo } from "react";
import { getCurrencySign } from "../../helpers/utility";
import { getTranslations } from "../../helpers/translation";
import { currencyId, win, isMobile } from "../../constants";
import Info from "../Icons/Info";
import GameInfo from "./GameInfo";
import { getGameInfoButtonData, useShallowSelector } from "../../store/selectors";

const GameInfoButton = () => {
    const [isOpen, setIsOpen] = useState(null);
    const infoButtonRef = useRef();
    const translate = useMemo(() => getTranslations(), []);
    const { tournamentDoubleStake } = useShallowSelector(getGameInfoButtonData);

    return (
        <div className="button-wrapper">
            <button
                className={`game-settings-btn btn-info ${isOpen ? "active" : ""}`}
                onClick={() => setIsOpen(!isOpen)}
                ref={infoButtonRef}
            >
                <Info />
                {tournamentDoubleStake && !isMobile && (
                    <span className="text-item mobile-hide">
                        <span>{translate("Prize")}:</span>
                        <span className="text-colored">
                            {win && `${win * tournamentDoubleStake} ${getCurrencySign(currencyId) || ""}`}
                        </span>
                    </span>
                )}
            </button>
            <GameInfo
                isOpen={isOpen}
                infoButtonRef={infoButtonRef}
                onClose={() => setIsOpen(prev => prev && false)}
                translate={translate}
            />
        </div>
    );
};

export default memo(GameInfoButton);
