import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getDoubleStakeInfo, useShallowSelector } from "../../../store/selectors";
import {
    bet,
    currencyId,
    DOUBLE_STAKE_ACCEPT_ID,
    DOUBLE_STAKE_REDOUBLE_ID,
    DOUBLE_STAKE_SURRENDER_ID,
    SOCKET_INVOKE_ACTIONS
} from "../../../constants";
import { invoke } from "../../../connection";
import { getTranslations } from "../../../helpers/translation";

const DoubleStakeAccept = ({ close }) => {
    const { nextStake, canRedouble } = useShallowSelector(getDoubleStakeInfo);
    const translate = useMemo(() => getTranslations(), []);

    const doubleStakeActionHandler = typeId => () => {
        invoke(SOCKET_INVOKE_ACTIONS.DOUBLE_STAKE_RESPONSE, [typeId]);
        close();
    };

    return (
        <div>
            <div className="popup-header">
                <span className="popup-title">{translate("DoubleStakeRequest")}</span>
                {/*<button className="btn-close">*/}
                {/*    <Close />*/}
                {/*</button>*/}
            </div>
            <div className="popup-content">
                <div className="popup-text">
                    <p>{translate("DoubleStakeOffer")}</p>
                    <p>
                        {translate("YourFinalStake")} {bet * nextStake} {currencyId}
                    </p>
                </div>
            </div>
            <div className="popup-footer ">
                <div className="btns-wrapper">
                    <button className="btn-main-grey" onClick={doubleStakeActionHandler(DOUBLE_STAKE_SURRENDER_ID)}>
                        {translate("Surrender")}
                    </button>
                </div>
                <div className="btns-wrapper">
                    {canRedouble && (
                        <button className="btn-main-grey" onClick={doubleStakeActionHandler(DOUBLE_STAKE_REDOUBLE_ID)}>
                            {translate("Redouble")}
                        </button>
                    )}
                    <button className="btn-main-colored" onClick={doubleStakeActionHandler(DOUBLE_STAKE_ACCEPT_ID)}>
                        {translate("Accept")}
                    </button>
                </div>
            </div>
        </div>
    );
};

DoubleStakeAccept.propTypes = {
    close: PropTypes.func.isRequired
};

export default DoubleStakeAccept;
