import { isHistory, TILE_MOVE_COURSE_ID, TILE_MOVE_COURSE_NAME } from "../../constants";
import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

export function useShallowSelector(selector) {
    return useSelector(selector, shallowEqual);
}

export const getGameDisableData = state => state.gameData.disableScreen;

export const toggleAutoDeal = createSelector(
    ({ gameData, settings }) => ({
        isPlayerTurn: gameData.isPlayerTurn,
        pValidMoves: gameData.player.validMoves,
        isAutoDeal: settings.isAutoDeal
    }),
    ({ isPlayerTurn, pValidMoves, isAutoDeal }) => isAutoDeal && isPlayerTurn && !pValidMoves?.length
);

export const getBazarData = ({ gameData, settings }) => {
    return {
        bazar: gameData.bazar,
        roundFinished: gameData.roundFinished,
        isAutoDeal: settings.isAutoDeal,
        bazarDominoesCount: gameData.bazarDominoesCount,
        isPlayerTurn: gameData.isPlayerTurn
    };
};

export const getAutoDealData = createSelector(
    [
        toggleAutoDeal,
        ({ gameData }) => gameData.isPlayerTurn,
        ({ gameData }) => gameData.startAnimation,
        ({ gameData }) => gameData.bazarDominoMove,
        ({ gameData }) => gameData.bazar?.isPlayer,
        ({ settings }) => settings.isAnimationEnabled,
        ({ gameData }) => gameData.player.dominoes?.length
    ],
    (
        invokeAutoDeal,
        isPlayerTurn,
        startAnimation,
        bazarDominoMove,
        isPlayer,
        isAnimationEnabled,
        playerDominoesLength
    ) => {
        return {
            isPlayer,
            forceUpdate: !isAnimationEnabled && isPlayerTurn && playerDominoesLength,
            startAnimation,
            bazarDominoMove,
            invokeAutoDeal
        };
    }
);

export const getOpenedBazarData = ({ gameData, settings, gameStaticData }) => {
    const { bazar, bazarDominoMove, roundFinished, mobileMoveBoardUp } = gameData;
    const { dominoes, isPlayer } = bazar || {};
    const { isAnimationEnabled } = settings;
    const { isGuest } = gameStaticData;

    return { dominoes, isPlayer, bazarDominoMove, isAnimationEnabled, roundFinished, isGuest, mobileMoveBoardUp };
};

export const getGenerateTileAnimationStylesData = createSelector(
    [
        ({ gameData }) => gameData.targetTileInfo,
        ({ gameData }) => gameData.startAnimation,
        ({ gameData }) => gameData.board,
        ({ gameData }) => gameData.selectedTile,
        ({ gameData }) => gameData.isPlayerTurn
    ],
    (targetTileInfo, startAnimation, board, selectedTile, isPlayerTurn) => {
        let direction;
        let directionFirstStep;
        if (targetTileInfo && targetTileInfo.moveCourse !== TILE_MOVE_COURSE_ID.CENTER) {
            const currentSet = board[`${TILE_MOVE_COURSE_NAME[targetTileInfo.moveCourse]}DominoesSet`];
            direction = currentSet.direction;
            directionFirstStep = currentSet.directionFirstStep;
        }

        return {
            isPlayerTurn,
            selectedTile,
            targetTileInfo,
            startAnimation,
            directionFirstStep,
            moveCourseDirection: direction
        };
    }
);

export const getAppData = ({ settings }) => {
    const { themes, currentTheme, backgroundUrl } = settings;
    return {
        themes,
        currentTheme,
        backgroundUrl
    };
};

export const getSettingsData = ({ gameStaticData, settings, gameData }) => {
    const { isGuest } = gameStaticData;
    const { isGameStarted } = gameData;
    const settingsData = Boolean(Object.keys(settings).length);

    return {
        isGuest,
        settingsData,
        isGameStarted
    };
};

export const getGameInfoButtonData = ({ gameData, gameStaticData }) => {
    const { doubleStakeData } = gameData;
    const tournamentDoubleStake = gameStaticData.tournamentInfo && (doubleStakeData?.currentStake || 1);

    // return {
    //     tournamentInfo: isHistory ? data.tournamentInfo : gameStaticData.tournamentInfo,
    //     doubleStakeInfo,
    //     gameConfiguration: isHistory ? data?.gameConfiguration : gameStaticData?.gameConfiguration
    // };
    return {
        tournamentDoubleStake,
        doubleStakeData
    };
};

export const getGameInfoData = ({ gameData, gameStaticData }) => {
    const { gameConfiguration, tournamentInfo } = gameStaticData;
    return {
        currentStake: gameData.doubleStakeData?.currentStake,
        ...tournamentInfo,
        ...gameConfiguration
    };
};

export const getGameCompData = ({ gameData, gameStaticData }) => {
    const { weakConnection, connectionLost, disableScreen } = gameData;
    const { isGuest, gameConfiguration } = gameStaticData;
    return {
        isGuest,
        disableScreen,
        weakConnection,
        connectionLost,
        isDemoGame: gameConfiguration?.isDemo
    };
};

export const getBoardData = ({ gameData, gameStaticData, settings }) => {
    const { isGameStarted, isGameVisible, mobileMoveBoardUp, mobilePlayerMoreTiles, roundFinished, board } = gameData;
    const { isGuest } = gameStaticData;
    const { isAnimationEnabled } = settings;
    const { centralDominoId } = board;

    return {
        isGuest,
        roundFinished,
        isGameStarted,
        isGameVisible,
        centralDominoId,
        mobileMoveBoardUp,
        isAnimationEnabled,
        mobilePlayerMoreTiles
    };
};

export const getPlayerTilesData = ({ gameData, settings, gameStaticData }) => {
    const {
        player,
        isPlayerTurn,
        selectedTile,
        bazar,
        board,
        isPlayerWin,
        roundFinished,
        isPlayerPlayed,
        playedDomino,
        isGameVisible
    } = gameData;
    const { dominoes, validMoves, dominoesPoint, additionalPoint, point } = player;
    const { isAutoDeal, isDragAndDropEnabled } = settings;
    const { isGuest } = gameStaticData;

    return {
        bazar,
        isGuest,
        dominoes,
        isAutoDeal,
        validMoves,
        isPlayerWin,
        isPlayerTurn,
        selectedTile,
        playedDomino,
        isGameVisible,
        roundFinished,
        dominoesPoint,
        isPlayerPlayed,
        additionalPoint,
        isDragAndDropEnabled,
        historyStepPoint: point,
        centralDominoId: board.centralDominoId
    };
};

export const getTileFrontData = ({ gameData }) => {
    const { playedDomino, targetTileInfo, board, startAnimation } = gameData;
    const { rightDominoesSet, leftDominoesSet } = board;

    return {
        playedDomino,
        targetTileInfo,
        startAnimation,
        rightDominoesLength: rightDominoesSet.dominoes.length,
        leftDominoesLength: leftDominoesSet.dominoes.length
    };
};

export const getPointNotificationData = ({ gameData }) => {
    const { isPlayerPlayed, isPlayerTurn } = gameData;
    return {
        isPlayerTurn,
        isPlayerPlayed
    };
};

export const getSettingsPopupData = ({ settings }) => {
    const { themes, currentTheme, isAutoDeal, isAnimationEnabled, isDragAndDropEnabled } = settings;

    return {
        themes,
        isAutoDeal,
        currentTheme,
        isDragAndDropEnabled,
        isAnimationEnabled
    };
};

export const getSettingsThemeData = ({ settings }) => {
    return settings.themes;
};

export const getBoardTilesWrapperData = ({ gameData, gameStaticData }) => {
    const { tileMaxScaled, resetTileScale, currentRound, tilesPrinted } = gameData;
    return {
        tileMaxScaled,
        resetTileScale,
        currentRound,
        tilesPrinted,
        isGuest: gameStaticData.isGuest
    };
};

export const getBoardTilesData = ({ gameData }) => {
    const { board, selectedTile, isPlayerTurn, targetTileInfo, firstPossibleTileIsRotated } = gameData;
    const { centralDominoId, leftDominoesSet, rightDominoesSet, upDominoesSet, downDominoesSet } = board;

    return {
        targetTileInfo,
        isPlayerTurn,
        selectedTile,
        centralDominoId,
        leftDominoesSet,
        rightDominoesSet,
        upDominoesSet,
        downDominoesSet,
        firstPossibleTileIsRotated
    };
};

export const getActionBtnData = ({ gameData, gameStaticData }) => {
    const { maxStakeDoubling, multiplePair } = gameStaticData.gameConfiguration;
    const { isPlayerTurn, doubleStakeData } = gameData;

    return {
        isPlayerTurn,
        multiplePair,
        doubleStakeData,
        maxStakeDoubling
    };
};

export const getDoubleStakeInfo = ({ gameData }) => {
    const { doubleStake } = gameData;

    return {
        nextStake: doubleStake?.nextStake,
        canRedouble: doubleStake?.canRedouble
    };
};

export const getOpponentTilesData = ({ gameData }) => {
    const {
        bazar,
        playedDomino,
        isPlayerTurn,
        isPlayerWin,
        roundFinished,
        startAnimation,
        isPlayerPlayed,
        opponent: { dominoes, dominoesPoint, additionalPoint, point }
    } = gameData;

    return {
        bazar,
        dominoes,
        isPlayerWin,
        playedDomino,
        isPlayerTurn,
        roundFinished,
        dominoesPoint,
        startAnimation,
        isPlayerPlayed,
        additionalPoint,
        historyStepPoint: point,
        playedDominoId: startAnimation && playedDomino?.dominoId
    };
};

export const getPlayerInfo = ({ gameData, gameStaticData }) => {
    const {
        player: { gameTime, stepTime, notification, isConnected },
        isPlayerTurn,
        stopTimer,
        doubleStakeData
    } = gameData;

    return {
        extraTime: gameStaticData.gameConfiguration.gameTime,
        isPlayerTurn,
        stopTimer,
        stepTime,
        gameTime,
        notification,
        isConnected,
        doubleStakeData,
        nickName: gameStaticData.playerInitialState.playerNickName
    };
};

export const getOpponentInfo = ({ gameData, gameStaticData }) => {
    const {
        opponent: { gameTime, stepTime, notification, isConnected },
        isPlayerTurn,
        stopTimer,
        doubleStakeData
    } = gameData;

    return {
        extraTime: gameStaticData.gameConfiguration.gameTime,
        isPlayerTurn,
        stopTimer,
        gameTime,
        stepTime,
        notification,
        isConnected,
        doubleStakeData,
        nickName: gameStaticData.opponentInitialState.playerNickName
    };
};

export const getHistoryControllerData = ({ gameData }) => {
    const { rounds, currentStep, currentRound } = gameData;

    const steps = !isNaN(currentRound) && !isNaN(currentStep) && rounds[currentRound].steps;
    const stepsLen = steps?.length;

    const isLastStep = !stepsLen || currentStep === stepsLen - 1;
    const isFirstStep = currentStep === 0;
    return {
        currentRound,
        currentStep,
        isLastStep,
        isFirstStep,
        stepsLength: steps?.length
    };
};

export const getScoreBarData = ({ gameData, gameStaticData }) => {
    const { currentRound, gameScore } = gameData;
    const scoreData = isHistory ? gameStaticData.gameScore : gameScore;
    const { gameType } = gameStaticData.gameConfiguration;
    const { playerGameScore, opponentGameScore, roundsScores } = scoreData;

    return {
        gameType,
        currentRound,
        roundsScores,
        playerGameScore,
        opponentGameScore
    };
};

export const getAppWrapperData = ({ gameData }) => {
    const { isGameVisible, tilesPrinted } = gameData;

    return {
        isGameVisible,
        tilesPrinted
    };
};

export const getPopupData = ({ popup, gameData }) => ({ ...popup, disableScreen: gameData.disableScreen });
