import React from "react";

const Next = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
            <path d="M6,18l8.5-6L6,6ZM16,6V18h2V6Z" />
            <path d="M0,0H24V24H0Z" fill="none" />
        </svg>
    );
};

export default Next;
