import { useEffect } from "react";
import { isMobile } from "../constants";

const useOutsideClick = (callback, ...refs) => {
    useEffect(() => {
        document.addEventListener(`${isMobile ? "touchstart" : "mousedown"}`, handleClick);
        return () => {
            document.removeEventListener(`${isMobile ? "touchstart" : "mousedown"}`, handleClick);
        };
    }, []);

    const handleClick = e => {
        e.stopPropagation();
        if (refs.every(ref => ref.current && !ref.current.contains(e.target))) {
            callback();
        }
    };
};

export default useOutsideClick;
