import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { POPUP_NAMES } from "../../constants";
import { openPopup } from "../../store/slices/popup";
import MultiStep from "../Icons/MultiStep";
import Double from "../Icons/Double";
import { getActionBtnData, useShallowSelector } from "../../store/selectors";

const ActionButtons = () => {
    const dispatch = useDispatch();
    const { isPlayerTurn, maxStakeDoubling, doubleStakeData, multiplePair } = useShallowSelector(getActionBtnData);

    const multiplePairClickHandler = () => {
        //TODO: write multiple pair functionality
    };

    return (
        <div className="action-buttons-wrapper">
            {/*add inactive class when button is inactive*/}
            {multiplePair && (
                <button className="action-button green-btn" onClick={multiplePairClickHandler}>
                    <span className="icon-wrapper">
                        <MultiStep />
                    </span>
                </button>
            )}
            {isPlayerTurn &&
                maxStakeDoubling > 1 &&
                (!doubleStakeData || (doubleStakeData.isPlayer && doubleStakeData.currentStake < maxStakeDoubling)) && (
                    <button
                        className="action-button yellow-btn"
                        onClick={() => dispatch(openPopup({ name: POPUP_NAMES.DOUBLE_STAKE }))}
                    >
                        <span className="icon-wrapper">
                            <Double />
                        </span>
                    </button>
                )}
        </div>
    );
};

export default memo(ActionButtons);
