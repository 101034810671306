import { HIDDEN, isHistory } from "../constants";
import { removePlayedDomino, setSelectedTile, setStepData, toggleAnimation } from "../store/slices/gameData";
import { batch } from "react-redux";
import { getElementsPosition } from "../helpers/utility";
import {
    getDirectionByTilesCount,
    getValidDirectionRotateInfo,
    playBazarMoveIfChanceTileIsRendered
} from "../helpers/localHelperFunctions";

export function animationEndUpdates({ getState, dispatch }) {
    let animationInProcess = false;
    let nextAnimationActions = [];
    let currentStep = null;
    let currentActions = [];
    let pointNotificationTimeoutId = null;
    let duringDealingOppAction = null;
    let nextActionTimeoutIds = [];
    return next => action => {
        if (action.type.endsWith("setGameVisibility")) {
            animationInProcess = false;
            nextAnimationActions = [];
            currentStep = null;
            currentActions = [];
            pointNotificationTimeoutId = null;
            duringDealingOppAction = null;
            nextActionTimeoutIds = [];
            return next(action);
        }
        if (duringDealingOppAction && action.type === "gameData/setIsGameStarted") {
            setTimeout(() => {
                dispatch(setStepData(duringDealingOppAction.payload));
                duringDealingOppAction = null;
            });
        }
        const { type, payload } = action;
        const { settings, gameStaticData, gameData } = getState();

        if (!isHistory && !document[HIDDEN]) {
            if (type === "gameData/setBazarAnimation" && payload) {
                animationInProcess = true;
            }

            if (!gameStaticData.isGuest && settings?.isAnimationEnabled) {
                if (type === "gameData/setStepData") {
                    if (!gameData.isGameStarted && gameData.board?.centralDominoId === -1) {
                        duringDealingOppAction = action;
                        // setTimeout(() => {
                        //     dispatch(setStepData(payload));
                        // }, 1000);
                        return;
                    }

                    if (animationInProcess) {
                        nextAnimationActions.push(action);
                        nextActionTimeoutIds.push(
                            setTimeout(() => {
                                batch(() => {
                                    nextAnimationActions.forEach(i => {
                                        next(i);
                                    });
                                });
                                nextAnimationActions = [];
                            }, 2000)
                        );
                    } else {
                        animationInProcess = true;
                        currentStep = action;
                        const { dominoMove, bazarDominoMove, isPlayerPlayed } = payload;
                        const domino = dominoMove || bazarDominoMove;

                        if (!isPlayerPlayed && !bazarDominoMove) {
                            const { direction } = getDirectionByTilesCount(null, domino.moveCourse);
                            const opponentSelectedTile = {
                                [domino.moveCourse]: getElementsPosition(
                                    document.getElementById("oppSelectedTile"),
                                    getValidDirectionRotateInfo(direction)
                                ),
                                dominoId: domino.dominoId
                            };
                            next(setSelectedTile(opponentSelectedTile));
                        }

                        const startAnimOrUpdatedata = () => {
                            if (bazarDominoMove) {
                                playBazarMoveIfChanceTileIsRendered(next, domino);
                            } else {
                                next(toggleAnimation({ toggle: true, dominoMove: domino }));
                            }
                        };

                        if (gameData.board.centralDominoId === -1 && !isPlayerPlayed) {
                            setTimeout(startAnimOrUpdatedata);
                        } else {
                            startAnimOrUpdatedata();
                        }
                    }
                    return;
                } else if (type === "gameData/toggleAnimation" && !payload && animationInProcess) {
                    animationInProcess = false;
                    if (currentStep) {
                        if (!currentStep.payload.bazarDominoMove) {
                            clearTimeout(pointNotificationTimeoutId);
                            pointNotificationTimeoutId = setTimeout(() => {
                                next(removePlayedDomino());
                            }, 1500);
                        }
                        next(currentStep);
                    }
                    currentStep = null;

                    if (nextAnimationActions.length) {
                        const nextAction = nextAnimationActions.shift();
                        animationInProcess = true;
                        currentStep = nextAction;
                        clearTimeout(nextActionTimeoutIds.shift());

                        const { dominoMove, bazarDominoMove, isPlayerPlayed } = nextAction.payload;
                        const domino = dominoMove || bazarDominoMove;

                        if (bazarDominoMove) {
                            playBazarMoveIfChanceTileIsRendered(next, domino);
                        } else {
                            setTimeout(() => {
                                if (!isPlayerPlayed) {
                                    const { direction } = getDirectionByTilesCount(null, domino.moveCourse);
                                    const opponentSelectedTile = {
                                        [domino.moveCourse]: getElementsPosition(
                                            document.getElementById("oppSelectedTile"),
                                            getValidDirectionRotateInfo(direction)
                                        ),
                                        dominoId: domino.dominoId
                                    };
                                    next(setSelectedTile(opponentSelectedTile));
                                }
                                next(toggleAnimation({ toggle: true, dominoMove: domino }));
                            }, 1200);
                        }
                    } else if (currentActions.length) {
                        batch(() => {
                            currentActions.forEach(i => {
                                next(i);
                            });
                        });
                        currentActions = [];
                    }
                    return;
                } else if (animationInProcess) {
                    currentActions.push(action);
                    return;
                }
            } else if (type === "gameData/setStepData") {
                payload.updateTilesDirection = true;
                setTimeout(() => {
                    next(removePlayedDomino());
                }, 1500);
            }
        }

        if (type === "gameData/setGameData") {
            if (!settings?.isAnimationEnabled) {
                payload.isGameStarted = true;
            }
            payload.isGuest = gameStaticData.isGuest;
        }

        return next(action);
    };
}
