import React, { memo, useEffect, useState } from "react";
import moment from "moment";

const TimeBlock = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return <p>{moment(date).format("HH:mm:ss")}</p>;
};

export default memo(TimeBlock);
