import { useCallback, useEffect } from "react";

function useLockBodyZoom(disableZoom = true) {
    const handleTouchMove = useCallback(e => {
        if ((e.scale && e.scale !== 1) || e.touches.length > 1) {
            e.preventDefault();
        }
    }, []);
    useEffect(() => {
        if (disableZoom) {
            document.addEventListener("touchmove", handleTouchMove, {
                passive: false
            });
        }
        return () => {
            document.removeEventListener("touchmove", handleTouchMove);
        };
    }, [disableZoom, handleTouchMove]);
}
export default useLockBodyZoom;
