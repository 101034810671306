import React, { memo, useEffect, useRef, useState } from "react";
import Tile from "../../Tile";
import classNames from "classnames";
import { getOpenedBazarData, useShallowSelector } from "../../../store/selectors";
import { invoke } from "../../../connection";
import { isHistory, SOCKET_INVOKE_ACTIONS } from "../../../constants";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setBazarAnimation, toggleAnimation } from "../../../store/slices/gameData";
import usePrevious from "../../../customHooks/usePrevious";
import { getSoundStatus, playSound } from "../../../services/soundService";

const OpenedBazar = ({ getBazarTileAnimationStyles }) => {
    const dominoesRef = useRef({});
    const tilesEls = useRef({});
    const tileIsDisabled = useRef(null);
    const [enabled, setEnabled] = useState(true);
    const dispatch = useDispatch();

    const {
        dominoes,
        isPlayer,
        bazarDominoMove,
        isAnimationEnabled,
        roundFinished,
        isGuest,
        mobileMoveBoardUp
    } = useShallowSelector(getOpenedBazarData);

    const clickHandler = index => () => {
        if (isPlayer && !roundFinished && !tileIsDisabled.current) {
            tileIsDisabled.current = true;
            invoke(SOCKET_INVOKE_ACTIONS.GET_DOMINO_FROM_BAZAR, [index + 1]);
        }
    };

    const prevIsAnimationEnabled = usePrevious(isAnimationEnabled);

    useEffect(() => {
        const sound = getSoundStatus();
        const isDominoes = dominoesRef.current.isDominoes;

        tileIsDisabled.current = false;
        sound && !isDominoes && dominoes && playSound("bazarOpen");
        dominoesRef.current.isDominoes = !!dominoes;
    }, [dominoesRef, dominoes]);

    useEffect(() => {
        if (mobileMoveBoardUp) {
            setEnabled(false);
            setTimeout(() => {
                setEnabled(true);
            }, 300);
        }
    }, [mobileMoveBoardUp]);

    return (
        <div
            className={classNames("bone-yard-opened", {
                open: dominoes,
                "no-anim": isHistory || isGuest || !isAnimationEnabled || prevIsAnimationEnabled === false
            })}
            onAnimationEnd={({ target }) => {
                if (target.id) {
                    setEnabled(true);
                }
                if (target.classList.contains("bone-yard-opened")) {
                    dispatch(toggleAnimation(false));
                }
            }}
            onAnimationStart={({ target }) => {
                if (target.classList.contains("bone-yard-opened")) {
                    dispatch(setBazarAnimation(true));
                }
            }}
        >
            {dominoes?.map((i, index) => {
                if (i === -1) {
                    return <div key={index} />;
                }
                const isTileSelected = bazarDominoMove?.dominoIndex - 1 === index && !isHistory;
                const toggleAnimationForSelected = isAnimationEnabled && isTileSelected;
                const currentTileRef = tilesEls.current[bazarDominoMove?.dominoIndex - 1];
                const { tileStyles, wrapperStyles } = getBazarTileAnimationStyles(
                    toggleAnimationForSelected,
                    currentTileRef
                );
                const tileRef = el => (tilesEls.current[index] = el);
                const tileId = isPlayer && isTileSelected ? bazarDominoMove?.dominoId : 0;

                return (
                    <div
                        ref={tileRef}
                        className={classNames("bone-yard-tile", {
                            player: isPlayer,
                            opponent: (!isPlayer || isGuest) && !isTileSelected && !roundFinished
                        })}
                        key={index}
                        style={wrapperStyles}
                        id={index === dominoes.lastIndexOf(0) ? `last-bone-yard-tile` : ""}
                    >
                        <Tile
                            threeD={Boolean(isPlayer && tileStyles)}
                            boneYard
                            tileId={roundFinished ? i : tileId}
                            style={tileStyles}
                            showAnimation={toggleAnimationForSelected}
                            clickHandler={isPlayer && !isGuest && !isHistory && enabled ? clickHandler(index) : null}
                        />
                    </div>
                );
            })}
        </div>
    );
};

OpenedBazar.propTypes = {
    getBazarTileAnimationStyles: PropTypes.func.isRequired
};

export default memo(OpenedBazar);
