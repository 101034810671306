import React, { memo, useMemo } from "react";
import ConnectionLostIcon from "../Icons/ConnectionLostIcon";
import { getTranslations } from "../../helpers/translation";

const UserConnectionLost = () => {
    const translate = useMemo(() => getTranslations(), []);

    return (
        <div className="user-connection-lost">
            <div className="icon-wrapper">
                <ConnectionLostIcon />
            </div>
            <div className="text-wrapper">
                <p className="info-text">{translate("ConnectionLost")}</p>
                <p className="info-text-bold">{translate("connectionAutoResume")}</p>
            </div>
        </div>
    );
};

export default memo(UserConnectionLost);
