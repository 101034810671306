import React from "react";

const ArrowToLeft = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24">
            <g transform="translate(0 24) rotate(-90)">
                <path d="M16.6,15.4L12,10.8l-4.6,4.6L6,14l6-6l6,6L16.6,15.4z" />
                <path fill="none" d="M23.9,24l-24-0.1L0,0L24,0L23.9,24z" />
            </g>
        </svg>
    );
};

export default ArrowToLeft;
