import { createSlice } from "@reduxjs/toolkit";
import { join2Objects } from "../../helpers/localHelperFunctions";

export const gameStaticData = createSlice({
    name: "gameStaticData",
    initialState: {},
    reducers: {
        setGameStaticData: (state, { payload }) => {
            join2Objects(state, payload);
        }
    }
});

export const { setGameStaticData } = gameStaticData.actions;

export default gameStaticData.reducer;
