import React, { memo, useMemo } from "react";
import ToggleSwitchItem from "../../../ToggleSwitchItem";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { toggleAutoDeal } from "../../../../store/selectors";
import { getTranslations } from "../../../../helpers/translation";
import { IS_IOS_CHROME } from "../../../../constants";

const Game = ({ switchers, setSwitchers }) => {
    const onChange = name => e => {
        setSwitchers({ ...switchers, [name]: e.target.checked });
    };
    const invokeAutoDeal = useSelector(toggleAutoDeal);
    const translate = useMemo(() => getTranslations(), []);

    return (
        <div className="popup-toggle-switch-block">
            <ToggleSwitchItem
                disabled={IS_IOS_CHROME}
                name={translate("ShowAnimations")}
                onChange={!IS_IOS_CHROME ? onChange("isAnimationEnabled") : null}
                checked={switchers.isAnimationEnabled}
            />
            <div className="auto-deal-container">
                <ToggleSwitchItem
                    disabled={invokeAutoDeal}
                    name={translate("AutoDeal")}
                    onChange={onChange("isAutoDeal")}
                    checked={switchers.isAutoDeal}
                />
                {invokeAutoDeal && <span className="auto-deal-text">{translate("AutoDealIsInProgress")}</span>}
            </div>
            <ToggleSwitchItem
                name={translate("DragAndDrop")}
                onChange={onChange("isDragAndDropEnabled")}
                checked={switchers.isDragAndDropEnabled}
            />
        </div>
    );
};

Game.propTypes = {
    switchers: PropTypes.object.isRequired,
    setSwitchers: PropTypes.func.isRequired
};

export default memo(Game);
