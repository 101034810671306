export function guestModeMiddleware({ getState }) {
    return next => action => {
        const { isGuest } = getState().gameStaticData;

        if (isGuest && action.type === "gameData/setStepData") {
            action.payload.isGuest = isGuest;
        }

        return next(action);
    };
}
