import { useState, useEffect } from "react";

export const useDocumentSizes = () => {
    const [documentSizes, setDocumentSizes] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        window.addEventListener("resize", handleDocumentSizes);
        return () => window.removeEventListener("resize", handleDocumentSizes);
    }, []);

    const handleDocumentSizes = () => {
        setDocumentSizes({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    return documentSizes;
};
