import React from "react";

const Double = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 43.639 28.521">
            <g id="Group_911" data-name="Group 911">
                <path
                    d="M346.166,19.654h1.714l5.455-5.455L356.3,17.16a3.639,3.639,0,0,0,1.091-1.091V12.8c-1.558-2.182-5.922-3.74-11.066-3.74s-9.507,1.559-11.066,3.585v3.585C336.814,18.1,341.022,19.654,346.166,19.654Zm0,5.767a32.912,32.912,0,0,0,4.052-.312L351,24.33l-2.182-2.338a15.123,15.123,0,0,1-2.65.156c-5.143,0-9.507-1.559-11.065-3.585v3.273C336.814,24.018,341.022,25.42,346.166,25.42Zm5.767,7.793a22.419,22.419,0,0,1-5.767.779c-5.143,0-9.507-1.559-11.066-3.585v3.273c1.558,2.026,5.922,3.585,11.066,3.585s9.663-1.559,11.065-3.74V30.564l-4.052,4.052Zm-5.767-1.87a25.579,25.579,0,0,0,3.585-.312l-2.65-2.65.312-.312h-1.247c-5.143,0-9.507-1.559-11.065-3.585v3.273C336.814,29.784,341.022,31.343,346.166,31.343Zm15.118-13.715-4.052,4.052-4.052-4.052-2.65,2.65,4.052,4.052-4.052,4.052,2.65,2.65,4.052-4.052,4.052,4.052,2.65-2.65-4.052-4.052,4.052-4.052ZM369.7,32.746c0-4.676,9.039-7.949,9.039-16.832,0-4.52-2.338-7.013-6.7-7.013-4.52,0-6.7,2.65-6.7,7.013v2.805H369.7V15.758c0-2.026.935-2.805,2.338-2.805s2.338.623,2.338,3.273c0,8.1-9.04,9.507-9.04,17.767v3.429h13.092V33.369h-8.572A1.179,1.179,0,0,1,369.7,32.746Z"
                    transform="translate(-335.1 -8.9)"
                />
            </g>
        </svg>
    );
};

export default Double;
