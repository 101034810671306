import React, { useState } from "react";
import classNames from "classnames/bind";
import { toggleSound, getSoundStatus, playMusic, stopMusic } from "../../services/soundService";

import Sound from "../Icons/Sound";
import useDidUpdate from "../../customHooks/useDidUpdate";
import { useSelector } from "react-redux";

const SoundController = () => {
    const [isSoundOn, setIsSoundOn] = useState(getSoundStatus());
    const isGameOver = useSelector(state => state.gameData.disableScreen);

    useDidUpdate(() => {
        toggleSound(isSoundOn);
        (isSoundOn && !isGameOver ? playMusic : stopMusic)();
    }, [isSoundOn, isGameOver]);

    const handleSoundControl = () => {
        setIsSoundOn(prev => !prev);
    };

    return (
        <button
            className={classNames("game-settings-btn btn-sound", {
                "sound-off": !isSoundOn
            })}
            onClick={handleSoundControl}
        >
            <Sound />
        </button>
    );
};

export default SoundController;
