import React from "react";

const Private = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 13.781">
            <path
                d="M12.076,7.757A3.659,3.659,0,0,1,15.718,11.4h0c0,.03,0,.064,0,.1a2.891,2.891,0,0,1-.295,1.276l.008-.017L17.576,14.9a9.316,9.316,0,0,0,2.477-3.368l.023-.061a8.584,8.584,0,0,0-7.971-5.428h-.028a10.067,10.067,0,0,0-3,.52l.071-.021L10.72,8.115a2.262,2.262,0,0,1,1.233-.361c.043,0,.086,0,.129,0h-.006Zm-7.285-2,2,2A8.641,8.641,0,0,0,4.1,11.338l-.021.058a8.584,8.584,0,0,0,7.971,5.428h.09a8.041,8.041,0,0,0,3.212-.665l-.052.02.286.286,2.135,2.143.929-.929L5.791,4.827ZM8.862,9.828,10,10.97a1.039,1.039,0,0,0-.076.395,1.021,1.021,0,0,0,.006.109v0a2.225,2.225,0,0,0,2.213,2.214h.058a1.4,1.4,0,0,0,.452-.075l-.01,0,1.142,1.142a3.82,3.82,0,0,1-1.568.357h0a3.659,3.659,0,0,1-3.642-3.642h0a2.042,2.042,0,0,1-.059-.493A2.085,2.085,0,0,1,8.866,9.82l-.005.008Zm3.071-.572,2.285,2.285V11.4A2.225,2.225,0,0,0,12,9.182h0Z"
                transform="translate(-4.076 -4.827)"
            />
        </svg>
    );
};

export default Private;
