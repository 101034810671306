import React from "react";

const ArrowToRight = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24">
            <g transform="translate(0 24) rotate(-90)">
                <path d="M1.41,0,6,4.58,10.59,0,12,1.41l-6,6-6-6Z" transform="translate(6 8.59)" />
                <path d="M0,0H24V24H0Z" fill="none" />
            </g>
        </svg>
    );
};

export default ArrowToRight;
