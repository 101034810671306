import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import AppData from "./containers/AppData";

import "./assets/styles/styles.scss";

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <AppData />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root")
);
