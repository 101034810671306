import { getBrowserName, getBrowserSpecificProperties, getGameDataFromUrl } from "../helpers/utility";

export const APP_PROPERTIES = {
    DEFAULT_ASPECT_RATIO_WIDTH: 16,
    DEFAULT_ASPECT_RATIO_HEIGHT: 9,
    FONT_SIZE_SCALE_INDEX: 170,
    BOARD_HEIGHT_PERCENTAGES_IN_PORTRAIT: 68
};

// import images
import default_BG from "../assets/images/default_BG_img.jpg";

// export images
export const default_BG_img = default_BG;

export const DEVICE_MODE = {
    DESKTOP: "desktop",
    MOBILE: "mobile"
};

export const {
    isMobile,
    gameId,
    token,
    lang,
    consumerPartnerId,
    currencyId,
    win,
    bet,
    playerId,
    isHistory,
    guestId,
    isDemo
} = getGameDataFromUrl();

export const HTTP_METHODS = {
    GET: "GET",
    POST: "POST"
};

export const POSITIONS = {
    TOP_LEFT: "TOP_LEFT",
    BOTTOM_LEFT: "BOTTOM_LEFT",
    CENTER_LEFT: "CENTER_LEFT",
    BOTTOM_RIGHT: "BOTTOM_RIGHT",
    TOP_RIGHT: "TOP_RIGHT",
    CENTER_RIGHT: "CENTER_RIGHT",
    CENTER: "CENTER"
};

export const SOCKET_INVOKE_ACTIONS = {
    MOVE: "Move",
    AUTO_DEAL: "AutoDeal",
    LEAVE_GAME: "LeaveGame",
    GET_GAME_DATA: "GetGameData",
    GET_DOMINO_FROM_BAZAR: "GetDominoFromBazar",
    GET_INITIAL_STATE: "GetInitialState",
    DOUBLE_STAKE_REQUEST: "DoubleStakeRequest",
    DOUBLE_STAKE_RESPONSE: "DoubleStakeResponse",
    GET_INET_SPEED: "GetInetSpeed"
};

export const SOCKET_ACTIONS = {
    INITIAL_STATE: "InitialState",
    GAME_DATA: "GameData",
    STEP: "Step",
    ROUND_RESULT: "RoundResult",
    GAME_RESULT: "GameResult",
    DOUBLE_STAKE: "DoubleStake",
    CONNECTION_CHANGED: "ConnectionChanged",
    INET_SPEED: "InetSpeed"
};

export const TILE_MOVE_COURSE_ID = {
    UP: 1,
    RIGHT: 2,
    DOWN: 3,
    LEFT: 4,
    CENTER: 5
};

export const TILE_MOVE_COURSE_NAME = {
    1: "up",
    2: "right",
    3: "down",
    4: "left",
    5: "center"
};

export const TILE_ANIMATION_DIRECTIONS = {
    1: "left bottom",
    2: "right top",
    3: "left bottom",
    4: "left top",
    5: "left top"
};

export const TILES_MAP = {
    1: [1, 1],
    2: [2, 2],
    3: [3, 3],
    4: [4, 4],
    5: [5, 5],
    6: [6, 6],
    7: [0, 1],
    8: [0, 2],
    9: [0, 3],
    10: [0, 4],
    11: [0, 5],
    12: [0, 6],
    13: [1, 2],
    14: [1, 3],
    15: [1, 4],
    16: [1, 5],
    17: [1, 6],
    18: [2, 3],
    19: [2, 4],
    20: [2, 5],
    21: [2, 6],
    22: [3, 4],
    23: [3, 5],
    24: [3, 6],
    25: [4, 5],
    26: [4, 6],
    27: [5, 6],
    28: [0, 0]
};
export const dots = {
    1: [2, 3, 4, 5, 6],
    2: [6],
    3: [4, 5, 6],
    4: [1, 3, 5],
    5: [4, 5, 6],
    6: [6],
    7: [2, 3, 4, 5, 6]
};

export const POPUP_NAMES = {
    SETTINGS: "SETTINGS",
    LEAVE_GAME: "LEAVE_GAME",
    DOUBLE_STAKE: "DOUBLE_STAKE",
    DOUBLE_STAKE_ACCEPT: "DOUBLE_STAKE_ACCEPT"
};

//various constants
export const BROWSER_NAME = getBrowserName();
export const INITIAL_STATE = "initialState";
export const GAME_DATA = "gameData";
export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const IS_IOS_CHROME = IS_IOS && (BROWSER_NAME === "chrome" || BROWSER_NAME === "crios");
export const DOMINO_SOUND_KEY = "domino";
export const FINISH_TYPE_TIME_OUT = "TimeOut";
export const TILE_DEFAULT_SCALE_SIZE = 1;
export const MIN_TILE_SIZE_FOR_NEXT_STEP = isMobile ? 1.2 : 1.3;
export const TILE_MINIMUM_SCALE_SIZE = 0.3;
export const ALL_TILES_COUNT = 14;
export const DOUBLE_STAKE_OFFER_ID = 1;
export const DOUBLE_STAKE_ACCEPT_ID = 2;
export const DOUBLE_STAKE_REDOUBLE_ID = 3;
export const DOUBLE_STAKE_SURRENDER_ID = 4;
export const UP_DIRECTION_START_MIN_COUNT = 2;
export const RIGHT_DIRECTION_START_MIN_COUNT = 7;
export const DOWN_DIRECTION_START_MIN_COUNT = 1;
export const LEFT_DIRECTION_START_MIN_COUNT = 3;
export const CLASSIC_GAME_TYPE_ID = 27;
export const FIVES_GAME_TYPE_ID = 28;
export const THREES_GAME_TYPE_ID = 29;
export const RESET_DRAGGED_TILE_ID = 999;
export const CONNECT_TRY_MAX_COUNT = 30;

export const POINTS_MAP_BY_TYPE = {
    [CLASSIC_GAME_TYPE_ID]: 0,
    [FIVES_GAME_TYPE_ID]: 5,
    [THREES_GAME_TYPE_ID]: 3
};

//TODO: make directions lengths dynamic based on even tiles count in that direction
export const TILES_DIRECTIONS_BASED_ON_COUNT = {
    [TILE_MOVE_COURSE_ID.UP]: {
        min: UP_DIRECTION_START_MIN_COUNT,
        directionRanges: [
            { from: UP_DIRECTION_START_MIN_COUNT, direction: TILE_MOVE_COURSE_ID.LEFT },
            { from: 7, direction: TILE_MOVE_COURSE_ID.DOWN },
            { from: 14, direction: TILE_MOVE_COURSE_ID.RIGHT }
        ]
    },
    [TILE_MOVE_COURSE_ID.RIGHT]: {
        min: RIGHT_DIRECTION_START_MIN_COUNT,
        directionRanges: [
            { from: RIGHT_DIRECTION_START_MIN_COUNT, direction: TILE_MOVE_COURSE_ID.UP },
            { from: 12, direction: TILE_MOVE_COURSE_ID.LEFT },
            { from: 23, direction: TILE_MOVE_COURSE_ID.DOWN }
        ]
    },
    [TILE_MOVE_COURSE_ID.DOWN]: {
        min: DOWN_DIRECTION_START_MIN_COUNT,
        directionRanges: [
            { from: DOWN_DIRECTION_START_MIN_COUNT, direction: TILE_MOVE_COURSE_ID.RIGHT },
            { from: 11, direction: TILE_MOVE_COURSE_ID.UP },
            { from: 14, direction: TILE_MOVE_COURSE_ID.LEFT }
        ]
    },
    [TILE_MOVE_COURSE_ID.LEFT]: {
        min: LEFT_DIRECTION_START_MIN_COUNT,
        directionRanges: [
            { from: LEFT_DIRECTION_START_MIN_COUNT, direction: TILE_MOVE_COURSE_ID.DOWN },
            { from: 7, direction: TILE_MOVE_COURSE_ID.RIGHT },
            { from: 19, direction: TILE_MOVE_COURSE_ID.UP }
        ]
    }
};

export const { HIDDEN, VISIBILITY_CHANGE } = getBrowserSpecificProperties();

export const transitionEndEventName = (() => {
    let transitions = {
        transition: "transitionend",
        OTransition: "oTransitionEnd",
        MozTransition: "transitionend",
        WebkitTransition: "webkitTransitionEnd"
    };
    let bodyStyle = document.body.style;
    for (let transition in transitions) {
        if (bodyStyle[transition] !== undefined) {
            return transitions[transition];
        }
    }
})();

export const VALIDATION_COOKIE_NAME = "_ga_anl_cf3q";

export const keysMap = {
    is: "isSwitched",
    ip: "isPlayer",
    ic: "isConnected",
    p: "player",
    o: "opponent",
    ipt: "isPlayerTurn",
    ipp: "isPlayerPlayed",
    ds: "doubleStake",
    dsd: "doubleStakeData",
    gt: "gameTime",
    st: "stepTime",
    n: "notification",
    vm: "validMoves",
    i: "id",
    vc: "validCourses",
    mc: "moveCourse",
    w: "point",
    ct: "connectorTile",
    ss: "status",
    ns: "nextStake",
    cr: "canRedouble",
    cs: "currentStake",
    mds: "IsMaxDoubleStake",
    b: "board",
    gs: "gameScore",
    bz: "bazar",
    bdc: "bazarDominoesCount",
    pp: "popups",
    igs: "isGameStarted",
    d: "dominoes",
    cid: "centralDominoId",
    lds: "leftDominoesSet",
    rds: "rightDominoesSet",
    uds: "upDominoesSet",
    dds: "downDominoesSet",
    s: "score",
    rr: "roundResult",
    sct: "setConnectorTile",
    di: "dominoId",
    pg: "playerGameScore",
    og: "opponentGameScore",
    rs: "roundsScores",
    prs: "playerRoundScore",
    pa: "playerAdditionalPoint",
    ors: "opponentRoundScore",
    oa: "opponentAdditionalPoint",
    f: "finishStatus",
    ipw: "isPlayerWin",
    gc: "gameConfiguration",
    pis: "playerInitialState",
    ois: "opponentInitialState",
    ti: "tournamentInfo",
    ig: "isGuest",
    id: "isDemo",
    nr: "newRound",
    mp: "multiplePair",
    aw: "allToWinner",
    in: "incognito",
    pe: "private",
    msd: "maxStakeDoubling",
    gp: "gamePoints",
    ge: "gameType",
    nn: "playerNickName",
    td: "tournamentId",
    to: "tourOrder",
    pf: "prizeFund",
    dm: "dominoMove",
    bdm: "bazarDominoMove",
    crs: "currentRoundScore",
    dx: "dominoIndex",
    iw: "isDraw",
    dp: "dominoesPoint",
    ap: "additionalPoint"
};
