import React, { Fragment, memo, useCallback, useMemo, useRef } from "react";
import Tile from "../../Tile";
import {
    isHistory,
    SOCKET_INVOKE_ACTIONS,
    TILE_MOVE_COURSE_ID,
    TILE_MOVE_COURSE_NAME,
    TILES_MAP
} from "../../../constants";
import { invoke } from "../../../connection";
import PropTypes from "prop-types";
import PointsNotification from "../../PointsNotification";
import classNames from "classnames";
import { getEachTileDirectionBasedOnCount } from "../../../helpers/localHelperFunctions";
import useDidUpdate from "../../../customHooks/useDidUpdate";
import { getBoardTilesData, useShallowSelector } from "../../../store/selectors";

const BoardTiles = ({ updateTilesPositionsAndSizes }) => {
    const centralTileRef = useRef(null);
    const {
        targetTileInfo,
        isPlayerTurn,
        selectedTile,
        centralDominoId,
        leftDominoesSet,
        rightDominoesSet,
        upDominoesSet,
        downDominoesSet,
        firstPossibleTileIsRotated
    } = useShallowSelector(getBoardTilesData);

    const upDominoes = upDominoesSet.dominoes;
    const rightDominoes = rightDominoesSet.dominoes;
    const downDominoes = downDominoesSet.dominoes;
    const leftDominoes = leftDominoesSet.dominoes;
    const centralTile = TILES_MAP[centralDominoId];
    const firstTileRotated = centralDominoId > 0 && TILES_MAP[centralDominoId][0] !== TILES_MAP[centralDominoId][1];

    const doStep = direction => {
        if (isPlayerTurn && selectedTile) {
            invoke(SOCKET_INVOKE_ACTIONS.MOVE, [selectedTile.dominoId, direction]);
        }
    };

    const selectedTileValidCourses = useMemo(() => {
        return selectedTile?.validCourses || [];
    }, [selectedTile]);

    const getPossibleTileInfoByDirection = moveCourse => {
        return isPlayerTurn && selectedTileValidCourses.find(i => i.moveCourse === moveCourse);
    };

    const collectPossibleConnectorTiles = (connectorTiles, moveCourse, currentDirectionDominoes) => {
        const possibleTileInfo = getPossibleTileInfoByDirection(moveCourse);
        if (!currentDirectionDominoes.length && possibleTileInfo) {
            connectorTiles.push({
                connectorTile: possibleTileInfo.connectorTile,
                moveCourse,
                point: possibleTileInfo.point
            });
        }
    };

    const centralDominoConnectorTiles = useMemo(() => {
        const connectorTiles = [];
        let centerRightDirection = false;
        let centerLeftDirection = false;
        const { UP, RIGHT, DOWN, LEFT } = TILE_MOVE_COURSE_ID;

        if (
            isPlayerTurn &&
            selectedTileValidCourses.length &&
            centralTile &&
            (!upDominoes.length || !rightDominoes.length || !downDominoes.length || !leftDominoes.length)
        ) {
            const [firstValue, secondsValue] = centralTile;
            if (
                selectedTileValidCourses.find(i => i.connectorTile === firstValue || i.connectorTile === secondsValue)
            ) {
                if (firstValue === secondsValue) {
                    if (leftDominoes.length && rightDominoes.length) {
                        !upDominoes.length && collectPossibleConnectorTiles(connectorTiles, UP, upDominoes);
                        !downDominoes.length && collectPossibleConnectorTiles(connectorTiles, DOWN, downDominoes);
                    } else {
                        if (!rightDominoes.length) {
                            centerRightDirection = true;
                            collectPossibleConnectorTiles(connectorTiles, RIGHT, rightDominoes);
                        }
                        if (!leftDominoes.length) {
                            centerLeftDirection = true;
                            collectPossibleConnectorTiles(connectorTiles, LEFT, leftDominoes);
                        }
                    }
                } else {
                    if (!leftDominoes.length) {
                        centerLeftDirection = true;
                        collectPossibleConnectorTiles(connectorTiles, LEFT, leftDominoes);
                    }
                    if (!rightDominoes.length) {
                        centerRightDirection = true;
                        collectPossibleConnectorTiles(connectorTiles, RIGHT, rightDominoes);
                    }
                }
            }
        }

        return { connectorTiles, centerRightDirection, centerLeftDirection };
    }, [centralTile, selectedTile, upDominoes, rightDominoes, downDominoes, leftDominoes]);

    const getTileConnectClassByDirection = (direction, firstValue, secondValue, prevTile) => {
        const isFirstConnectorTile = prevTile.includes(firstValue);
        const isSecondConnectorTile = prevTile.includes(secondValue);
        const isTileEven = firstValue === secondValue;
        let className = "";

        switch (direction) {
            case TILE_MOVE_COURSE_ID.UP:
                if (isTileEven) {
                    className = "rotated-to-left";
                } else if (isFirstConnectorTile) {
                    className = "rotated-reverse";
                }
                break;
            case TILE_MOVE_COURSE_ID.RIGHT: {
                if (!isTileEven) {
                    if (isFirstConnectorTile) {
                        className = "rotated-to-left";
                    }
                    if (isSecondConnectorTile) {
                        className = "rotated-to-right";
                    }
                }
                break;
            }
            case TILE_MOVE_COURSE_ID.DOWN:
                if (isTileEven) {
                    className = "rotated-to-left";
                } else if (isSecondConnectorTile) {
                    className = "rotated-reverse";
                }
                break;
            case TILE_MOVE_COURSE_ID.LEFT: {
                if (!isTileEven) {
                    if (isFirstConnectorTile) {
                        className = "rotated-to-right";
                    }
                    if (isSecondConnectorTile) {
                        className = "rotated-to-left";
                    }
                }
                break;
            }
            case TILE_MOVE_COURSE_ID.CENTER:
            default:
                break;
        }

        return className;
    };

    // tiles recursive printing
    const printTiles = (dominoesSet, moveCourse, realIndex, moveCourseDirection) => {
        const { dominoes, setConnectorTile, score, updatedDominoes } = dominoesSet;
        let dominoesCopy = updatedDominoes ? [...updatedDominoes] : [...dominoes];
        return dominoesCopy.map((i, index) => {
            if (realIndex && index >= 2) {
                ++realIndex;
            }
            const directionCourse = getEachTileDirectionBasedOnCount(moveCourse, realIndex || index);
            if (directionCourse) {
                const prevTileForCourse = TILES_MAP[dominoesCopy[index - 1].dominoId];
                const updatedDominoesSet = {
                    ...dominoesSet,
                    updatedDominoes: dominoesCopy.slice(index, dominoesCopy.length)
                };
                dominoesCopy.splice(index, dominoesCopy.length);
                return (
                    <div
                        key={i.dominoId}
                        className={classNames(`${TILE_MOVE_COURSE_NAME[directionCourse]}`, {
                            "next-to-even-tile": prevTileForCourse[0] === prevTileForCourse[1]
                        })}
                    >
                        {printTiles(updatedDominoesSet, moveCourse, (realIndex || index) + 1, directionCourse)}
                    </div>
                );
            }
            const possibleTileInfo = getPossibleTileInfoByDirection(moveCourse);
            const isLastTile = i.dominoId === dominoes[dominoes.length - 1].dominoId;
            const id = isLastTile ? `moveCourse${moveCourse}` : "";
            const isTileClickable = !!(isLastTile && possibleTileInfo);
            const tile = TILES_MAP[i.dominoId];
            const prevTileId = realIndex ? realIndex - (index === 0 ? 2 : 1) : index - 1;

            const prevTile =
                index === 0 && !realIndex ? TILES_MAP[centralDominoId] : TILES_MAP[dominoes[prevTileId].dominoId];

            const className = getTileConnectClassByDirection(
                moveCourseDirection || moveCourse,
                tile[0],
                tile[1],
                prevTile
            );
            const possibleTileConnectorInfo = [
                { connectorTile: setConnectorTile, moveCourse, point: possibleTileInfo?.point }
            ];
            return (
                <Fragment key={i.dominoId}>
                    <Tile
                        id={id}
                        score={score}
                        showFace
                        tileId={i.dominoId}
                        boardTile
                        isLastTile={isLastTile}
                        moveCourse={moveCourse}
                        className={className}
                        clickHandler={isTileClickable ? tileClickHandler : null}
                        possibleTileConnectorInfo={isTileClickable ? possibleTileConnectorInfo : null}
                    />
                    {isTileClickable && !targetTileInfo && (
                        <PointsNotification
                            point={selectedTileValidCourses.find(j => j.moveCourse === moveCourse).point}
                            boardPossiblePoint
                        />
                    )}
                </Fragment>
            );
        });
    };

    const tileClickHandler = useCallback(
        direction => () => {
            if (isPlayerTurn && selectedTile) {
                doStep(direction);
            }
        },
        [isPlayerTurn, selectedTile]
    );

    // ******  old history tiles sizes version   ****
    // useDidUpdate(() => {
    //     if (centralDominoId > -1) {
    //         if (isHistory) {
    //             const boardTilesCurrentCount =
    //                 upDominoes.length +
    //                 rightDominoes.length +
    //                 downDominoes.length +
    //                 leftDominoes.length +
    //                 (centralDominoId !== 0 ? 1 : 0);
    //             const lessThenPrev = boardTilesCount.current > boardTilesCurrentCount;
    //             const UsedHistorySlider = Math.abs(boardTilesCount.current - boardTilesCurrentCount) > 1;
    //             boardTilesCount.current = boardTilesCurrentCount;
    //             updateTilesPositionsAndSizes(null, lessThenPrev, UsedHistorySlider, boardTilesCurrentCount);
    //         } else {
    //             updateTilesPositionsAndSizes();
    //         }
    //     }
    // }, [upDominoes.length, rightDominoes.length, downDominoes.length, leftDominoes.length, centralDominoId]);

    useDidUpdate(() => {
        let intervalid;
        if (centralDominoId > -1) {
            const withoutAnimation =
                !upDominoes.length && !rightDominoes.length && !downDominoes.length && !leftDominoes.length;
            const scaled = updateTilesPositionsAndSizes(withoutAnimation);
            // console.log("/////////  scaled  ", scaled);
            const tliesContainerEl = document.getElementsByClassName("tiles-container")[0];
            const tliesContainerElTr = tliesContainerEl.style.transform;
            const tliesContainerElTrVal = tliesContainerElTr.substring(
                tliesContainerElTr.indexOf("(") + 1,
                tliesContainerElTr.indexOf(")")
            );
            // console.log("//////////  el scale val  ", tliesContainerElTrVal);

            if (!isHistory && Number(tliesContainerElTrVal) === 1) {
                let boardTilesCount =
                    upDominoes.length + rightDominoes.length + downDominoes.length + leftDominoes.length;
                boardTilesCount += centralDominoId !== 0 ? 1 : 0;

                if (boardTilesCount > 3 && !scaled) {
                    setTimeout(() => {
                        updateTilesPositionsAndSizes(true);
                    }, 200);
                }
            }
        }
        return () => clearInterval(intervalid);
    }, [upDominoes.length, rightDominoes.length, downDominoes.length, leftDominoes.length, centralDominoId]);

    useDidUpdate(() => {
        centralDominoId === -1 && selectedTile && updateTilesPositionsAndSizes(true);
    }, [selectedTile, centralDominoId]);

    return (
        <>
            <div
                className={classNames("left-tiles", {
                    "first-tile-rotated": firstTileRotated
                })}
            >
                {printTiles(leftDominoesSet, TILE_MOVE_COURSE_ID.LEFT)}
            </div>
            <div className="tiles-column">
                <div className="up-tiles">{printTiles(upDominoesSet, TILE_MOVE_COURSE_ID.UP)}</div>

                {centralDominoId > 0 ? (
                    <div ref={centralTileRef}>
                        <Tile
                            id={"moveCourse5"}
                            isCenter
                            boardTile
                            showFace
                            className={classNames("first-tile", {
                                "rotated-to-left": firstTileRotated
                            })}
                            tileId={centralDominoId}
                            moveCourse={TILE_MOVE_COURSE_ID.CENTER}
                            clickHandler={tileClickHandler}
                            possibleTileConnectorInfo={centralDominoConnectorTiles.connectorTiles}
                            centerRightDirection={centralDominoConnectorTiles.centerRightDirection}
                            centerLeftDirection={centralDominoConnectorTiles.centerLeftDirection}
                        />
                        {selectedTileValidCourses.length > 0 &&
                            !targetTileInfo &&
                            centralDominoConnectorTiles.connectorTiles.map((i, index) => {
                                return (
                                    <PointsNotification
                                        className={`point-${TILE_MOVE_COURSE_NAME[i.moveCourse]}`}
                                        key={index}
                                        point={i.point}
                                        boardPossiblePoint
                                    />
                                );
                            })}
                    </div>
                ) : (
                    <div
                        id="moveCourse5"
                        style={{
                            opacity: selectedTile && isPlayerTurn ? 1 : 0
                        }}
                        className={classNames("first-tile-container", {
                            rotated: firstPossibleTileIsRotated
                        })}
                        onClick={tileClickHandler(TILE_MOVE_COURSE_ID.CENTER)}
                    >
                        {selectedTileValidCourses.length > 0 && !targetTileInfo && (
                            <PointsNotification point={selectedTileValidCourses[0].point} boardPossiblePoint />
                        )}
                    </div>
                )}

                <div className="down-tiles">{printTiles(downDominoesSet, TILE_MOVE_COURSE_ID.DOWN)}</div>
            </div>
            <div
                className={classNames("right-tiles", {
                    "first-tile-rotated": firstTileRotated
                })}
            >
                {printTiles(rightDominoesSet, TILE_MOVE_COURSE_ID.RIGHT)}
            </div>
        </>
    );
};

BoardTiles.propTypes = {
    updateTilesPositionsAndSizes: PropTypes.func.isRequired
};

export default memo(BoardTiles);
