import React from "react";

const Info = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 18">
            <path
                d="M11,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm.9,13.5H10.1V10.1h1.8Zm0-7.2H10.1V6.5h1.8Z"
                transform="translate(-2 -2)"
            />
        </svg>
    );
};

export default Info;
