import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import useDidUpdate from "../../../customHooks/useDidUpdate";
import { isHistory } from "../../../constants";

const ChanceTile = () => {
    const [isVisible, setIsvisible] = useState(true);
    const bazarDominoesCount = useSelector(({ gameData }) => gameData.bazarDominoesCount);

    useDidUpdate(() => {
        if (!isHistory) {
            setIsvisible(false);
            setTimeout(() => {
                setIsvisible(true);
            });
        }
    }, [bazarDominoesCount]);

    if (!isVisible) {
        return null;
    }

    return <div id="chance-tile" className="chance-tile" />;
};

export default memo(ChanceTile);
