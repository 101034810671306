import React, { memo } from "react";
import classnames from "classnames";

import { CLASSIC_GAME_TYPE_ID, isHistory } from "../../constants";
import { getScoreBarData, useShallowSelector } from "../../store/selectors";
import { setHistoryCurrentRound } from "../../store/slices/gameData";
import { useDispatch } from "react-redux";

const ScoreBarContent = () => {
    const dispatch = useDispatch();
    const { currentRound, roundsScores, playerGameScore, opponentGameScore, gameType } = useShallowSelector(
        getScoreBarData
    );

    const handleScoreSelection = index => () => {
        dispatch(setHistoryCurrentRound(index));
    };

    return (
        <div className={`score-wrapper ${isHistory ? "history-mode" : ""}`}>
            <div className="scroll-content">
                {roundsScores?.map((round, index) => (
                    <div
                        className={classnames(`score-item`, { selected: isHistory && currentRound === index })}
                        key={index}
                        onClick={isHistory ? handleScoreSelection(index) : null}
                    >
                        <div className="opponent-score">
                            <div className="item-content">{`${round.opponentRoundScore} ${
                                gameType !== CLASSIC_GAME_TYPE_ID ? `(+ ${round.opponentAdditionalPoint})` : ""
                            }`}</div>
                        </div>
                        <div className="player-score">
                            <div className="item-content">{`${round.playerRoundScore} ${
                                gameType !== CLASSIC_GAME_TYPE_ID ? `(+ ${round.playerAdditionalPoint})` : ""
                            }`}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="main-score">
                <div className="opponent-score">{opponentGameScore}</div>
                <div className="player-score">{playerGameScore}</div>
            </div>
        </div>
    );
};

export default memo(ScoreBarContent);
