import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch } from "react-redux";
import Tile from "../../Tile";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
    setMobilePlayerMoreTiles,
    setMobileMoveBoardUp,
    setSelectedTile,
    updateSelectedTile
} from "../../../store/slices/gameData";
import { getElementsPosition } from "../../../helpers/utility";
import {
    getClosestParentNodeBySelector,
    getDirectionByTilesCount,
    getElementsDistance,
    getValidDirectionRotateInfo
} from "../../../helpers/localHelperFunctions";
import { GenerateTileAnimationStyles } from "../../../hocs/GenerateTileAnimationStyles";
import useDidUpdate from "../../../customHooks/useDidUpdate";
import { invoke } from "../../../connection";
import { IS_IOS, isHistory, isMobile, SOCKET_INVOKE_ACTIONS } from "../../../constants";
import { getPlayerTilesData, useShallowSelector } from "../../../store/selectors";
import PointsNotification from "../../PointsNotification";
import WinnerBadge from "../../WinnerBadge";
import ChanceTile from "../ChanceTile";
import { getTranslations } from "../../../helpers/translation";

const PlayerTiles = ({ getTileAnimationStyles }) => {
    const dispatch = useDispatch();
    const mobileTouched = useRef(null);
    const possibleTilesForDrop = useRef(null);
    const sortedElsByDistance = useRef(null);
    const playerTilesRef = useRef(null);
    const tileWrapperRef = useRef(null);
    const [draggedTile, setDraggedTile] = useState(null);
    const [tileToResetDrag, setTileToResetDrag] = useState(null);
    const [rerenderPlayerTiles, setRerenderPlayerTiles] = useState(null);

    const translate = useMemo(() => getTranslations(), []);

    const {
        bazar,
        isGuest,
        dominoes,
        validMoves,
        isPlayerWin,
        isPlayerTurn,
        selectedTile,
        playedDomino,
        roundFinished,
        dominoesPoint,
        isGameVisible,
        isPlayerPlayed,
        additionalPoint,
        centralDominoId,
        historyStepPoint,
        isDragAndDropEnabled
    } = useShallowSelector(getPlayerTilesData);

    const getSelectedTileMoveCoursePositions = (dominoInfo, el) => {
        const moveCoursesPositions = {};
        dominoInfo.validCourses.forEach(i => {
            const { direction } = getDirectionByTilesCount(null, i.moveCourse);
            moveCoursesPositions[i.moveCourse] = getElementsPosition(el, getValidDirectionRotateInfo(direction));
        });
        return moveCoursesPositions;
    };

    const tileClickHandler = (dominoInfo, draggedElement) => e => {
        if (selectedTile?.dominoId !== dominoInfo.dominoId && !tileToResetDrag) {
            const el = draggedElement || e.currentTarget;
            dispatch(setSelectedTile({ ...dominoInfo, ...getSelectedTileMoveCoursePositions(dominoInfo, el) }));
        } else {
            dispatch(setSelectedTile(null));
        }
    };

    useDidUpdate(() => {
        !selectedTile && setDraggedTile(null);
    }, [selectedTile]);

    // const ondragStart = dominoInfo => e => {
    // setTileToResetDrag(null);
    // if (isMobile) {
    //     mobileTouched.current = true;
    //     document.addEventListener(
    //         "touchend",
    //         () => {
    //             if (mobileTouched.current) {
    //                 const el = getClosestParentNodeBySelector(e.target, ".tile-wrapper");
    //                 tileClickHandler(dominoInfo, el)(e);
    //             }
    //         },
    //         { once: true }
    //     );
    // }
    // };

    const resetToDefaultPositions = (domino = null) => {
        dispatch(setSelectedTile(null));
        setDraggedTile(null);
        sortedElsByDistance.current = null;
        possibleTilesForDrop.current = null;
        setTileToResetDrag(domino);
        setTimeout(() => setTileToResetDrag(null));
        toggleTileZindexWhileDragging("removeAll");
    };

    const onDrag = dominoInfo => e => {
        if (!draggedTile?.[dominoInfo.dominoId]) {
            // console.log(">>>>>>>  ON DRAG set selected tile");
            toggleTileZindexWhileDragging("add", e.target);
            const el = getClosestParentNodeBySelector(e.target, ".tile-wrapper");
            if (el) {
                setDraggedTile({ [dominoInfo.dominoId]: true });
                tileClickHandler(dominoInfo, el)(e);
            } else {
                resetToDefaultPositions(dominoInfo.dominoId);
                // console.log(">>>>>>>  ON DRAG set selected tile  NO_ELLLLL");
            }
        }
    };

    const onDragEnd = dominoInfo => e => {
        // console.log("}}}}}}}}}}}}}}}}  on drag end  ", draggedTile);
        if (draggedTile) {
            mobileTouched.current = false;
            toggleTileZindexWhileDragging("remove", e.target);
            const el = getClosestParentNodeBySelector(e.target, ".tile-wrapper");
            const mainBoardRect = document.getElementById("main-board-helper").getBoundingClientRect();
            const elRect = getElementsPosition(el);
            if (!elRect || elRect.yCenter > mainBoardRect.bottom) {
                // console.log(">>>>>>>>>>  ON DRAG END RESET");
                resetToDefaultPositions(dominoInfo.dominoId);
            } else if (!sortedElsByDistance.current?.length) {
                // console.log(">>>>>>>  ON DRAG END  NORMAL");

                if (centralDominoId === -1) {
                    possibleTilesForDrop.current = [document.getElementById("moveCourse5")];
                } else {
                    possibleTilesForDrop.current = document.querySelectorAll('*[class^="possible-tile-layer-helper"]');
                }
                // console.log(">>>>>>>  ON DRAG get possible target tiles", possibleTilesForDrop.current);

                if (!possibleTilesForDrop.current || !possibleTilesForDrop.current.length) {
                    // console.log(">>>>>>>>>  EMPTY NODE list  ", possibleTilesForDrop.current);
                    resetToDefaultPositions(dominoInfo.dominoId);
                } else {
                    if (e.target?.getBoundingClientRect) {
                        sortedElsByDistance.current = [];
                        for (let i = 0; i < possibleTilesForDrop.current.length; ++i) {
                            sortedElsByDistance.current.push({
                                el: possibleTilesForDrop.current[i],
                                distance: getElementsDistance(e.target, possibleTilesForDrop.current[i])
                            });
                        }
                        sortedElsByDistance.current.sort((a, b) => a.distance - b.distance);
                    }

                    dispatch(updateSelectedTile(getSelectedTileMoveCoursePositions(dominoInfo, el)));
                    // element id is our possible move course
                    let moveCourse = sortedElsByDistance.current[0].el.id;
                    if (moveCourse.includes("moveCourse")) {
                        moveCourse = moveCourse.replace("moveCourse", "");
                    }
                    invoke(SOCKET_INVOKE_ACTIONS.MOVE, [selectedTile.dominoId, Number(moveCourse)]);
                }

                possibleTilesForDrop.current = null;
                sortedElsByDistance.current = null;
            } else {
                // console.log(">>>>>>>  ON DRAG END  BUGOT");
                resetToDefaultPositions(dominoInfo.dominoId);
            }
        }
    };

    const toggleTileZindexWhileDragging = (action, target) => {
        if (action === "removeAll") {
            const draggedElements = document.getElementsByClassName("draggable-zIndex");
            for (let i = 0; i < draggedElements.length; ++i) {
                draggedElements[i].classList.remove("draggable-zIndex");
            }
        } else {
            document.getElementById("game-board").classList?.[action]("tile-dragging");
            const wrapperEl = getClosestParentNodeBySelector(target, ".tile-wrapper-helper");
            wrapperEl?.classList?.[action]("draggable-zIndex");
        }
    };

    useEffect(() => {
        if (isMobile) {
            const moveBoardUp =
                isPlayerTurn &&
                (dominoes.length > 10 || (dominoes.length === 10 && bazar && isPlayerTurn && !roundFinished));
            const playerMoreTiles = dominoes.length > 10 || (dominoes.length === 10 && bazar);

            batch(() => {
                dispatch(setMobileMoveBoardUp(moveBoardUp));
                dispatch(setMobilePlayerMoreTiles(playerMoreTiles));
            });
        }
    }, [isPlayerTurn, dominoes.length, bazar, roundFinished]);

    useEffect(() => {
        const pointerCancelHandler = () => {
            setRerenderPlayerTiles(true);
            resetToDefaultPositions();
            setTimeout(() => setRerenderPlayerTiles(false));
        };
        IS_IOS && window.addEventListener("pointercancel", pointerCancelHandler);
        return () => window.removeEventListener("pointercancel", pointerCancelHandler);
    }, []);

    return (
        <div
            className={classNames("player-tiles-container", {
                "game-end-with-no-tiles": roundFinished && !dominoes.length
            })}
        >
            {isGuest && <div className="player-notification">{translate("GuestMode")}</div>}

            <div
                className={classNames("player-tiles", {
                    closed: !isPlayerTurn && dominoes.length > 10,
                    history: isHistory
                })}
                ref={playerTilesRef}
            >
                {!isGameVisible || rerenderPlayerTiles
                    ? null
                    : dominoes.map((dominoId, index) => {
                          const validCurrentDomino = validMoves?.find(i => i.dominoId === dominoId);
                          const isCurrentDominoSelected = selectedTile?.dominoId === dominoId;
                          const style = isCurrentDominoSelected && !roundFinished && getTileAnimationStyles();
                          return (
                              <div className="tile-wrapper-helper" ref={tileWrapperRef} key={dominoId || index}>
                                  <Tile
                                      isPlayer
                                      disabledToDrag={Boolean(
                                          !isPlayerTurn || selectedTile || !validCurrentDomino || roundFinished
                                      )}
                                      onDragEnd={
                                          !isGuest &&
                                          isDragAndDropEnabled &&
                                          !roundFinished &&
                                          onDragEnd(validCurrentDomino)
                                      }
                                      onDrag={
                                          !isGuest &&
                                          isDragAndDropEnabled &&
                                          !roundFinished &&
                                          onDrag(validCurrentDomino)
                                      }
                                      resetDrag={tileToResetDrag === dominoId}
                                      draggedTile={draggedTile}
                                      tileId={dominoId}
                                      style={style}
                                      className={classNames({
                                          invalid: (!isPlayerTurn || !validCurrentDomino) && !isGuest,
                                          valid: isPlayerTurn && validCurrentDomino,
                                          selected:
                                              isCurrentDominoSelected &&
                                              isPlayerTurn &&
                                              validCurrentDomino &&
                                              !draggedTile?.[dominoId] &&
                                              !isDragAndDropEnabled
                                      })}
                                      showAnimation={Boolean(isCurrentDominoSelected && style)}
                                      clickHandler={
                                          isPlayerTurn &&
                                          validCurrentDomino &&
                                          !roundFinished &&
                                          !isGuest &&
                                          !isDragAndDropEnabled &&
                                          !isHistory
                                              ? tileClickHandler(validCurrentDomino)
                                              : null
                                      }
                                  />
                              </div>
                          );
                      })}

                {bazar && isPlayerTurn && !roundFinished && <ChanceTile />}
                {((playedDomino?.isPointValuable && isPlayerPlayed) || (isHistory && historyStepPoint)) && (
                    <PointsNotification
                        playedDomino={playedDomino}
                        point={isHistory ? historyStepPoint : playedDomino.point}
                        className="game-end-notification right"
                    />
                )}
                {roundFinished && (
                    <>
                        {dominoesPoint > 0 && (
                            <PointsNotification
                                point={dominoesPoint}
                                withoutPlus
                                className="game-end-notification rounded left"
                            />
                        )}
                        {isPlayerWin && (
                            <>
                                {additionalPoint > 0 && (
                                    <PointsNotification
                                        point={additionalPoint}
                                        className="game-end-notification rounded right"
                                    />
                                )}
                                <WinnerBadge />
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

PlayerTiles.propTypes = {
    getTileAnimationStyles: PropTypes.func.isRequired
};

export default memo(GenerateTileAnimationStyles(PlayerTiles));
