import React, { memo, useEffect, useRef } from "react";
import Front from "./Front";
import Back from "./Back";
import PropTypes from "prop-types";
import { transitionEndEventName } from "../../constants";
import { useDispatch } from "react-redux";
import { toggleAnimation } from "../../store/slices/gameData";
import Draggable from "react-draggable";
import { useShallowSelector } from "../../store/selectors";

const Tile = ({
    id,
    tileId,
    isPlayer,
    isCenter,
    boneYard,
    threeD,
    boardTile,
    moveCourse,
    disabledToDrag,
    className,
    isLastTile,
    onDrag,
    style,
    onDragEnd,
    draggedTile,
    clickHandler,
    showAnimation,
    resetDrag,
    centerLeftDirection,
    centerRightDirection,
    possibleTileConnectorInfo
}) => {
    const ref = useRef(null);
    const draggableRef = useRef(null);
    const dispatch = useDispatch();
    const gameBoardDimensions = useShallowSelector(state => state.gameData.gameBoardDimensions);

    const transitionEnd = () => {
        // setTimeout is required because of transition end triggered a few milliseconds before real transition finishing
        setTimeout(() => {
            dispatch(toggleAnimation(false));
        });
    };

    useEffect(() => {
        if (showAnimation) {
            ref.current.addEventListener(transitionEndEventName, transitionEnd);
        }
        return () => ref.current?.removeEventListener(transitionEndEventName, transitionEnd);
    }, [showAnimation]);

    const tile = (
        <div
            id={id}
            ref={ref}
            style={style || {}}
            className={`tile-wrapper ${className}`}
            onClick={!draggedTile?.[tileId] && (isPlayer || boneYard) ? clickHandler : null}
        >
            <Front
                id={id}
                centerRightDirection={centerRightDirection}
                centerLeftDirection={centerLeftDirection}
                isCenter={isCenter}
                isPlayer={isPlayer}
                tileId={tileId}
                isLastTile={isLastTile}
                moveCourse={moveCourse}
                boardTile={boardTile}
                clickHandler={!isPlayer ? clickHandler : null}
                possibleTileConnectorInfo={possibleTileConnectorInfo}
            />
            <Back />
            {threeD && showAnimation && (
                <>
                    <div className="side side-top" />
                    <div className="side side-bottom" />
                    <div className="side side-right" />
                    <div className="side side-left" />
                    <div className="side side-helper-front" />
                    <div className="side side-helper-back" />
                    <div className="side side-helper-inside1" />
                    <div className="side side-helper-inside2" />
                </>
            )}
        </div>
    );

    const getDragBounds = () => {
        const { left, top, right, bottom } = gameBoardDimensions;
        const tileDimensions = draggableRef.current.getBoundingClientRect();

        return {
            left: left - tileDimensions.left,
            top: top - tileDimensions.top,
            right: right - tileDimensions.right,
            bottom: bottom - tileDimensions.bottom
        };
    };

    const bounds = gameBoardDimensions && draggableRef.current ? getDragBounds() : {};
    const position = resetDrag ? { x: 0, y: 0 } : null;

    return (
        <>
            {onDrag ? (
                <Draggable
                    bounds={bounds}
                    nodeRef={draggableRef}
                    disabled={disabledToDrag}
                    allowAnyClick={false}
                    position={position}
                    onStop={onDragEnd}
                    onDrag={onDrag}
                >
                    <div ref={draggableRef}>{tile}</div>
                </Draggable>
            ) : (
                tile
            )}
        </>
    );
};

Tile.propTypes = {
    style: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    id: PropTypes.string,
    tileId: PropTypes.number,
    isCenter: PropTypes.bool,
    threeD: PropTypes.bool,
    resetDrag: PropTypes.bool,
    isPlayer: PropTypes.bool,
    boneYard: PropTypes.bool,
    boardTile: PropTypes.bool,
    isLastTile: PropTypes.bool,
    moveCourse: PropTypes.number,
    className: PropTypes.string,
    clickHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    showAnimation: PropTypes.bool,
    onDrag: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onDragEnd: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    draggedTile: PropTypes.object,
    disabledToDrag: PropTypes.bool,
    centerRightDirection: PropTypes.bool,
    centerLeftDirection: PropTypes.bool,
    possibleTileConnectorInfo: PropTypes.array
};

Tile.defaultProps = {
    id: null,
    style: null,
    tileId: null,
    className: "",
    onDrag: null,
    threeD: null,
    onDragEnd: null,
    draggedTile: null,
    boneYard: false,
    resetDrag: false,
    isCenter: false,
    moveCourse: null,
    isPlayer: false,
    boardTile: null,
    isLastTile: false,
    clickHandler: null,
    showAnimation: null,
    disabledToDrag: true,
    centerLeftDirection: false,
    centerRightDirection: false,
    possibleTileConnectorInfo: null
};

// eslint-disable-next-line react/display-name
export default memo(Tile, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});
