import React from "react";

const Play = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
            <path d="M8,5V19l11-7Z" transform="translate(0 0)" />
            <path d="M0,0H24V24H0Z" fill="none" />
        </svg>
    );
};

export default Play;
