import { createSlice } from "@reduxjs/toolkit";
import { join2Objects } from "../../helpers/localHelperFunctions";

const popup = createSlice({
    name: "popup",
    initialState: {},
    reducers: {
        openPopup: (state, { payload }) => {
            join2Objects(state, payload);
        },
        closePopup: state => {
            delete state.name;
            delete state.data;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            action => action.type.endsWith("setGameVisibility"),
            () => {}
        );
    }
});

export const { openPopup, closePopup } = popup.actions;

export default popup.reducer;
