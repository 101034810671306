import React from "react";
import PropTypes from "prop-types";

const PopupFooter = ({ close, onSubmit, closeButtonText, submitButtonText, disabled }) => {
    return (
        <div className="popup-footer " style={{ pointerEvents: disabled ? "none" : "all" }}>
            {close && (
                <div className="btns-wrapper" onClick={close}>
                    <button className="btn-main-grey">{closeButtonText}</button>
                </div>
            )}
            <div className="btns-wrapper">
                <button className="btn-main-colored" disabled={disabled} onClick={onSubmit}>
                    {submitButtonText}
                </button>
            </div>
        </div>
    );
};

PopupFooter.defaultProps = {
    close: null,
    onSubmit: null,
    closeButtonText: "Cancel",
    submitButtonText: "Confirm",
    disabled: false
};

PopupFooter.propTypes = {
    close: PropTypes.func,
    onSubmit: PropTypes.func,
    closeButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    disabled: PropTypes.bool.isRequired
};

export default PopupFooter;
