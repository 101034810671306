import { Howl, Howler } from "howler";

export const [setupSound, toggleSound, playSound, getSoundStatus] = (() => {
    let sounds = null;
    let key = "isSoundEnabled";

    const toggleSound = enable => {
        window.sessionStorage.setItem(key, enable);
        Howler.mute(!enable);
    };

    const playSound = spriteName => {
        window.sessionStorage.getItem(key) && sounds?.play(spriteName);
    };

    const getSoundStatus = () => {
        return window.sessionStorage.getItem(key) === "true";
    };

    const setupSound = (config, prodKey = "") => {
        if (!sounds) {
            key = prodKey + key;
            sounds = new Howl(config);
            toggleSound(false);
        }
    };

    return [setupSound, toggleSound, playSound, getSoundStatus];
})();

export const [playMusic, stopMusic] = (() => {
    let sound = null;

    const play = () => sound?.play();
    const stop = () => sound?.stop();

    const config = {
        src: ["/assets/sounds/domino_music.wav"],
        loop: true,
        volume: 0.3
    };

    if (!sound) {
        sound = new Howl(config);
    }

    return [play, stop];
})();
