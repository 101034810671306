import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import Tile from "../../Tile";
import classNames from "classnames";
import { getAutoDealData, useShallowSelector } from "../../../store/selectors";
import { invoke } from "../../../connection";
import { SOCKET_INVOKE_ACTIONS } from "../../../constants";
import PropTypes from "prop-types";

const AutoDeal = ({ getBazarTileAnimationStyles }) => {
    const autoDealTileRef = useRef(null);
    const [, setAnimateAutoDealTile] = useState(false);

    const { isPlayer, invokeAutoDeal, bazarDominoMove, startAnimation, forceUpdate } = useShallowSelector(
        getAutoDealData
    );

    const autoDeal = useCallback(() => {
        if (invokeAutoDeal) {
            setTimeout(() => {
                invoke(SOCKET_INVOKE_ACTIONS.AUTO_DEAL);
            }, 1400);
        }
    }, [invokeAutoDeal, startAnimation]);

    useEffect(() => {
        autoDeal();
    }, [invokeAutoDeal, forceUpdate]);

    useEffect(() => {
        //this case is for reload
        if (bazarDominoMove && !autoDealTileRef.current) {
            setAnimateAutoDealTile(prev => !prev);
        }
    }, [bazarDominoMove, autoDealTileRef]);

    const printAutoDealTile = () => {
        const enableAnimation = Boolean(startAnimation && bazarDominoMove);
        const { tileStyles, wrapperStyles } = autoDealTileRef
            ? getBazarTileAnimationStyles(enableAnimation, autoDealTileRef.current)
            : {};

        return (
            <div
                ref={autoDealTileRef}
                className={classNames("bone-yard-tile auto-deal", { player: isPlayer })}
                style={wrapperStyles}
                onTransitionEnd={e => {
                    if (enableAnimation && e.target.classList.contains("auto-deal")) {
                        setTimeout(() => {
                            autoDeal();
                        });
                    }
                }}
            >
                <Tile boneYard tileId={bazarDominoMove?.dominoId} style={tileStyles} showAnimation={enableAnimation} />
            </div>
        );
    };

    return <>{printAutoDealTile()}</>;
};

AutoDeal.propTypes = {
    getBazarTileAnimationStyles: PropTypes.func.isRequired
};

export default memo(AutoDeal);
