import { createSlice } from "@reduxjs/toolkit";
import {
    join2Objects,
    endGameData,
    getTargetTileMoveCourseInfo,
    getValidDirectionRotateInfo,
    updateBoardDominoes,
    updatePlayersDominoes,
    setDominoDirections,
    setHistoryCurrentData,
    detectFirstTileSkeletonPositioon
} from "../../helpers/localHelperFunctions";
import { getElementsPosition } from "../../helpers/utility";
import { TILE_MOVE_COURSE_ID, TILES_MAP } from "../../constants";

const initialState = {
    mobileMoveBoardUp: false,
    mobilePlayerMoreTiles: false
};

export const gameData = createSlice({
    name: "gameData",
    initialState,
    reducers: {
        setGameData: (state, { payload }) => {
            join2Objects(state, payload);
            setDominoDirections(state.board);
            detectFirstTileSkeletonPositioon(state);
            state.isGameVisible = true;
            delete state.isGuest;
        },
        setSelectedTile: (state, { payload }) => {
            state.selectedTile = payload;
            state.playedDomino = null;

            if (!state.isPlayerTurn && state.board.centralDominoId === -1 && payload) {
                state.firstPossibleTileIsRotated = TILES_MAP[payload.dominoId][0] !== TILES_MAP[payload.dominoId][1];
            }

            // if (payload) {
            //     if (!payload.draggedEl) {
            //         state.selectedTile.selected = true;
            //         state.selectedTile.dragged = false;
            //     } else {
            //         state.selectedTile.selected = false;
            //         state.selectedTile.dragged = true;
            //     }
            // }
        },
        updateSelectedTile: (state, { payload }) => {
            join2Objects(state.selectedTile, payload);
        },
        setStepData: (state, { payload }) => {
            const {
                board,
                dominoMove,
                bazarDominoMove,
                isPlayerTurn,
                isPlayerPlayed,
                player,
                opponent,
                bazar,
                isGuest,
                gameScore,
                bazarDominoesCount,
                updateTilesDirection,
                newRound
            } = payload;

            if (
                !isPlayerPlayed &&
                state.board.centralDominoId === -1 &&
                state.firstPossibleTileIsRotated === undefined
            ) {
                state.firstPossibleTileIsRotated =
                    TILES_MAP[dominoMove.dominoId][0] !== TILES_MAP[dominoMove.dominoId][1];
            }

            state.isPlayerTurn = isPlayerTurn;
            state.isPlayerPlayed = isPlayerPlayed;
            state.resetTileScale = false;

            const stGScores = state.gameScore;

            if (!bazarDominoMove && gameScore) {
                stGScores.playerGameScore = gameScore.playerGameScore;
                stGScores.opponentGameScore = gameScore.opponentGameScore;
                stGScores.roundsScores[stGScores.roundsScores?.length - 1] = {
                    ...stGScores.roundsScores[stGScores.roundsScores?.length - 1],
                    ...(gameScore.currentRoundScore || {})
                };
            }

            if (newRound) {
                // state.isGameStarted = false;
                stGScores.roundsScores.push({
                    playerRoundScore: 0,
                    playerAdditionalPoint: 0,
                    opponentRoundScore: 0,
                    opponentAdditionalPoint: 0
                });
            }

            if (board) {
                state.board = board;
            } else if (dominoMove) {
                updateBoardDominoes(dominoMove, state.board);
            }
            updatePlayersDominoes(
                dominoMove?.dominoId,
                state,
                bazarDominoMove?.dominoId,
                isPlayerPlayed,
                player,
                opponent,
                isGuest
            );
            if (dominoMove && dominoMove?.moveCourse !== TILE_MOVE_COURSE_ID.CENTER && updateTilesDirection) {
                setDominoDirections(state.board, dominoMove.moveCourse);
            }
            state.playedDomino = dominoMove;
            state.selectedTile = null;
            state.targetTileInfo = null;
            state.bazarDominoMove = null;
            state.startAnimation = false;
            state.bazar = bazar;
            state.bazarDominoesCount = bazarDominoesCount;
        },
        removePlayedDomino: state => {
            state.playedDomino = null;
        },
        toggleAnimation: (state, { payload: { toggle, dominoMove, isPlayerPlayed } }) => {
            if (toggle && dominoMove?.moveCourse) {
                const { moveCourse, connectorTile } = dominoMove;

                state.playedDomino = dominoMove;
                if (moveCourse !== TILE_MOVE_COURSE_ID.CENTER) {
                    setDominoDirections(state.board, moveCourse);
                }

                const { moveCourseToSet, moveCourseDirection } = getTargetTileMoveCourseInfo(moveCourse, state.board);
                state.targetTileInfo = {
                    ...getElementsPosition(
                        document.getElementById(`moveCourse${moveCourseToSet}`),
                        getValidDirectionRotateInfo(moveCourseDirection)
                    ),
                    moveCourse,
                    connectorTile
                };
            }
            state.startAnimation = toggle;
            state.isPlayerPlayed = isPlayerPlayed;
        },
        setBazarDominoMove: (state, { payload }) => {
            state.bazarDominoMove = payload;
        },
        setRoundResult: (state, { payload }) => {
            state.player = { ...state.player, ...payload.player };
            state.opponent = { ...state.opponent, ...payload.opponent };
            delete payload.player;
            delete payload.opponent;
            join2Objects(state, payload);
            state.roundFinished = true;
            state.resetTileScale = false;
        },
        setTileMaxScaled: state => {
            state.tileMaxScaled = true;
        },
        setDoubleStakeData: (state, { payload }) => {
            state.player = { ...state.player, ...payload.player };
            state.opponent = { ...state.opponent, ...payload.opponent };
            delete payload.player;
            delete payload.opponent;
            join2Objects(state, payload);
        },
        setIsGameStarted: state => {
            state.isGameStarted = true;
            state.roundFinished = false;
            state.resetTileScale = true;
        },
        setGameBoardDimensions: (state, { payload }) => {
            state.gameBoardDimensions = payload;
        },
        setIsUserConnected: (state, { payload }) => {
            const { isPlayer, isConnected } = payload;
            if (state.player) {
                state[`${isPlayer ? "player" : "opponent"}`].isConnected = isConnected;
            }
        },
        setHistoryData: (state, { payload }) => {
            state.currentStep = 0;
            state.currentRound = 0;
            state.rounds = payload;
            state.isGameVisible = true;
            setHistoryCurrentData(state);
        },
        setHistoryCurrentStep: (state, { payload }) => {
            state.currentStep = payload;
            setHistoryCurrentData(state);
        },
        setHistoryCurrentRound: (state, { payload }) => {
            state.currentRound = payload;
            state.currentStep = 0;
            setHistoryCurrentData(state);
        },
        setEndGameData: endGameData,
        setGameVisibility: (state, { payload }) => {
            state.isGameVisible = payload;
        },
        setTilesPrinted: (state, { payload }) => {
            state.tilesPrinted = payload;
        },
        setMobileMoveBoardUp: (state, { payload }) => {
            state.mobileMoveBoardUp = payload;
        },
        setMobilePlayerMoreTiles: (state, { payload }) => {
            state.mobilePlayerMoreTiles = payload;
        },
        setWeakConnection: (state, { payload }) => {
            state.weakConnection = payload;
        },
        setUserConnectionLost: (state, { payload }) => {
            state.connectionLost = payload;
        },
        setBazarAnimation: () => {}
    },
    extraReducers: builder => {
        builder.addMatcher(
            action => action.type.endsWith("setGameVisibility"),
            () => initialState
        );
    }
});

export const {
    setGameData,
    setStepData,
    setIsGameStarted,
    setBazarAnimation,
    setMobileMoveBoardUp,
    setWeakConnection,
    setUserConnectionLost,
    setMobilePlayerMoreTiles,
    setGameBoardDimensions,
    setEndGameData,
    setTilesPrinted,
    setGameVisibility,
    setRoundResult,
    setHistoryData,
    setIsUserConnected,
    setSelectedTile,
    setHistoryCurrentStep,
    setHistoryCurrentRound,
    toggleAnimation,
    setTileMaxScaled,
    setDoubleStakeData,
    removePlayedDomino,
    updateSelectedTile,
    setBazarDominoMove
} = gameData.actions;

export default gameData.reducer;
