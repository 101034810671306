import { useEffect, useRef } from "react";

export default function useDidUpdate(cb, defs) {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) cb();
        else mounted.current = true;
    }, defs);
}
