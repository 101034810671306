import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getDoubleStakeInfo, useShallowSelector } from "../../../store/selectors";
import { bet, currencyId, SOCKET_INVOKE_ACTIONS } from "../../../constants";
import { invoke } from "../../../connection";
import { getTranslations } from "../../../helpers/translation";

const DoubleStake = ({ close }) => {
    const { nextStake } = useShallowSelector(getDoubleStakeInfo);
    const translate = useMemo(() => getTranslations(), []);

    const doubleStakeHandler = () => {
        invoke(SOCKET_INVOKE_ACTIONS.DOUBLE_STAKE_REQUEST);
        close();
    };

    return (
        <div>
            <div className="popup-header">
                <span className="popup-title">{translate("DoubleStake")}</span>
                {/*<button className="btn-close">*/}
                {/*    <Close />*/}
                {/*</button>*/}
            </div>
            <div className="popup-content">
                <div className="popup-text">
                    <p>{translate("DoYouWantToDoubleStake")}</p>
                    <p>
                        {translate("YourFinalStake")} {bet * (nextStake || 2)} {currencyId}
                    </p>
                </div>
            </div>
            <div className="popup-footer ">
                <div className="btns-wrapper" onClick={close}>
                    <button className="btn-main-grey">{translate("Cancel")}</button>
                </div>
                <div className="btns-wrapper">
                    <button className="btn-main-colored" onClick={doubleStakeHandler}>
                        {translate("Double")}
                    </button>
                </div>
            </div>
        </div>
    );
};

DoubleStake.propTypes = {
    close: PropTypes.func.isRequired
};

export default DoubleStake;
