import React, { memo } from "react";
import { OldScoreBar } from "skill-games/components";
import ScoreBarContent from "../../components/ScoreBarContent";

const ScoreBarWrapper = () => {
    return (
        <div className="score-bar-container">
            <OldScoreBar visibleWidth={6.8} className="score-bar">
                {/*canOpen={gameScore.roundsScores?.length}*/}
                <ScoreBarContent />
            </OldScoreBar>
        </div>
    );
};

export default memo(ScoreBarWrapper);
