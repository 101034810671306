import React from "react";
import PropTypes from "prop-types";
import Close from "../../Icons/Close";

const PopupHeader = ({ name, onClose }) => {
    return (
        <div className="popup-header">
            <span className="popup-title">{name}</span>
            {onClose && (
                <button className="btn-close">
                    <Close />
                </button>
            )}
        </div>
    );
};

PopupHeader.defaultProps = {
    name: "",
    onClose: null
};

PopupHeader.propTypes = {
    name: PropTypes.string,
    onClose: PropTypes.func
};

export default PopupHeader;
